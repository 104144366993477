import { OverlayView } from '@react-google-maps/api';
import type { Coordinates } from '@soilsense/shared';
import React, { useCallback, useMemo } from 'react';
import { highlightColor } from '../../utils/consts';

type Props = Readonly<{
  coordinates: Coordinates;
  fill: string;
  fillOpacity: number;
  stroke: string;
  strokeWidth: number;
  label: string | undefined;
  labelColor: string;
  highlighted: boolean;
  handleClick: (() => void) | undefined;
}>;

export const MouseEventFriendlyMarker: React.FC<Props> = ({
  coordinates,
  fill,
  fillOpacity,
  stroke,
  strokeWidth,
  label,
  labelColor,
  highlighted,
  handleClick,
}) => {
  const blurFilterId = useMemo(generateRandomSuffix, []);
  const iconWidth = 14;
  const iconHeight = 28;
  const labelWidth = 92;
  const labelHeight = 22;

  const iconHalfW = iconWidth / 2;
  const iconHalfH = iconHeight / 2;

  const labelHalfW = labelWidth / 2;
  const labelHalfH = labelHeight / 2;

  const totalWidth = Math.max(iconWidth, labelWidth);
  const totalHeight = iconHeight + labelHeight;

  const onClick = useCallback(
    (event) => {
      if (handleClick != undefined) {
        event.stopPropagation();
        handleClick();
      }
    },
    [handleClick]
  );

  return (
    <OverlayView mapPaneName='overlayMouseTarget' bounds={new google.maps.LatLngBounds(coordinates, coordinates)}>
      <svg
        style={{
          overflow: 'visible',
          position: 'relative',
          left: label == undefined ? `-${iconHalfW}px` : `-${totalWidth / 2}px`,
          top: label == undefined ? `-${iconHeight}px` : `-${iconHeight + labelHalfH}px`,
          width: label == undefined ? `${iconWidth}px` : `${totalWidth}px`,
          height: label == undefined ? `${iconHeight}px` : `${totalHeight}px`,
          pointerEvents: 'none',
        }}
        viewBox={label == undefined ? `0 0 ${iconWidth} ${iconHeight}` : `0 0 ${totalWidth} ${totalHeight}`}
      >
        <filter id={blurFilterId}>
          <feGaussianBlur in='SourceGraphic' stdDeviation='1' />
        </filter>
        <g
          transform={
            label == undefined ? undefined : `translate(${Math.max(0, labelWidth / 2 - iconWidth / 2)} 0)`
          }
          style={{
            pointerEvents: 'auto',
          }}
          onClick={onClick}
        >
          <path
            fill={fill}
            fillOpacity={fillOpacity}
            stroke={stroke}
            strokeWidth={strokeWidth}
            d={`
              M ${iconHalfW},${iconHeight}
              C ${iconHalfW - 1},${iconHalfH} 0,${iconHalfH - 2} 0,${iconHalfH / 2}
              A ${iconHalfW},${iconHalfH / 2} 0 1,1 ${iconWidth},${iconHalfH / 2}
              C ${iconWidth},${iconHalfH - 2} ${iconHalfW + 1},${iconHalfH} ${iconHalfW},${iconHeight}
              z
            `}
          />
          {highlighted && (
            <path
              fill={'none'}
              stroke={highlightColor(1)}
              strokeWidth={strokeWidth * 3}
              d={`
              M ${iconHalfW - 1},${iconHeight}
              C ${iconHalfW - 2},${iconHalfH} -1,${iconHalfH - 2} -1,${iconHalfH / 2}
              A ${iconHalfW + 1},${iconHalfH / 2} 0 1,1 ${iconWidth + 1},${iconHalfH / 2}
              C ${iconWidth + 1},${iconHalfH - 2} ${iconHalfW + 2},${iconHalfH} ${iconHalfW + 1},${iconHeight}
              z
            `}
              filter={`url(#${blurFilterId})`}
            />
          )}
        </g>
        {label != undefined && (
          <g
            transform={`translate(${Math.max(0, iconWidth / 2 - labelWidth / 2)} ${iconHeight})`}
            onClick={onClick}
            style={{
              pointerEvents: 'auto',
            }}
          >
            <ellipse
              fill={fill}
              fillOpacity={fillOpacity}
              stroke={stroke}
              strokeWidth={strokeWidth}
              cx={`${labelHalfW}`}
              cy={`${labelHalfH}`}
              rx={`${labelHalfW}`}
              ry={`${labelHalfH}`}
            />
            {highlighted && (
              <ellipse
                fill={'none'}
                stroke={'#89cff0'}
                strokeWidth={strokeWidth * 3}
                cx={`${labelHalfW}`}
                cy={`${labelHalfH}`}
                rx={`${labelHalfW + 1}`}
                ry={`${labelHalfH}`}
                filter={`url(#${blurFilterId})`}
              />
            )}
            <text
              fontSize='16'
              x={`${labelHalfW}`}
              y={`${Math.round((3 * labelHeight) / 4)}`}
              textAnchor='middle'
              fill={labelColor}
            >
              {label}
            </text>
          </g>
        )}
      </svg>
    </OverlayView>
  );
};

function generateRandomSuffix() {
  return Math.random().toString().substring(2);
}
