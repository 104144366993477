import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { makeStyles } from '@mui/styles';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import { tabIcons, tabLabels } from './Config';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 1000,
    borderTop: '1px solid #ddd',
  },
  selected: {
    fontSize: '0.85em !important',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingTop: 0,
  },
  label: {
    paddingTop: 2,
    fontSize: '0.7em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

interface IProps {
  disabled: boolean;
  selectedTab: number;
  handleTabChange: (newValue: number | string) => void;
}

const BottomNavigationPanel: FC<IProps> = ({ disabled, selectedTab, handleTabChange }) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <BottomNavigation
      className={classes.root}
      value={tabLabels[selectedTab]}
      onChange={(event, newValue) => {
        if (typeof newValue == 'string' || typeof newValue == 'number') {
          handleTabChange(newValue);
        }
      }}
      showLabels
    >
      {tabLabels.map((label: string, i: number) => (
        <BottomNavigationAction
          disabled={disabled}
          key={i}
          label={intl.formatMessage({ id: label.toLowerCase() })}
          value={label}
          icon={tabIcons[i]}
          id={`bot-nav-tab-${label}`}
          classes={{
            selected: classes.selected,
            label: classes.label,
          }}
        />
      ))}
    </BottomNavigation>
  );
};

export default BottomNavigationPanel;
