interface ErrorInfo {
  code: string;
  details?: string;
}

export class CError extends Error {
  code: string | undefined;
  info?: ErrorInfo;
  constructor(message: string, info?: ErrorInfo) {
    super(message);
    this.info = info;
  }
}

export const isCerror = (error: any): error is CError => {
  return error instanceof CError;
};

export enum Errors {
  CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN = 'CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN',
  DEVICE_SETTINGS_REQUEST_FAILED = 'DEVICE_SETTINGS_REQUEST_FAILED',
  CANNOT_FETCH_SENSOR_DATA_NO_PRECIPITATION_DATA = 'CANNOT_FETCH_SENSOR_DATA_NO_PRECIPITATION_DATA',
  NO_DATA_SOURCE_ON_OBSERVATION_SITE = 'NO_DATA_SOURCE_ON_OBSERVATION_SITE',
  NO_DATA_FOR_SELECTED_DATES = 'NO_DATA_FOR_SELECTED_DATES',
}
