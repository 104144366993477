import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import { DRAWER_WIDTH, DRAWER_WIDTH_LARGE } from 'components/Board/config';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import { tabLabels } from './Config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: DRAWER_WIDTH / tabLabels.length,
    minWidth: DRAWER_WIDTH / tabLabels.length,
    [theme.breakpoints.up('lg')]: {
      width: DRAWER_WIDTH_LARGE / tabLabels.length,
      minWidth: DRAWER_WIDTH_LARGE / tabLabels.length,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontWeight: theme.typography.fontWeightBold as any,
  },
}));

interface IStyledTabProps {
  label: string;
  disabled: boolean;
  key: string | number;
}

const StyledTab: FC<IStyledTabProps> = ({ children: _unusedChildren, ...props }) => {
  const classes = useStyles();
  return <Tab classes={classes} {...props} id={`nav-tab-${props.label}`} />;
};

interface IProps {
  selectedTab: number;
  handleChange: (newValue: number | string) => void;
  labels: string[];
  disabled?: boolean;
}

const ControlPanelTabs: FC<IProps> = ({ selectedTab, handleChange, disabled = false }) => {
  const intl = useIntl();
  return (
    <>
      <Paper square style={{ zIndex: 2 }}>
        <Tabs
          value={selectedTab}
          indicatorColor='primary'
          textColor='primary'
          onChange={(event, newValue) => {
            if (typeof newValue == 'string' || typeof newValue == 'number') {
              handleChange(newValue);
            }
          }}
          aria-label='select view'
          disabled={disabled}
        >
          {tabLabels.map((label: string, i: number) => {
            return (
              <StyledTab disabled={disabled} label={intl.formatMessage({ id: label.toLowerCase() })} key={i} />
            );
          })}
        </Tabs>
      </Paper>
    </>
  );
};

export default ControlPanelTabs;
