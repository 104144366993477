import type { Coordinates, Field } from '@soilsense/shared';
import { getCenter } from 'geolib';
import type { SiteDetails } from '../dataHandlers/ObservationSiteStore';
import type { FieldColor } from '../utils/getMarkerColorFromReadings';

export type FieldWithId = Field &
  Readonly<{
    id: string;
  }>;

export type FieldDetails = FieldWithId &
  Readonly<{
    color: FieldColor;
    center: Coordinates | undefined;
    siteDetails: readonly SiteDetails[];
  }>;

export function getFieldCenter(field: FieldWithId): Coordinates | undefined {
  const { path } = field;
  if (path.length < 3) {
    return undefined;
  }
  const center = getCenter([...path]);
  return center ? { lat: center.latitude, lng: center.longitude } : undefined;
}
