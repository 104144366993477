import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import MaterialDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import type { StyleRules } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useRef } from 'react';
import theme from 'theme';
import useOnClickOutside from '../utils/useOnClickOutside';
import { BAR_HEIGHT, DRAWER_WIDTH, DRAWER_WIDTH_LARGE } from './Board/config';
import { drawerMixins } from './PersistentDrawerLeft';

const STYLES: StyleRules = {
  root: {
    display: 'flex',
    position: 'fixed',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: BAR_HEIGHT,
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '60%',
  },
  appBarWithBackground: {
    backgroundSize: 'cover',
    backgroundPosition: 'left',
    opacity: 1,
    zIndex: theme.zIndex.drawer + 2,
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'auto',
      backgroundPosition: 'bottom',
    },
  },
  appBarWhite: {
    background: 'white',
    color: '#444',
    opacity: 1,
    zIndex: theme.zIndex.drawer + 2,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.08), 0px 4px 5px 0px rgba(0,0,0,0.07), 0px 1px 10px 0px rgba(0,0,0,0.05)',
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${DRAWER_WIDTH_LARGE}px)`,
      marginLeft: DRAWER_WIDTH_LARGE,
    },
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  hide: {
    display: 'none',
  },
  ...drawerMixins(theme),
};

const useStyles = makeStyles(() => STYLES);

interface IProps {
  id: number;
  titleElement: React.ReactNode;
  drawerContent?: JSX.Element;
  topSpacing: number;
  canOpen?: boolean;
  appBarClassName?: keyof typeof STYLES;
  titleImage?: string;
  backgroundImage?: string;
  zIndex?: number;
}

const BarWithDrawer: React.FC<IProps> = ({
  id,
  titleElement,
  titleImage,
  children,
  drawerContent,
  topSpacing,
  backgroundImage,
  appBarClassName = 'appBarWithBackground',
  zIndex = 1,
  canOpen = false,
}) => {
  const classes = useStyles();
  const [drawerOpen, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root} style={{ zIndex }} id={`bar-with-drawer-${id}`} data-cy={'bar-with-drawer'}>
      <AppBar
        position='fixed'
        style={{
          top: topSpacing,
          backgroundImage: `url('${backgroundImage}')`,
        }}
        className={clsx(classes[appBarClassName], {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
            {canOpen && (
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                className={clsx(classes.menuButton, drawerOpen && classes.hide)}
                size='large'
              >
                <MenuIcon />
              </IconButton>
            )}
            {titleImage && <img width={'35px'} src={titleImage} alt='Logo' style={{ marginRight: 8 }} />}
            {titleElement}
          </div>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            style={{ width: '100%', maxWidth: '40%' }}
          >
            {children}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer drawerOpen={drawerOpen} drawerContent={drawerContent} handleDrawerClose={handleDrawerClose} />
    </div>
  );
};

interface IDrawerProps {
  drawerOpen: boolean;
  drawerContent: React.ReactNode;
  handleDrawerClose: () => void;
}

const Drawer: React.FC<IDrawerProps> = ({ drawerOpen, drawerContent, handleDrawerClose }) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => handleDrawerClose());

  return (
    <MaterialDrawer
      ref={ref}
      className={classes.drawer}
      variant='persistent'
      anchor='left'
      open={drawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader} />
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose} size='large'>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      {drawerContent}
    </MaterialDrawer>
  );
};

export default BarWithDrawer;
