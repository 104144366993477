import type { DataLoggerConfiguration, Sensor } from '@soilsense/shared';

export const isSensorInfo = (sensorInfoState: Sensor | undefined | null): sensorInfoState is Sensor => {
  return sensorInfoState != null;
};

export function hasCalibration(configuration: DataLoggerConfiguration): boolean {
  const { cableTop, cableBottom } = configuration;
  return (
    cableTop.calibration?.fieldCapacity != undefined &&
    cableTop.calibration?.wiltingPoint != undefined &&
    cableBottom.calibration?.fieldCapacity != undefined &&
    cableBottom.calibration?.wiltingPoint != undefined
  );
}
