import { default as React } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  children: React.ReactNode;
}

interface IState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render(): React.ReactNode {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
          }}
        >
          <h2 style={{ textAlign: 'center' }}>
            <FormattedMessage id='error_boundary_something_went_wrong' />
          </h2>
          <h5 style={{ textAlign: 'center', marginTop: '-20px' }}>
            <FormattedMessage id='error_boundary_please_refresh' />
          </h5>
          <details style={{ whiteSpace: 'pre-wrap', textAlign: 'center', maxHeight: 500, overflow: 'auto' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
