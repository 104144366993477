import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type AlertProps = Readonly<{
  open: boolean;
  handleYes: () => void;
  handleNo: () => void;
}>;

export function Alert({ open, handleYes, handleNo }: AlertProps): JSX.Element {
  return (
    <Dialog
      open={open}
      onClose={handleNo}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <FormattedMessage id='cancel_are_you_sure' />
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleNo} color='primary' autoFocus>
          <FormattedMessage id='cancel_confirm_no' />
        </Button>
        <Button onClick={handleYes} color='primary'>
          <FormattedMessage id='cancel_confirm_yes' />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const CancelConfirmButton = ({
  disabled,
  onClick,
  classNameStyle,
  progressExists = true,
}: {
  disabled?: boolean;
  onClick: () => void;
  classNameStyle?: string;
  progressExists?: boolean;
}): JSX.Element => {
  const [showPrompt, setShowPrompt] = React.useState(false);

  return (
    <>
      <Alert
        open={showPrompt}
        handleYes={() => {
          setShowPrompt(false);
          onClick();
        }}
        handleNo={() => {
          setShowPrompt(false);
        }}
      />
      <Button
        disabled={disabled}
        onClick={() => {
          if (progressExists) {
            setShowPrompt(true);
          } else {
            onClick();
          }
        }}
        className={classNameStyle}
      >
        <FormattedMessage id='cancel' />
      </Button>
    </>
  );
};

export default CancelConfirmButton;
