import logo_red from 'assets/soilsense.png';
import logo_white from 'assets/soilsense_white.png';
import React from 'react';

export enum LogoColor {
  white = 'white',
  red = 'red',
}

export const getLogoPath = (color: LogoColor): string | undefined => {
  switch (color) {
    case LogoColor.white:
      return logo_white;
    case LogoColor.red:
      return logo_red;
    default:
      console.error('The logo color provided is wrong');
      return undefined;
  }
};

type Props = Readonly<{
  color?: LogoColor;
  width: number | string;
  style?: React.CSSProperties;
}>;

const Logo: React.FC<Props> = ({ color = LogoColor.white, width, style }) => {
  return <img style={style} src={getLogoPath(color)} width={width} alt='logo' />;
};

export default Logo;
