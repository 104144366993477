import ArchiveIcon from '@mui/icons-material/Archive';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import { Thermometer, ThermometerLines } from 'mdi-material-ui';
import React from 'react';

export const BAR_HEIGHT = 48;
export const BOTTOM_NAVIGATION_HEIGHT = 56;
export const DRAWER_WIDTH = 360;
export const DRAWER_WIDTH_LARGE = 500;

export enum CONTROL_OPTIONS {
  Salinity = 'EC (25°C reference)',
  Temperature = 'Soil temperature',
  BoxTemperature = 'Air temperature',
  Precipitation = 'Precipitation',
  AutoScale = 'Auto scale',
  ArchivedLocations = 'Archived locations',
}

export const CONTROL_ICONS = {
  [CONTROL_OPTIONS.Salinity]: <GroupWorkIcon />,
  [CONTROL_OPTIONS.Temperature]: <ThermometerLines />,
  [CONTROL_OPTIONS.BoxTemperature]: <Thermometer />,
  [CONTROL_OPTIONS.Precipitation]: <LocalDrinkIcon />,
  [CONTROL_OPTIONS.AutoScale]: <AspectRatioIcon />,
  [CONTROL_OPTIONS.ArchivedLocations]: <ArchiveIcon />,
};

export const CONTROLS_DEFAULT = {
  [CONTROL_OPTIONS.Salinity]: true,
  [CONTROL_OPTIONS.Temperature]: false,
  [CONTROL_OPTIONS.BoxTemperature]: false,
  [CONTROL_OPTIONS.Precipitation]: true,
  [CONTROL_OPTIONS.AutoScale]: true,
  [CONTROL_OPTIONS.ArchivedLocations]: false,
};

export type IControls = typeof CONTROLS_DEFAULT;

export const CONTROLS_ORDER: readonly (keyof IControls)[] = [
  CONTROL_OPTIONS.Salinity,
  CONTROL_OPTIONS.Temperature,
  CONTROL_OPTIONS.BoxTemperature,
  CONTROL_OPTIONS.Precipitation,
  CONTROL_OPTIONS.AutoScale,
  CONTROL_OPTIONS.ArchivedLocations,
];
