import { observer } from 'mobx-react-lite';
import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import { LoadingScreen } from '../components/LoadingScreen';
import { useCurrentUser } from '../dataHandlers/RootStore';
import { Routes } from '../Routes';

const PrivateRoute: React.FC<RouteProps> = observer(({ location, component: RouteComponent, ...rest }) => {
  const currentUser = useCurrentUser();
  if (currentUser === undefined) {
    return <LoadingScreen />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        currentUser ? (
          RouteComponent && <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={{ pathname: Routes.LOGIN, state: { from: location?.pathname } }} />
        )
      }
    />
  );
});

export default PrivateRoute;
