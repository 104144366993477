import type {
  DataLoggerConfiguration,
  MoistureSensorConfiguration,
  ObservationSite,
  SensorSlot,
} from '@soilsense/shared';
import { DEFAULT_BOT_DEPTH, DEFAULT_MID_DEPTH, DEFAULT_TOP_DEPTH, SENSOR_SLOTS } from '@soilsense/shared';
import type { ChartDataset, ChartOptions } from 'chart.js';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import assertNever from 'utils/assertNever';
import type { TransformedData } from '../../../dataHandlers/SensorTransformer';
import { isDefined } from '../../../utils/isDefined';
import type { IValidatedDateRangeObj } from '../../RangeDatePicker';
import ChartJsComponent from './ChartJsComponent';
import { getChartColors } from './Config';

type Interval = Readonly<{
  amount: 1 | 6;
  unit: 'hour' | 'day' | 'week' | 'month';
}>;

type Bucket = Readonly<{
  startTimestamp: number;
  endTimestamp: number;
  stats: Stats;
}>;

export const CHART_HEIGHT = '130px';

type Stats = Readonly<{ [key in SensorSlot]: TimeSeriesBucketStats | undefined }>;

type TimeSeriesBucketStats = Readonly<{
  weightedSum: number;
  minimum: number;
  maximum: number;
  totalSampledDuration: number;
}>;

function timeWeightedAverage(stats: TimeSeriesBucketStats | undefined): number | null {
  return stats == undefined ? null : stats.weightedSum / stats.totalSampledDuration;
}

type Writable<T> = {
  -readonly [K in keyof T]: T[K];
};

const ONE_HOUR = 60 * 60 * 1000;

type Props = Readonly<{
  site: ObservationSite;
  configuration: DataLoggerConfiguration;
  timeRange: IValidatedDateRangeObj;
  transformedData: readonly TransformedData[];
}>;

type YAxisBounds = Readonly<{ [key in SensorSlot]: readonly [number, number] }>;
type Datasets = Readonly<{ [key in SensorSlot]: ChartDataset }>;

export const ChartJsWeightedAverageChart: FC<Props> = observer(
  ({ site, configuration, timeRange, transformedData }) => {
    const buckets = useMemo(() => calculateBuckets(timeRange, transformedData), [timeRange, transformedData]);
    const labels = useMemo(
      () => buckets.map((bucket) => (bucket.startTimestamp + bucket.endTimestamp) / 2),
      [buckets]
    );
    const datasets: Datasets = useMemo(
      () => ({
        cableTop: makeDataset({ type: 'cableTop', configuration, buckets }),
        cableMiddle: makeDataset({ type: 'cableMiddle', configuration, buckets }),
        cableBottom: makeDataset({ type: 'cableBottom', configuration, buckets }),
      }),
      [buckets, configuration]
    );

    const yAxisBounds: YAxisBounds = useMemo(
      () => ({
        cableTop: makeAxisBounds(buckets.map((bucket) => timeWeightedAverage(bucket.stats?.cableTop))),
        cableMiddle: makeAxisBounds(buckets.map((bucket) => timeWeightedAverage(bucket.stats?.cableMiddle))),
        cableBottom: makeAxisBounds(buckets.map((bucket) => timeWeightedAverage(bucket.stats?.cableBottom))),
      }),
      [buckets]
    );

    if (configuration.placement == 'vertical') {
      return (
        <SingleWeightedAverageChart
          timeRange={timeRange}
          site={site}
          buckets={buckets}
          slots={SENSOR_SLOTS}
          labels={labels}
          datasets={datasets}
          yAxisBounds={yAxisBounds}
        />
      );
    } else {
      const sensorSlots: readonly SensorSlot[] =
        configuration.cableMiddle == undefined ? ['cableTop', 'cableBottom'] : SENSOR_SLOTS;
      return (
        <>
          {sensorSlots.map((slot) => (
            <SingleWeightedAverageChart
              key={slot}
              timeRange={timeRange}
              site={site}
              buckets={buckets}
              slots={[slot]}
              labels={labels}
              datasets={datasets}
              yAxisBounds={yAxisBounds}
            />
          ))}
        </>
      );
    }
  }
);

export function calculateBuckets(
  timeRange: IValidatedDateRangeObj,
  data: readonly TransformedData[]
): readonly Bucket[] {
  const result: Bucket[] = [];
  const { interval, rangeStart, rangeEnd } = determineInterval(timeRange);
  const rangeStartTimestamp = rangeStart.unix() * 1000;

  let dataIndex = 0;
  while (dataIndex < data.length && data[dataIndex].timestamp < rangeStartTimestamp) {
    dataIndex++;
  }

  const bucketStart = rangeStart.clone();
  const bucketEnd = rangeStart.clone().add(interval.amount, interval.unit);
  while (bucketStart.isBefore(rangeEnd)) {
    const bucketStartTimestamp = bucketStart.unix() * 1000;
    const bucketEndTimestamp = bucketEnd.unix() * 1000;

    const samples: TransformedData[] = [];
    while (dataIndex < data.length && data[dataIndex].timestamp < bucketEndTimestamp) {
      samples.push(data[dataIndex++]);
    }

    result.push({
      startTimestamp: bucketStartTimestamp,
      endTimestamp: bucketEndTimestamp,
      stats: {
        cableTop: calculateStats(bucketStartTimestamp, samples, 'vwcTop'),
        cableMiddle: calculateStats(bucketStartTimestamp, samples, 'vwcMid'),
        cableBottom: calculateStats(bucketStartTimestamp, samples, 'vwcBot'),
      },
    });

    bucketStart.add(interval.amount, interval.unit);
    bucketEnd.add(interval.amount, interval.unit);
  }

  return result;
}

export function determineInterval(
  timeRange: IValidatedDateRangeObj
): Readonly<{ interval: Interval; rangeStart: Moment; rangeEnd: Moment }> {
  const rangeStart = timeRange.startDate.clone().startOf('day');
  const rangeEnd = timeRange.endDate.clone().startOf('day').add(1, 'day');
  const days = rangeEnd.diff(rangeStart) / 1000 / 60 / 60 / 24;
  if (days <= 7) {
    return { interval: { amount: 6, unit: 'hour' }, rangeStart, rangeEnd };
  }
  if (days <= 45) {
    return { interval: { amount: 1, unit: 'day' }, rangeStart, rangeEnd };
  }
  if (days <= 180) {
    return { interval: { amount: 1, unit: 'week' }, rangeStart, rangeEnd };
  }
  return { interval: { amount: 1, unit: 'month' }, rangeStart, rangeEnd };
}

export function calculateStats(
  bucketStart: number,
  bucketSamples: readonly TransformedData[],
  key: 'vwcTop' | 'vwcMid' | 'vwcBot'
): TimeSeriesBucketStats | undefined {
  let result: Writable<TimeSeriesBucketStats> | undefined;
  let lastTimestamp = bucketStart;
  for (const sample of bucketSamples) {
    const value = sample[key];
    if (value == undefined) {
      continue;
    }
    const duration = Math.min(sample.timestamp - lastTimestamp, ONE_HOUR);
    if (result == undefined) {
      result = {
        weightedSum: value * duration,
        minimum: value,
        maximum: value,
        totalSampledDuration: duration,
      };
    } else {
      result.weightedSum += value * duration;
      result.minimum = Math.min(result.minimum, value);
      result.maximum = Math.max(result.maximum, value);
      result.totalSampledDuration += duration;
    }
    lastTimestamp = sample.timestamp;
  }
  return result;
}

function makeDataset({
  type,
  configuration,
  buckets,
}: {
  type: SensorSlot;
  configuration: DataLoggerConfiguration;
  buckets: readonly Bucket[];
}): ChartDataset {
  const color = getChartColors().moistureColor[type];
  return {
    label: determineLabel(configuration, type),
    data: buckets.map((bucket) => timeWeightedAverage(bucket.stats?.[type])),
    borderColor: color,
    borderWidth: 2,
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointRadius: 2,
  };
}

function determineLabel(configuration: DataLoggerConfiguration, type: SensorSlot): string {
  const sensorConfiguration: MoistureSensorConfiguration | undefined =
    type == 'cableTop'
      ? configuration.cableTop
      : type == 'cableMiddle'
      ? configuration.cableMiddle
      : type == 'cableBottom'
      ? configuration.cableBottom
      : assertNever(type);

  if (sensorConfiguration?.name == undefined) {
    const defaultDepth =
      type == 'cableTop'
        ? DEFAULT_TOP_DEPTH
        : type == 'cableMiddle'
        ? DEFAULT_MID_DEPTH
        : type == 'cableBottom'
        ? DEFAULT_BOT_DEPTH
        : assertNever(type);
    return `${sensorConfiguration?.depth ?? defaultDepth} cm`;
  } else {
    return sensorConfiguration.name;
  }
}

function makeAxisBounds(values: readonly (number | null | undefined)[]): readonly [number, number] {
  return [
    Math.max(0, Math.floor(Math.min(...values.filter(isDefined))) - 1),
    Math.min(100, Math.ceil(Math.max(...values.filter(isDefined))) + 1),
  ];
}

function SingleWeightedAverageChart({
  site,
  timeRange,
  buckets,
  slots,
  labels,
  datasets,
  yAxisBounds,
}: Readonly<{
  site: ObservationSite;
  timeRange: IValidatedDateRangeObj;
  buckets: readonly Bucket[];
  slots: readonly SensorSlot[];
  labels: number[];
  datasets: Datasets;
  yAxisBounds: YAxisBounds;
}>) {
  const intl = useIntl();
  const data = useMemo(
    () => ({ labels, datasets: slots.map((slot) => datasets[slot]) }),
    [datasets, labels, slots]
  );
  const options: ChartOptions = useMemo(() => {
    const yMin = Math.min(...slots.map((slot) => yAxisBounds[slot][0]));
    const yMax = Math.max(...slots.map((slot) => yAxisBounds[slot][1]));
    return makeChartOptions({ timeRange, site, buckets, yMin, yMax, intl });
  }, [buckets, site, slots, timeRange, yAxisBounds, intl]);
  return <ChartJsComponent type='line' data={data} options={options} width='100%' height={CHART_HEIGHT} />;
}

function makeChartOptions({
  timeRange,
  site,
  buckets,
  yMin,
  yMax,
  intl,
}: Readonly<{
  timeRange: IValidatedDateRangeObj;
  site: ObservationSite;
  buckets: readonly Bucket[];
  yMin: number;
  yMax: number;
  intl: IntlShape;
}>): ChartOptions {
  const { interval } = determineInterval(timeRange);
  const { safeRangeColor, safeRangeBackgroundColor } = getChartColors();
  const xMin = buckets[0].startTimestamp;
  const xMax = buckets[buckets.length - 1].endTimestamp;
  const safeRange = site.safeRanges.volumetricWaterContent;

  return {
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
      line: {
        borderWidth: 0,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    hover: {
      intersect: true,
      mode: undefined,
    },
    scales: {
      x: {
        type: 'time',
        min: xMin,
        max: xMax,
        ticks: {
          maxRotation: 0,
          callback: (label, index, ticks) => {
            const date = moment(ticks[index].value);
            const major = ticks[index].major;

            if (date.isValid()) {
              return intl.formatDate(date.toDate(), {
                month: major ? 'long' : 'short',
                day: 'numeric',
              });
            }
            return label;
          },
        },
        grid: {
          display: true,
          drawOnChartArea: true,
        },
      },
      y: {
        display: true,
        min: yMin,
        max: yMax,
        ticks: {
          maxTicksLimit: 2,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        filter: (item) => !item.dataset.label?.startsWith('_'),
        callbacks: {
          label: (context) => `${context.dataset.label ?? ''}: ${context.parsed.y.toFixed(1)} %`,
          title: (items) => {
            if (items.length == 0) {
              return '';
            }
            const index = items[0].dataIndex;
            const bucket = buckets[index];
            const startMoment = moment(bucket.startTimestamp);
            const endMoment = moment(bucket.endTimestamp);

            return interval.unit == 'hour'
              ? `${startMoment.format('MMM Do')} ${startMoment.format('HH:mm')} - ${endMoment.format('HH:mm')}`
              : interval.unit == 'day'
              ? startMoment.format('MMMM Do')
              : interval.unit == 'week'
              ? `${startMoment.format('MMM Do')} - ${endMoment.clone().subtract(1, 'day').format('MMM Do')}`
              : interval.unit == 'month'
              ? startMoment.format('MMMM YYYY')
              : assertNever(interval.unit);
          },
        },
      },
      legend: {
        display: false,
      },
      annotation: {
        annotations:
          safeRange == undefined
            ? []
            : [
                {
                  type: 'box',
                  xMin,
                  xMax,
                  yMin: safeRange[0],
                  yMax: safeRange[1],
                  borderWidth: 0,
                  backgroundColor: safeRangeBackgroundColor,
                },
                {
                  type: 'line',
                  xMin,
                  xMax,
                  yMin: safeRange[0],
                  yMax: safeRange[0],
                  borderWidth: 2,
                  borderColor: safeRangeColor,
                },
                {
                  type: 'line',
                  xMin,
                  xMax,
                  yMin: safeRange[1],
                  yMax: safeRange[1],
                  borderWidth: 2,
                  borderColor: safeRangeColor,
                },
              ],
      },
    },
  };
}
