import Signup from 'components/Signup';
import { useCurrentUser, useFarmStore, useUserStore } from 'dataHandlers/RootStore';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router';
import getErrorMessageIntl from 'utils/getErrorMessageIntl';
import { SoilsenseBackgroundOverlay } from '../components/BackgroundOverlayContainer';
import type { ISignupForm } from '../components/Signup';
import { SeverityLevel, showErrorSnackBar, showSnackBar } from '../components/SnackBar';

const SignupPage = observer((): JSX.Element => {
  const userStore = useUserStore();
  const farmStore = useFarmStore();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const handleSignUp = useCallback(
    async (event: React.FormEvent<HTMLFormElement & ISignupForm>) => {
      event.preventDefault();
      const {
        email: { value: email },
        password: { value: password },
        name: { value: name },
      } = (event.target as HTMLFormElement & ISignupForm).elements;
      setLoading(true);
      try {
        await userStore.createUserWithEmailAndPassword(email.trim(), password, async (authUser) => {
          if (!authUser || !authUser.user) {
            throw new Error(intl.formatMessage({ id: 'signup_failed_creating_user' }, { email }));
          }
          const farm = name ? await farmStore.addFarm(name, authUser.user.uid) : undefined;
          const farmPermissions = farm
            ? [
                {
                  id: farm.id,
                  isAdmin: true,
                },
              ]
            : [];
          await userStore.createUser(authUser.user.uid, email, farmPermissions);
        });
        history.push('/', { from: 'signup' });
        showSnackBar(SeverityLevel.success, intl.formatMessage({ id: 'signup_success' }, { email }));
      } catch (err) {
        showErrorSnackBar(
          intl.formatMessage(
            { id: 'signup_error' },
            {
              error: getErrorMessageIntl(err, intl),
            }
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [farmStore, history, userStore, intl]
  );

  const currentUser = useCurrentUser();
  if (currentUser) {
    return <Redirect to={{ pathname: '/', state: { from: 'signup' } }} />;
  }

  return (
    <SoilsenseBackgroundOverlay>
      <Signup onSubmit={handleSignUp} loading={loading} />
    </SoilsenseBackgroundOverlay>
  );
});

export default SignupPage;
