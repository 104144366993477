import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { BAR_HEIGHT, DRAWER_WIDTH, DRAWER_WIDTH_LARGE } from './Board/config';

export const drawerMixins = (theme: Theme) =>
  ({
    drawer: {
      width: DRAWER_WIDTH,
      [theme.breakpoints.up('lg')]: {
        width: DRAWER_WIDTH_LARGE,
      },
      flexShrink: 0,
      overflow: 'auto',
    },
    drawerPaper: {
      overflow: 'inherit',
      width: DRAWER_WIDTH,
      [theme.breakpoints.up('lg')]: {
        width: DRAWER_WIDTH_LARGE,
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      minHeight: BAR_HEIGHT,
      justifyContent: 'flex-end',
    },
  } as const);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: `100%`,
    overflow: 'auto',
    zIndex: 1,
  },
  content: {
    overflowX: 'hidden',
    position: 'relative',
    width: `calc(100vw - ${DRAWER_WIDTH}px)`,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100vw - ${DRAWER_WIDTH_LARGE}px)`,
    },
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: theme.palette.grey[200],
  },
  ...drawerMixins(theme),
}));

interface IProps {
  id: number;
  open: boolean;
  drawerContent: React.ReactNode;
}
const PersistentDrawerLeft: React.FC<IProps> = ({ id, children, open, drawerContent }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} id={`persistent-drawer-${id}`} data-cy={'persistent-drawer'}>
      <CssBaseline />
      <Hidden mdDown implementation='css'>
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader} />
          {drawerContent}
        </Drawer>
      </Hidden>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default PersistentDrawerLeft;
