import type { Coordinates, Gateway } from '@soilsense/shared';
import { GATEWAY_DECODER } from '@soilsense/shared';
import type { DocumentReference } from 'firebase/firestore';
import { arrayUnion, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import type FirebaseApi from './FirebaseApi';

class Gateways {
  private gatewaysRef = this.api.getCollections().gateways();

  constructor(private readonly api: FirebaseApi) {}

  public getGatewayDocRef(id: string): DocumentReference {
    return doc(this.gatewaysRef, id);
  }

  public async getGateway(id: string): Promise<Gateway> {
    const snapshot = await getDoc(this.getGatewayDocRef(id));
    return await GATEWAY_DECODER.decodeToPromise(snapshot.data());
  }

  public async getGatewaysBelongingToFarm(farmId: string): Promise<readonly Gateway[]> {
    const queryResult = await getDocs(query(this.gatewaysRef, where('farms', 'array-contains', farmId)));
    return Promise.all(queryResult.docs.map((snapshot) => GATEWAY_DECODER.decodeToPromise(snapshot.data())));
  }

  public async canAssignGatewayToFarm(gatewayId: string): Promise<boolean> {
    const reference = this.getGatewayDocRef(gatewayId);
    const snapshot = await getDoc(reference);
    if (snapshot.exists()) {
      const gateway = await GATEWAY_DECODER.decodeToPromise(snapshot.data());
      return gateway.freeToAssign === true;
    }
    return false;
  }

  public async updateGateway(
    gatewayId: string,
    selectedFarmId: string,
    coordinates: Coordinates | undefined
  ): Promise<void> {
    return updateDoc(this.getGatewayDocRef(gatewayId), {
      farms: arrayUnion(selectedFarmId),
      freeToAssign: false,
      coordinates,
    });
  }
}

export default Gateways;
