import type { Sensor } from '@soilsense/shared';
import { SENSOR_DECODER } from '@soilsense/shared';
import type { Transaction } from 'firebase/firestore';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import type FirebaseApi from './FirebaseApi';

class Sensors {
  constructor(private readonly api: FirebaseApi) {}

  public async getAllSensorIds(): Promise<readonly string[]> {
    const snapshot = await getDocs(this.api.getCollections().sensors());
    return snapshot.docs.map((doc) => doc.id);
  }

  public async canAssignSensorToFarm(sensorId: string): Promise<boolean> {
    const data = await getDoc(doc(this.api.getCollections().sensors(), sensorId));
    return Boolean(data.get('freeToAssign'));
  }

  public async sensorExists(sensorId: string): Promise<boolean> {
    const ref = doc(this.api.getCollections().sensors(), sensorId);
    const data = await getDoc(ref);
    return data.exists();
  }

  public assignToFarm(sensorId: string, farmId: string, transaction: Transaction): void {
    const sensorDocRef = this.api.getDocRef(`sensors/${sensorId}`);
    transaction.update(sensorDocRef, { farm: farmId, freeToAssign: false });
  }

  public async getSensor(sensorId: string): Promise<Sensor> {
    const data = await SENSOR_DECODER.decodeToPromise(
      (await getDoc(doc(this.api.getCollections().sensors(), sensorId))).data()
    );
    return data;
  }

  public async getAllNbLoggerSensors(): Promise<readonly Sensor[]> {
    const sensorsCollection = collection(this.api.db, 'sensors');
    const sensorsQuery = query(sensorsCollection, where('type', '==', 'nbsensor'));
    const snapshot = await getDocs(sensorsQuery);
    return Promise.all(snapshot.docs.map(async (doc) => this.getSensor(doc.id)));
  }
}

export default Sensors;
