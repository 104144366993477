import type { ChartData, ChartEvent, ChartOptions, ChartType, Plugin } from 'chart.js';
import { Chart, registerables } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import type { ReactNode, Ref } from 'react';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import crosshairPlugin from './ChartJsCrosshairPlugin';

Chart.register(...registerables, annotationPlugin, zoomPlugin, crosshairPlugin);

export type ChartCoordinates = Readonly<{
  x: number;
  y: number;
}>;

export type ChartJsHandle = Readonly<{
  getChartCoordinates(event: ChartEvent): ChartCoordinates | undefined;
}>;

export type Props = {
  type: ChartType;
  data: ChartData;
  options: ChartOptions;
  width: string;
  height: string;
  fallbackContent?: ReactNode;
  plugins?: Plugin[];
};

function ChartJsComponent(props: Props, ref: Ref<ChartJsHandle>): JSX.Element {
  const { data, options, width, height, fallbackContent } = props;
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const chartRef = useRef<Chart | null>(null);

  useImperativeHandle(ref, () => ({
    getChartCoordinates: (event) => {
      const chart = chartRef.current;
      if (chart != undefined) {
        const position = getRelativePosition(event, chart);
        const x = chart.scales.x.getValueForPixel(position.x);
        const y = chart.scales.y.getValueForPixel(position.y);
        if (x != undefined && y != undefined) {
          return { x, y };
        }
      }
      return undefined;
    },
  }));

  // needed for re-initialization of the chart using the most recent props in
  // case the canvas changes or a chart update fails
  const latestProps = useRef(props);
  useEffect(() => {
    latestProps.current = props;
  });

  // chart updates are unstable - the workaround is to attempt to re-initialize
  // the whole thing when an update throws an error
  const [forcedReinitializationCount, setForcedReinitializationCount] = useState(0);

  useEffect(() => {
    if (!canvas) return;

    const { type, data, options, plugins } = latestProps.current;
    const chart = new Chart(canvas, {
      type,
      data,
      options,
      plugins,
    });

    chartRef.current = chart;

    return () => {
      chartRef.current = null;
      chart.destroy();
    };
  }, [canvas, forcedReinitializationCount]);

  useEffect(() => {
    if (chartRef.current == null) {
      return;
    }

    try {
      const chart = chartRef.current;
      chart.options = options;
      chart.config.data = data;
      chart.update();
    } catch (error) {
      console.error('Error while updating a chart - attempting to re-initialize instead', error);
      setForcedReinitializationCount((value) => value + 1);
    }
  }, [data, options]);

  return (
    <div style={{ width, height, position: 'relative' }}>
      <canvas ref={setCanvas} role='img'>
        {fallbackContent}
      </canvas>
    </div>
  );
}

export default forwardRef(ChartJsComponent);
