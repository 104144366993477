import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import type { Dispatch } from 'react';
import React from 'react';

export const TIME_FORMAT = 'DD.MM.YYYY HH:mm';

export type DelimitedHistoryItem<V> = Readonly<{
  startTimestamp: number;
  previousStartTimestamp: number | undefined;
  nextStartTimestamp: number | undefined;
  hidden: boolean;
  value: V;
}>;

export type DelimitedValueHistory<V> = readonly DelimitedHistoryItem<V>[];

export type HistoryState<V> = Readonly<{
  history: DelimitedValueHistory<V>;
  selectedIndex: number;
  setSelectedIndex: Dispatch<number>;
  insertItem: Dispatch<number>;
  disabled: boolean;
}>;

type Props<V> = Readonly<{
  label: string;
  state: HistoryState<V>;
}>;

export function HistoryItemSelector<V>({
  label,
  state: { history, selectedIndex, setSelectedIndex, disabled },
}: Props<V>): JSX.Element {
  return (
    <FormControl variant='outlined' fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={selectedIndex}
        onChange={(event) => {
          const index = event.target.value;
          if (typeof index == 'number') {
            setSelectedIndex(index);
          }
        }}
        disabled={disabled}
      >
        {([] as JSX.Element[]).concat(
          ...history.map(({ hidden, startTimestamp, nextStartTimestamp: endTimestamp }, index) => {
            if (hidden) {
              return [];
            }
            const startTimeString = moment(startTimestamp).format(TIME_FORMAT);
            const endTimeString = endTimestamp == undefined ? undefined : moment(endTimestamp).format(TIME_FORMAT);
            return [
              <MenuItem key={startTimestamp} value={index}>
                {endTimeString == undefined ? `From ${startTimeString}` : `${startTimeString} - ${endTimeString}`}
              </MenuItem>,
            ];
          })
        )}
      </Select>
    </FormControl>
  );
}
