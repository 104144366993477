import HighlightIcon from '@mui/icons-material/Highlight';
import RouterIcon from '@mui/icons-material/Router';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SyncIcon from '@mui/icons-material/Sync';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import { green, grey, pink } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { SoilsenseBackgroundOverlay } from '../components/BackgroundOverlayContainer';
import StatusCircle from '../components/Circle';
import { showErrorSnackBar } from '../components/SnackBar';
import BrokerControl, { BrokerControlEndPoints } from '../components/SystemStatus/BrokerControl';
import NbLoggerStatuses from '../components/SystemStatus/NbLoggerStatuses';
import SensorStatuses from '../components/SystemStatus/SensorStatuses';
import Tabbed from '../components/SystemStatus/Tabbed';
import { useStyles } from '../components/SystemStatus/styles';
import { useDataLoggerStatusStore, useFarmStore, useTokenProvider } from '../dataHandlers/RootStore';
import { BASE_URL } from '../utils/consts';
import { fetchAndSet } from '../utils/fetchAndSet';
import getErrorMessage from '../utils/getErrorMessage';
import { useLocalStorage } from '../utils/useLocalStorage';

const BASE = `${BASE_URL}/admin`;

const SystemStatusPage = (): JSX.Element => {
  return (
    <SoilsenseBackgroundOverlay>
      <SystemStatus />
    </SoilsenseBackgroundOverlay>
  );
};

const SystemStatus: React.FC = observer(() => {
  const classes = useStyles();
  const tokenProvider = useTokenProvider();

  const [brokerStatus, setBrokerStatus] = useState<boolean>();

  const farmStore = useFarmStore();
  const dataLoggerStatusStore = useDataLoggerStatusStore();

  const [selectedFarmId, setSelectedFarmId] = useLocalStorage(
    'system-status-selected-farm',
    farmStore.selectedFarmId
  );
  useEffect(() => {
    farmStore.setSelectedFarmId(selectedFarmId);
  }, [farmStore, selectedFarmId]);

  const fetchBrokerStatus = useCallback(async () => {
    const token = await tokenProvider?.();
    fetchAndSet(`${BASE}/${BrokerControlEndPoints.GET_STATUS}`, token)
      .then(() => {
        setBrokerStatus(true);
      })
      .catch((e: Error) => {
        setBrokerStatus(false);
        const message = getErrorMessage(e);
        showErrorSnackBar(message);
        console.error(message);
      });
  }, [tokenProvider]);

  useEffect(() => {
    fetchBrokerStatus();
  }, [fetchBrokerStatus]);

  const CustomTabbed = ({ orientation }: { orientation: 'horizontal' | 'vertical' }) => {
    return (
      <Tabbed
        tabNames={['Sensors', 'Broker Control', 'Services', 'NbLoggers']}
        tabIcons={[
          <HighlightIcon key={0} />,
          <RouterIcon key={1} />,
          <SyncIcon key={2} />,
          <SettingsInputAntennaIcon key={3} />,
        ]}
        tabContents={[
          <SensorStatuses selectedFarmId={selectedFarmId} setSelectedFarmId={setSelectedFarmId} key={0} />,
          <BrokerControl getIdToken={tokenProvider} brokerStatus={brokerStatus} baseUrl={BASE} key={1} />,
          <ServicesStatus key={2} />,
          <NbLoggerStatuses key={3} />,
        ]}
        orientation={orientation}
      />
    );
  };

  return (
    <Container
      component='main'
      maxWidth='md'
      style={{ position: 'relative', height: '100vh', overflow: 'scroll' }}
    >
      <CssBaseline />
      <RefreshButton
        onClick={() => {
          dataLoggerStatusStore.updateStatuses();
          fetchBrokerStatus();
        }}
      />
      <Box className={classes.paper} boxShadow={3}>
        <Hidden mdDown>
          <CustomTabbed orientation='vertical' />
        </Hidden>
        <Hidden mdUp>
          <CustomTabbed orientation='horizontal' />
        </Hidden>
      </Box>
    </Container>
  );
});

const RefreshButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton
      onClick={onClick}
      edge='end'
      aria-label='delete'
      style={{
        position: 'absolute',
        right: 52,
        top: -48,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        background: 'rgba(255, 255, 255, 0.65)',
      }}
      size='large'
    >
      <SyncIcon />
    </IconButton>
  );
};

const ServicesStatus = () => {
  const [servicesStatus, setServicesStatus] = useState<
    Array<{
      service: string;
      status: string;
    }>
  >();
  useEffect(() => {
    const f = async () => {
      const res = await fetch(`${BASE_URL}/admin/services-status`);
      const json = (await res.json()) as [
        {
          service: string;
          status: string;
        }
      ];
      // check if json is a list
      if (!Array.isArray(json)) {
        console.error('services-status response is not a list');
        return;
      }
      setServicesStatus(json);
    };
    f();
  }, []);
  if (!servicesStatus || servicesStatus.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      {servicesStatus?.map(({ service, status }) => {
        return (
          <Card
            key={service}
            style={{
              margin: 10,
              padding: 10,
              display: 'flex',
              flexDirection: 'row',
              background: 'rgba(255, 255, 255, 0.4)',
            }}
          >
            {service}:{' '}
            <StatusCircle
              color={status === undefined ? grey[500] : status === 'running' ? green[500] : pink[500]}
              width={15}
              margin={5}
            />
          </Card>
        );
      })}
    </div>
  );
};

export default SystemStatusPage;
