import Box from '@mui/material/Box';
import type { LoadScriptNextProps } from '@react-google-maps/api';
import { LoadScriptNext } from '@react-google-maps/api';
import type { CSSProperties } from 'react';
import React, { forwardRef, useState } from 'react';
import Loading from '../Loading';

const googleMapsLibraries: LoadScriptNextProps['libraries'] = ['drawing', 'visualization', 'places'];

type Props = Readonly<{
  style: CSSProperties;
}>;

const MapWrapper: React.FC<Props> = forwardRef(({ style, children }, ref) => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  const [loadError, setLoadError] = useState<Error>();

  return (
    <Box
      ref={ref}
      style={{
        zIndex: 1,
        ...style,
      }}
    >
      {googleMapsApiKey == undefined ? (
        <div>Map cannot be loaded because no API key was provided</div>
      ) : (
        <LoadScriptNext
          googleMapsApiKey={googleMapsApiKey}
          libraries={googleMapsLibraries}
          loadingElement={<Loading color='secondary' style={{ margin: '0 auto' }} />}
          onError={setLoadError}
        >
          <>{loadError == undefined ? children : <div>Map cannot be loaded right now, sorry.</div>}</>
        </LoadScriptNext>
      )}
    </Box>
  );
});

export default MapWrapper;
