import type {
  FarmPrecipitationResponse,
  PrecipitationDataPoint,
  PrecipitationObservations,
} from '@soilsense/shared';
import type { IValidatedDateRangeObj } from 'components/RangeDatePicker';
import type { FirebaseTokenProvider } from 'interfaces/IUser';
import moment from 'moment';
import { JsonDecoder } from 'ts.data.json';
import { BASE_URL } from 'utils/consts';
import { fetchAndSet } from 'utils/fetchAndSet';

type PrecipitationBucket = Readonly<{
  endTimestamp: number;
  mm: number;
}>;

export type PrecipitationBuckets = readonly PrecipitationBucket[];

const RAINFALL_BUCKET_SIZE_DAYS = 1;

function createFarmPrecipitationResponseDecoder(): JsonDecoder.Decoder<FarmPrecipitationResponse> {
  const { object: obj, array: arr, number: num, dictionary: dict } = JsonDecoder;
  const observationsDecoder = arr<PrecipitationDataPoint>(
    obj({ timestamp: num, mm: num }, 'PrecipitationDataPoint'),
    'PrecipitationObservations'
  );
  return obj(
    {
      rainfall: observationsDecoder,
      rainfallFromWeatherObservations: dict(observationsDecoder, 'rainfallFromWeatherObservations'),
      irrigations: dict(observationsDecoder, 'irrigations'),
    },
    'FarmPrecipitationResponse'
  );
}

const FARM_PRECIPITATION_RESPONSE_DECODER = createFarmPrecipitationResponseDecoder();

export async function fetchPrecipitation(
  farmId: string,
  dateRange: IValidatedDateRangeObj,
  token: string | FirebaseTokenProvider
): Promise<FarmPrecipitationResponse> {
  const { startDate, endDate } = dateRange;
  const fromTimestamp = startDate.unix() * 1000;
  const toTimestamp = endDate.clone().add(1, 'day').unix() * 1000;
  const url = `${BASE_URL}/precipitation/${farmId}?from=${fromTimestamp}&to=${toTimestamp}`;

  const data = await fetchAndSet(url, typeof token == 'string' ? token : await token());
  return FARM_PRECIPITATION_RESPONSE_DECODER.decodeToPromise(data);
}

export function createPrecipitationBuckets(
  observations: PrecipitationObservations,
  dateRange: IValidatedDateRangeObj
): PrecipitationBuckets {
  if (observations.length === 0) {
    return [];
  }

  const { startDate, endDate } = dateRange;
  const result: PrecipitationBucket[] = [];

  let bucketStart = startDate.valueOf();
  let bucketEnd = startDate.clone().startOf('day').add(RAINFALL_BUCKET_SIZE_DAYS, 'day').valueOf();

  let dataIndex = 0;
  while (dataIndex < observations.length && observations[dataIndex].timestamp < bucketStart) {
    // precipitation may have occurred before the start of the date range since
    // the range may depend on a specific observation site whereas the data is
    // fetched for an entire farm
    dataIndex++;
  }
  while (bucketStart < endDate.valueOf()) {
    let mm = 0;
    while (
      dataIndex < observations.length &&
      observations[dataIndex].timestamp >= bucketStart &&
      observations[dataIndex].timestamp < bucketEnd
    ) {
      mm += observations[dataIndex].mm;
      dataIndex++;
    }
    result.push({ endTimestamp: bucketEnd, mm });
    bucketStart = bucketEnd;
    bucketEnd = moment(bucketEnd).add(RAINFALL_BUCKET_SIZE_DAYS, 'day').valueOf();
  }

  return result;
}
