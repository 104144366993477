import ApplyIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import type {
  MoistureSensorCalibration,
  ObservationSiteCalibrationSuggestion,
  SensorSlot,
  SoilDataSource,
  ValueHistoryItem,
} from '@soilsense/shared';
import { decodeObservationSiteCalibrationSuggestions } from '@soilsense/shared';
import axios from 'axios';
import deepEqual from 'deep-equal';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import type { SiteDetails } from '../../dataHandlers/ObservationSiteStore';
import { useFarmStore, useTokenProvider } from '../../dataHandlers/RootStore';
import type { SingleSensorData } from '../../dataHandlers/SensorTransformer';
import { customerVisibleDeviceId } from '../../dataHandlers/utils/formatters';
import { BASE_URL } from '../../utils/consts';
import { fetchAndSet } from '../../utils/fetchAndSet';
import getErrorMessage from '../../utils/getErrorMessage';
import { Alert } from '../CancelConfirmButton';
import CustomDialog from '../Dialog';
import { showErrorSnackBar, showSuccessSnackBar } from '../SnackBar';
import { CalibrationSuggestion } from './CalibrationSuggestion';
import { ChartJsMoistureChart } from './Chart/ChartJsMoistureChart';
import { useChartZoom } from './Chart/ZoomableChartGroup';
import type { DelimitedHistoryItem } from './HistoryItemSelector';
import { HistoryItemSelector, TIME_FORMAT } from './HistoryItemSelector';
import { SoilCalibrationTable } from './SoilCalibrationTable';

type OngoingSuggestionAction = 'none' | 'loading' | 'applying' | 'cancelling';

type Props = Readonly<{
  siteDetails: SiteDetails;
  sensorData: SingleSensorData;
  handleClose: () => void;
}>;

export const CalibrationHistoryDialog: FC<Props> = ({ siteDetails, sensorData, handleClose }) => {
  const farmStore = useFarmStore();
  const tokenProvider = useTokenProvider();
  const intl = useIntl();

  const [masterChartZoom] = useChartZoom(sensorData.data);
  const [soilDataSourceHistory, setSoilDataSourceHistory] = useState(siteDetails.site.soilDataSourceHistory);

  const [ongoingSuggestionAction, setOngoingSuggestionAction] = useState<OngoingSuggestionAction>('none');
  const [suggestions, setSuggestions] = useState<readonly ObservationSiteCalibrationSuggestion[]>([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number>();

  // used purely as a safety mechanism in the effect below; the separate ref is
  // needed in order to *not* re-launch the effect whenever the ongoing
  // suggestion action changes
  const ongoingSuggestionActionRef = useRef(ongoingSuggestionAction);
  useEffect(() => {
    ongoingSuggestionActionRef.current = ongoingSuggestionAction;
  }, [ongoingSuggestionAction]);

  useEffect(() => {
    let active = true;

    (async function () {
      if (ongoingSuggestionActionRef.current != 'none' || tokenProvider == undefined) {
        return;
      }
      try {
        setOngoingSuggestionAction('loading');
        const fromTimestamp = farmStore.selectedDates.startDate.valueOf();
        let toTimestamp = farmStore.selectedDates.endDate.valueOf();

        // if toTimestamp is today add 1 day to include today's data
        if (moment(toTimestamp).isSame(moment(), 'day')) {
          toTimestamp += 1000 * 60 * 60 * 24;
        }

        const token = await tokenProvider();
        const suggestions = await decodeObservationSiteCalibrationSuggestions(
          await fetchAndSet(
            `${BASE_URL}/calibration-suggestions/?observationSiteId=${siteDetails.site.id}&from=${fromTimestamp}&to=${toTimestamp}`,
            token
          )
        );
        console.log({ suggestions });
        if (active) {
          setSuggestions(suggestions);
        }
      } catch (err) {
        console.error(getErrorMessage(err));
        console.error(err);
      } finally {
        setOngoingSuggestionAction('none');
      }
    })();

    return () => {
      active = false;
    };
  }, [farmStore, siteDetails.site.id, tokenProvider]);

  const noChanges = useMemo(
    () => deepEqual(soilDataSourceHistory, siteDetails.site.soilDataSourceHistory),
    [siteDetails.site.soilDataSourceHistory, soilDataSourceHistory]
  );

  const [showCancelConfirmationPrompt, setShowCancelConfirmationPrompt] = React.useState(false);
  const onClose = useCallback(() => {
    if (noChanges) {
      handleClose();
    } else {
      setShowCancelConfirmationPrompt(true);
    }
  }, [handleClose, noChanges]);

  const delimitedHistory = useMemo(() => {
    const result: DelimitedHistoryItem<SoilDataSource | undefined>[] = [];
    const firstTimestamp = moment(sensorData.data[0].timestamp);
    const lastTimestamp = moment(sensorData.data[sensorData.data.length - 1].timestamp);
    for (let i = 0; i < soilDataSourceHistory.length; i++) {
      const { startTimestamp, value } = soilDataSourceHistory[i];

      const previousItem = i - 1 >= 0 ? soilDataSourceHistory[i - 1] : undefined;
      const previousStartTimestamp = previousItem?.startTimestamp;

      const nextItem = i + 1 < soilDataSourceHistory.length ? soilDataSourceHistory[i + 1] : undefined;
      const nextStartTimestamp = nextItem?.startTimestamp;

      const hidden =
        lastTimestamp.isBefore(startTimestamp) ||
        (nextItem != undefined && firstTimestamp.isAfter(nextItem.startTimestamp));
      result.push({ startTimestamp, previousStartTimestamp, nextStartTimestamp, hidden, value });
    }
    return result;
  }, [sensorData.data, soilDataSourceHistory]);

  const [selectedIndex, setSelectedIndex] = useState(() => {
    const available = delimitedHistory
      .map(({ hidden }, index) => ({
        hidden,
        index,
      }))
      .filter(({ hidden }) => hidden == false);
    return available[available.length - 1]?.index;
  });

  const selectedItem = useMemo(
    (): DelimitedHistoryItem<SoilDataSource | undefined> | undefined => delimitedHistory[selectedIndex],
    [delimitedHistory, selectedIndex]
  );

  const insertItem = useCallback(
    (startTimestamp: number, sensorCalibration?: ObservationSiteCalibrationSuggestion) => {
      setSoilDataSourceHistory((soilDataSourceHistory) => {
        const alreadyExists = soilDataSourceHistory.some((item) => item.startTimestamp == startTimestamp);
        if (alreadyExists) {
          return soilDataSourceHistory;
        }

        const nextItemIndex = soilDataSourceHistory.findIndex((item) => item.startTimestamp > startTimestamp);
        const previousItemIndex = nextItemIndex == -1 ? soilDataSourceHistory.length - 1 : nextItemIndex - 1;
        const prototypeItem = soilDataSourceHistory[previousItemIndex] ?? soilDataSourceHistory[nextItemIndex];
        if (prototypeItem == undefined) {
          return soilDataSourceHistory;
        }

        const newHistory = [...soilDataSourceHistory];

        let newItem: ValueHistoryItem<SoilDataSource | undefined> = { startTimestamp, value: prototypeItem.value };
        if (sensorCalibration != undefined) {
          const { sensorSlot, fieldCapacity, wiltingPoint } = sensorCalibration;
          if (prototypeItem.value?.configuration?.[sensorSlot] != undefined) {
            const { value } = prototypeItem;
            const { configuration } = value;
            newItem = {
              startTimestamp,
              value: {
                ...value,
                configuration: {
                  ...configuration,
                  [sensorSlot]: {
                    ...configuration[sensorSlot],
                    calibration: { fieldCapacity, wiltingPoint },
                  },
                },
              },
            };
          }
        }

        newHistory.splice(previousItemIndex + 1, 0, newItem);
        setSelectedIndex(previousItemIndex + 1);
        return newHistory;
      });
    },
    []
  );

  const soilDataSourceState = useMemo(
    () => ({
      history: delimitedHistory,
      selectedIndex,
      setSelectedIndex,
      insertItem,
      disabled: selectedSuggestionIndex != undefined,
    }),
    [delimitedHistory, selectedIndex, insertItem, selectedSuggestionIndex]
  );

  const setCalibration = useCallback(
    (sensorSlot: SensorSlot, calibration: MoistureSensorCalibration | undefined): void => {
      if (selectedItem?.value != undefined) {
        const value = selectedItem.value;
        const newItem: ValueHistoryItem<SoilDataSource | undefined> = {
          startTimestamp: selectedItem.startTimestamp,
          value: {
            ...value,
            configuration: {
              ...value.configuration,
              [sensorSlot]: {
                ...value.configuration[sensorSlot],
                calibration,
              },
            },
          },
        };
        const newHistory = [...soilDataSourceHistory];
        newHistory.splice(selectedIndex, 1, newItem);
        setSoilDataSourceHistory(newHistory);
      }
    },
    [selectedIndex, selectedItem, soilDataSourceHistory]
  );

  const setStartTimestamp = useCallback(
    (value: Moment | null) => {
      if (
        selectedItem != undefined &&
        value?.isBetween(selectedItem.previousStartTimestamp, selectedItem.nextStartTimestamp, undefined, '()')
      ) {
        const newHistory = [...soilDataSourceHistory];
        newHistory.splice(selectedIndex, 1, {
          ...soilDataSourceHistory[selectedIndex],
          startTimestamp: value.valueOf(),
        });
        setSoilDataSourceHistory(newHistory);
      }
    },
    [selectedIndex, selectedItem, soilDataSourceHistory]
  );

  const deleteSelectedItem = useCallback(() => {
    if (selectedItem != undefined) {
      const newHistory = [...soilDataSourceHistory];
      newHistory.splice(selectedIndex, 1);
      setSoilDataSourceHistory(newHistory);
      if (selectedIndex == soilDataSourceHistory.length - 1) {
        setSelectedIndex((index) => index - 1);
      }
    }
  }, [selectedIndex, selectedItem, soilDataSourceHistory]);

  const dismissSelectedSuggestion = useCallback(
    async (action: 'applying' | 'cancelling') => {
      if (
        ongoingSuggestionAction != 'none' ||
        selectedSuggestionIndex == undefined ||
        tokenProvider == undefined
      ) {
        return;
      }
      try {
        setOngoingSuggestionAction(action);
        const suggestion = suggestions[selectedSuggestionIndex];
        const token = await tokenProvider();
        await axios.post(`${BASE_URL}/calibration-suggestions/${suggestion.id}/dismiss`, null, {
          headers: { authorization: `Bearer ${token}` },
        });
        setSelectedSuggestionIndex(undefined);
        const newSuggestions = [...suggestions];
        newSuggestions[selectedSuggestionIndex] = { ...suggestion, dismissed: true };
        setSuggestions(newSuggestions);
      } catch (err) {
        console.error(err);
      } finally {
        setOngoingSuggestionAction('none');
      }
    },
    [ongoingSuggestionAction, selectedSuggestionIndex, suggestions, tokenProvider]
  );

  const applySelectedSuggestion = useCallback(async () => {
    await dismissSelectedSuggestion('applying');
  }, [dismissSelectedSuggestion]);

  const cancelSelectedSuggestion = useCallback(async () => {
    await dismissSelectedSuggestion('cancelling');
    deleteSelectedItem();
  }, [deleteSelectedItem, dismissSelectedSuggestion]);

  return (
    <CustomDialog
      title={intl.formatMessage({ id: 'adjust_soil_settings' })}
      handleClose={onClose}
      open={true}
      handleSave={async () => {
        try {
          await farmStore.updateSoilDataSourceHistory(siteDetails.site.id, soilDataSourceHistory);
          showSuccessSnackBar(intl.formatMessage({ id: 'calibrations_updated' }));
          handleClose();
        } catch (err) {
          console.error(err);
          showErrorSnackBar(intl.formatMessage({ id: 'error_updating_calibrations' }, { error: err as string }));
        }
      }}
      saveDisabled={noChanges}
      fullWidth={false}
      minWidth='800px'
    >
      <Box py={3} display='flex' flexDirection='column' gap={2}>
        <Box px={3}>
          <Box>
            <Typography variant='h6'>{siteDetails.site.name}</Typography>
            <span>ID: {customerVisibleDeviceId(siteDetails.deviceIds)}</span>
          </Box>
          <Box display='flex' my={2}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>{intl.formatMessage({ id: 'calibration_suggestion' })}</InputLabel>
              <Select
                label={intl.formatMessage({ id: 'calibration_suggestion' })}
                disabled={
                  suggestions.filter(({ dismissed }) => dismissed == false).length == 0 ||
                  ongoingSuggestionAction != 'none'
                }
                value={selectedSuggestionIndex ?? -1}
                onChange={(event) => {
                  if (selectedSuggestionIndex != undefined) {
                    deleteSelectedItem();
                  }
                  const value = event.target.value;
                  if (typeof value == 'number') {
                    setSelectedSuggestionIndex(value == -1 ? undefined : value);
                    const suggestion = suggestions[value];
                    if (suggestion != undefined) {
                      const { appliesFrom } = suggestion;
                      insertItem(appliesFrom, suggestion);
                    }
                  }
                }}
              >
                <MenuItem value={-1}>
                  {ongoingSuggestionAction == 'loading' ? (
                    <Box display='flex' justifyContent='center'>
                      <CircularProgress size={24} />
                    </Box>
                  ) : (
                    <Typography color='secondary'>
                      {/* Select a suggestion */}
                      {intl.formatMessage({ id: 'select_suggestion' })}
                    </Typography>
                  )}
                </MenuItem>
                {Array.from(suggestions.entries())
                  .filter(([_unusedIndex, { dismissed }]) => dismissed == false)
                  .map(([index, suggestion]) => (
                    <MenuItem key={suggestion.id} value={index}>
                      <CalibrationSuggestion value={suggestion} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <IconButton
              // aria-label='Apply calibration suggestion'
              aria-label={intl.formatMessage({ id: 'apply_calibration_suggestion' })}
              onClick={applySelectedSuggestion}
              disabled={selectedSuggestionIndex == undefined || ongoingSuggestionAction != 'none'}
              size='large'
            >
              {ongoingSuggestionAction == 'applying' ? <CircularProgress size={16} /> : <ApplyIcon />}
            </IconButton>
            <IconButton
              // aria-label='Cancel calibration suggestion'
              aria-label={intl.formatMessage({ id: 'cancel_calibration_suggestion' })}
              onClick={cancelSelectedSuggestion}
              disabled={selectedSuggestionIndex == undefined || ongoingSuggestionAction != 'none'}
              size='large'
            >
              {ongoingSuggestionAction == 'cancelling' ? <CircularProgress size={16} /> : <CancelIcon />}
            </IconButton>
          </Box>
          <Box display='flex' my={2} gap={2}>
            <HistoryItemSelector
              // label='Calibration history'
              label={intl.formatMessage({ id: 'calibration_history' })}
              state={soilDataSourceState}
            />
            <IconButton
              // aria-label='Delete history item'
              aria-label={intl.formatMessage({ id: 'delete_history_item' })}
              onClick={deleteSelectedItem}
              edge='start'
              disabled={
                selectedItem == undefined ||
                soilDataSourceHistory[selectedIndex]?.value?.deviceNumber !=
                  soilDataSourceHistory[selectedIndex - 1]?.value?.deviceNumber ||
                selectedSuggestionIndex != undefined
              }
              size='large'
            >
              <DeleteIcon />
            </IconButton>
          </Box>
          <Box m='auto' width='600px' display='flex' flexDirection='column' gap={2}>
            <DateTimePicker
              ampm={false}
              disableFuture
              value={selectedItem == undefined ? null : moment(selectedItem.startTimestamp)}
              minDateTime={
                selectedItem?.previousStartTimestamp == undefined
                  ? undefined
                  : moment(selectedItem.previousStartTimestamp)
              }
              maxDateTime={
                selectedItem?.nextStartTimestamp == undefined ? undefined : moment(selectedItem.nextStartTimestamp)
              }
              onChange={setStartTimestamp}
              label='Start time'
              renderInput={(props) => <TextField {...props} />}
              inputFormat={TIME_FORMAT}
            />
            <SoilCalibrationTable
              dataLoggerConfiguration={selectedItem?.value?.configuration}
              setCalibration={setCalibration}
            />
          </Box>
        </Box>
        <ChartJsMoistureChart
          sensorChartData={sensorData}
          safeRanges={siteDetails.site.safeRanges}
          displayMidDepth={siteDetails.configuration.cableMiddle != undefined}
          nameMap={siteDetails.nameMap}
          coordinates={siteDetails.site.coordinates}
          showPlantAvailableWater={false}
          displayPrecipitation={false}
          yLabel={intl.formatMessage({ id: 'volumetric_water_content' })}
          yMin={undefined}
          yMax={undefined}
          chartZoom={masterChartZoom}
          useSingleChart={false}
          soilDataSourceState={soilDataSourceState}
        />
      </Box>
      <Alert
        open={showCancelConfirmationPrompt}
        handleYes={() => {
          setShowCancelConfirmationPrompt(false);
          handleClose();
        }}
        handleNo={() => {
          setShowCancelConfirmationPrompt(false);
        }}
      />
    </CustomDialog>
  );
};
