import type { Column } from '@material-table/core';
import MaterialTable from '@material-table/core';
import type { PaperProps } from '@mui/material';
import { Paper } from '@mui/material';
import type { DataLoggerConfiguration, MoistureSensorCalibration, SensorSlot } from '@soilsense/shared';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import useAuthToken from '../../utils/useAuthToken';
import { deriveCalibration } from './FcCalibrationDialog';

type Props = Readonly<{
  dataLoggerConfiguration: DataLoggerConfiguration | undefined;
  setCalibration: (sensorSlot: SensorSlot, calibration: MoistureSensorCalibration | undefined) => void;
}>;

type Row = Readonly<{
  id: string;
  sensorSlot: 'cableTop' | 'cableMiddle' | 'cableBottom';
  name: string | undefined;
  fieldCapacity: number | undefined;
  wiltingPoint: number | undefined;
}>;

export const SoilCalibrationTable: FC<Props> = ({ dataLoggerConfiguration, setCalibration }): JSX.Element => {
  const intl = useIntl();
  const getIdToken = useAuthToken();
  const columns: Column<Row>[] = [
    {
      title: intl.formatMessage({ id: 'sensor' }),
      field: 'name',
      editable: 'never',
    },
    {
      title: intl.formatMessage({ id: 'field_capacity' }),
      field: 'fieldCapacity',
      type: 'numeric',
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: intl.formatMessage({ id: 'wilting_point' }),
      field: 'wiltingPoint',
      type: 'numeric',
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ];
  const rows: Row[] = ([] as Row[]).concat(
    rowsFromDataLoggerConfiguration(dataLoggerConfiguration, 'cableTop'),
    rowsFromDataLoggerConfiguration(dataLoggerConfiguration, 'cableMiddle'),
    rowsFromDataLoggerConfiguration(dataLoggerConfiguration, 'cableBottom')
  );
  return (
    <MaterialTable
      components={{
        Container: TableContainer,
        Toolbar: EmptyToolbar,
      }}
      columns={columns}
      data={rows}
      options={{
        search: false,
        sorting: false,
        draggable: false,
        paging: false,
      }}
      editable={{
        onRowUpdate: async ({ sensorSlot, fieldCapacity, wiltingPoint }) => {
          setCalibration(sensorSlot, await deriveCalibration(fieldCapacity, wiltingPoint, getIdToken, intl));
        },
      }}
    />
  );
};

function rowsFromDataLoggerConfiguration(
  configuration: DataLoggerConfiguration | undefined,
  sensorSlot: SensorSlot
): readonly Row[] {
  const sensorConfiguration = configuration?.[sensorSlot];
  if (sensorConfiguration == undefined) {
    return [];
  }
  return [
    {
      id: sensorSlot,
      sensorSlot,
      name: sensorConfiguration.name ?? `${sensorConfiguration.depth} cm`,
      fieldCapacity: sensorConfiguration.calibration?.fieldCapacity,
      wiltingPoint: sensorConfiguration.calibration?.wiltingPoint,
    },
  ];
}

function TableContainer(props: PaperProps) {
  return (
    <Paper
      {...props}
      elevation={0}
      style={{
        width: '100%',
      }}
    />
  );
}

function EmptyToolbar() {
  return <></>;
}
