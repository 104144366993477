import InfoIcon from '@mui/icons-material/Info';
import MaterialTooltip from '@mui/material/Tooltip';
import React from 'react';

const TooltipIcon: React.FC<{ secondaryText: string }> = (props) => {
  const { secondaryText, children } = props;

  return (
    <MaterialTooltip title={secondaryText}>
      <span>
        {children}
        <InfoIcon fontSize='small' style={{ marginLeft: '-5px' }} />
      </span>
    </MaterialTooltip>
  );
};

export default TooltipIcon;
