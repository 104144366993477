import axios from 'axios';

export const fetchAndSet = async (url: string, token?: string): Promise<unknown> => {
  if (!token) {
    throw new Error('Token was not set but tried to fetch data');
  }
  return axios
    .get(url, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((resp) => {
      console.log({ resp });
      return resp.data;
    });
};
