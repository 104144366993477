import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CircularProgress, Collapse, TextField, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import type { Coordinates } from '@soilsense/shared';
import { getAreaOfPolygon } from 'geolib';
import type { Dispatch, Ref } from 'react';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFarmStore } from '../../dataHandlers/RootStore';
import type { AreaId } from '../../interfaces/Area';
import type { FieldWithId } from '../../interfaces/Field';
import { getFieldCenter } from '../../interfaces/Field';
import getErrorMessage from '../../utils/getErrorMessage';
import { INTERACTION_COLOR, NEUTRAL_COLOR } from '../../utils/getMarkerColorFromReadings';
import { showErrorSnackBar, showSuccessSnackBar } from '../SnackBar';
import { FieldShape } from './FieldShape';

export type AddFieldOverlayHandle = Readonly<{
  handleMapClick: (coordinates: Coordinates) => 'stop' | 'proceed';
}>;

type Props = Readonly<{
  fieldDraft: FieldWithId | undefined;
  setFieldDraft: Dispatch<FieldWithId | undefined>;
  setSelectedArea: React.Dispatch<AreaId>;
}>;

const useStyles = makeStyles((theme) => ({
  mapToolbarContainer: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    maxWidth: '350px',
  },
  mapOverlay: {
    backgroundColor: alpha(theme.palette.background.default, 0.85),
    color: theme.palette.primary.dark,
  },
  mapOverlayButton: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  largeIcon: {
    fontSize: '2.5em',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function AddFieldOverlay({ fieldDraft, setFieldDraft, setSelectedArea }: Props, ref: Ref<AddFieldOverlayHandle>) {
  const classes = useStyles();
  const [addingField, setAddingField] = useState(false);
  const farmStore = useFarmStore();
  const intl = useIntl();

  useImperativeHandle(ref, () => ({
    handleMapClick: (coordinates) => {
      if (addingField == false && fieldDraft != undefined) {
        setFieldDraft({
          ...fieldDraft,
          path: [...fieldDraft.path, coordinates],
        });
        return 'stop';
      }
      return 'proceed';
    },
  }));

  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const onEdit = useCallback(() => {
    if (addingField == false && polygonRef.current != undefined && fieldDraft != undefined) {
      setFieldDraft({
        ...fieldDraft,
        path: polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() })),
      });
    }
  }, [addingField, fieldDraft, setFieldDraft]);

  const onAdd = useCallback(() => {
    let active = true;
    if (fieldDraft != undefined) {
      setAddingField(true);
      (async () => {
        try {
          await farmStore.addField(fieldDraft);
          if (active) {
            showSuccessSnackBar(intl.formatMessage({ id: 'field_added' }));
            setFieldDraft(undefined);
            setSelectedArea({ kind: 'field', fieldId: fieldDraft.id });
          }
        } catch (err) {
          if (active) {
            const message = getErrorMessage(err);
            showErrorSnackBar(intl.formatMessage({ id: 'field_not_added' }, { message }));
          }
        } finally {
          if (active) {
            setAddingField(false);
          }
        }
      })();
    }
    return () => (active = false);
  }, [farmStore, fieldDraft, setFieldDraft, setSelectedArea, intl]);

  const path = useMemo(() => (fieldDraft == undefined ? [] : [...fieldDraft.path]), [fieldDraft]);

  const collapseProps = useMemo(
    () => ({
      timeout: 200,
      className: classes.mapToolbarContainer,
    }),
    [classes.mapToolbarContainer]
  );

  return (
    <>
      <Collapse {...collapseProps} in={fieldDraft == undefined}>
        <Button
          variant='contained'
          classes={{ root: `${classes.mapOverlay} ${classes.mapOverlayButton}`, contained: classes.column }}
          onClick={() => {
            setFieldDraft({
              id: farmStore.generateNewId(),
              name: '',
              path: [],
            });
          }}
        >
          <AddIcon className={classes.largeIcon} />
          <Typography variant='button'>
            <FormattedMessage id='add_field' defaultMessage='Add Field' />
          </Typography>
        </Button>
      </Collapse>
      <Collapse {...collapseProps} in={fieldDraft != undefined}>
        <Card className={classes.mapOverlay}>
          <Box p={1} display='flex' flexDirection='column' gap={1}>
            <Box m='auto'>
              <Typography variant='h5'>
                <FormattedMessage id='add_field' defaultMessage='Add Field' />
              </Typography>
            </Box>
            <Typography color='textPrimary' variant='body1'>
              <FormattedMessage
                id='click_to_choose_field_corners'
                defaultMessage='Click to choose field corners on the map.'
              />
            </Typography>
            <TextField
              // label='Field name'
              label={intl.formatMessage({ id: 'field_name' })}
              variant='outlined'
              value={fieldDraft?.name ?? ''}
              onChange={(e) => {
                if (fieldDraft != undefined) {
                  setFieldDraft({ ...fieldDraft, name: e.target.value });
                }
              }}
            />
            <Typography color='textSecondary' variant='body2'>
              {/* Area: {(getAreaOfPolygon(path) / 10000).toFixed(1)} ha */}
              <FormattedMessage
                id='area'
                defaultMessage='Area: {area} ha'
                values={{ area: (getAreaOfPolygon(path) / 10000).toFixed(1) }}
              />
            </Typography>
            <Box display='flex' flexDirection='row' justifyContent='flex-end' gap={2}>
              <Button size='small' variant='text' onClick={() => setFieldDraft(undefined)}>
                <FormattedMessage id='cancel' defaultMessage='Cancel' />
              </Button>
              <Button
                size='small'
                variant='contained'
                color='primary'
                disabled={
                  fieldDraft == undefined || fieldDraft.name.trim() == '' || path.length < 3 || addingField
                }
                onClick={onAdd}
              >
                {addingField ? <CircularProgress size={18} /> : <FormattedMessage id='add' defaultMessage='Add' />}
              </Button>
            </Box>
          </Box>
        </Card>
        {fieldDraft != undefined && (
          <FieldShape
            field={fieldDraft}
            center={getFieldCenter(fieldDraft)}
            strokeColor={INTERACTION_COLOR}
            fillColor={NEUTRAL_COLOR}
            editable={addingField == false}
            onLoad={(polygon) => {
              polygonRef.current = polygon;
            }}
            onEdit={onEdit}
          />
        )}
      </Collapse>
    </>
  );
}

export default forwardRef(AddFieldOverlay);
