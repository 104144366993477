import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React from 'react';
import type { SiteDetails } from '../../../dataHandlers/ObservationSiteStore';
import assertNever from '../../../utils/assertNever';
import type { IValidatedDateRangeObj } from '../../RangeDatePicker';
import { ChartJsWeightedAverageChart, CHART_HEIGHT } from './ChartJsWeightedAverageChart';

const useStyles = makeStyles(() => ({
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: CHART_HEIGHT,
  },
}));

type Props = Readonly<{
  timeRange: IValidatedDateRangeObj;
  siteDetails: SiteDetails;
}>;

export const WeightedAverageChart: FC<Props> = observer(
  ({ timeRange, siteDetails: { site, transformed, configuration } }) => {
    const classes = useStyles();
    switch (transformed.status) {
      case 'pending':
        return (
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        );
      case 'error':
        return (
          <ChartJsWeightedAverageChart
            site={site}
            timeRange={timeRange}
            transformedData={[]}
            configuration={configuration}
          />
        );
      case 'ready':
        return (
          <ChartJsWeightedAverageChart
            site={site}
            timeRange={timeRange}
            transformedData={transformed.value.data}
            configuration={configuration}
          />
        );
      default:
        return assertNever(transformed);
    }
  }
);
