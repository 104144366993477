import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  shape: {
    backgroundColor: (props: IProps) => props.color || theme.palette.primary.main,
    width: (props: IProps) => props.width,
    height: (props: IProps) => props.width,
    margin: (props: IProps) => props.margin,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
}));

interface IProps {
  color: string;
  width: number;
  margin: string | number;
}

const Circle: FC<IProps> = (props) => {
  const classes = useStyles(props);
  return <div className={clsx(classes.shape, classes.shapeCircle)} />;
};

export default Circle;
