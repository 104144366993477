import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import type { CONTROL_OPTIONS, IControls } from 'components/Board/config';
import React from 'react';
import { TabLabels } from '../components/Navigation/Config';
import ControlPanelTabs from '../components/Navigation/ControlPanelTabs';
import ChartsControlPanel from '../components/SensorData/ChartsControlPanel';
import type { AreaId } from '../interfaces/Area';
import OverviewControlPanel from './SensorData/OverviewControlPanel';

interface IProps {
  disabled: boolean;
  selectedTab: number;
  handleTabChange: (newValue: React.ReactText) => void;
  tabLabels: string[];
  selectedArea: AreaId | undefined;
  setSelectedArea: React.Dispatch<AreaId>;
  forcedShowSelectionCounter: number;
  uiReactionLoading: boolean;
  controls: IControls;
  controlsOnChange: (key: CONTROL_OPTIONS) => void;
}

const DrawerContent: React.FC<IProps> = ({
  disabled,
  selectedTab,
  handleTabChange,
  tabLabels,
  selectedArea,
  setSelectedArea,
  forcedShowSelectionCounter,
  uiReactionLoading,
  controls,
  controlsOnChange,
}) => {
  return (
    <>
      <Hidden mdDown implementation='css'>
        <ControlPanelTabs
          disabled={uiReactionLoading || disabled}
          selectedTab={selectedTab}
          handleChange={handleTabChange}
          labels={tabLabels}
        />
      </Hidden>
      {selectedTab === TabLabels.Charts && (
        <ChartsControlPanel
          uiReactionLoading={uiReactionLoading}
          controls={controls}
          controlsOnChange={controlsOnChange}
        />
      )}
      {selectedTab === TabLabels.Overview ? (
        <OverviewControlPanel
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          forcedShowSelectionCounter={forcedShowSelectionCounter}
          uiReactionLoading={uiReactionLoading}
        />
      ) : undefined}
      <Divider />
    </>
  );
};

export default DrawerContent;
