import Link from '@mui/material/Link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Logo from '../components/Logo';

const domainUrl = window.location.href.split('/').slice(0, -1).join('/');

export const NotFound = ({ message }: { message?: string }): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Logo width={'60%'} style={{ position: 'absolute', bottom: 0, left: '5%', opacity: 0.5, zIndex: -1 }} />
      <h1 style={{ margin: '0 auto' }}>{message ?? <FormattedMessage id='not_found_page_not_found' />}</h1>
      <Link href={domainUrl} underline='hover'>
        <FormattedMessage id='not_found_back_to' />
        &nbps;
        {domainUrl}
      </Link>
    </div>
  );
};
