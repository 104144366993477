import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { Dispatch } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

type NumberConstraints = Readonly<{
  min?: number;
  max?: number;
  integer?: boolean;
}>;

type Props = NumberConstraints &
  Readonly<{
    label: string;
    value: number | undefined;
    setValue: Dispatch<number | undefined>;
    disabled?: boolean;
    fullWidth?: boolean;
    InputProps?: TextFieldProps['InputProps'];
    width?: string;
    hint?: string;
    hideHelperText?: boolean;
  }>;

export default function NumberField({
  label,
  value,
  setValue,
  min,
  max,
  integer,
  disabled,
  fullWidth,
  InputProps,
  width,
  hint,
  hideHelperText,
}: Props): JSX.Element {
  const [stringValue, setStringValue] = useState(value?.toString() ?? '');
  const [touched, setTouched] = useState(false);
  const intl = useIntl();

  // this is to ensure that when the `value` prop changes externally, the string
  // value in the text field gets updated as well (if necessary)
  const latestStringValue = useRef(stringValue);
  useEffect(() => {
    latestStringValue.current = stringValue;
  }, [stringValue]);
  useEffect(() => {
    if (parseNumber(latestStringValue.current, { min, max, integer }) != value) {
      setStringValue(value?.toString() ?? '');
    }
  }, [value, min, max, integer]);

  const error =
    touched && (value == undefined || (min != undefined && value < min) || (max != undefined && value > max));

  const helperText = hideHelperText
    ? ''
    : error
    ? min == undefined && max == undefined
      ? intl.formatMessage({ id: 'number_field_please_enter_a_number' })
      : min != undefined && max == undefined
      ? intl.formatMessage({ id: 'number_field_please_enter_a_number_that_is_greater_or_equal_to' }, { min })
      : min == undefined && max != undefined
      ? intl.formatMessage({ id: 'number_field_please_enter_a_number_that_is_less_or_equal_to' }, { max })
      : intl.formatMessage({ id: 'number_field_please_enter_a_number_between' }, { min, max })
    : hint ?? ' ';

  return (
    <TextField
      label={label}
      value={stringValue}
      variant='outlined'
      onChange={(e) => {
        setTouched(true);
        setStringValue(e.target.value);
        setValue(parseNumber(e.target.value, { min, max, integer }));
      }}
      onBlur={() => setTouched(true)}
      error={error}
      disabled={disabled}
      type='text'
      helperText={helperText}
      fullWidth={fullWidth}
      InputProps={InputProps}
      style={{ width }}
      inputProps={{ min, max }}
    />
  );
}

function parseNumber(input: string, constraints: NumberConstraints): number | undefined {
  if (input.trim() == '') {
    return undefined;
  }
  const number = Number(input);
  if (isFinite(number) == false) {
    return undefined;
  }
  const { min, max, integer } = constraints;
  if (min != undefined && number < min) {
    return undefined;
  }
  if (max != undefined && number > max) {
    return undefined;
  }
  if (integer && Number.isInteger(number) == false) {
    return undefined;
  }
  return number;
}
