import type { Chart, ChartEvent, ChartType, Plugin, UpdateMode } from 'chart.js';
import type { EmptyObject } from 'chart.js/types/basic';

type CrosshairPluginOptions = Readonly<{
  display: boolean;
}>;

class ChartJsCrosshairPlugin implements Plugin<ChartType, CrosshairPluginOptions> {
  id = 'crosshair';

  private crosshairPosition: number | undefined;
  private syncedCharts = new Set<Chart>();

  start(chart: Chart, _unusedArgs: EmptyObject, options: CrosshairPluginOptions): void {
    if (options.display === true) {
      this.syncedCharts.add(chart);
    }
  }

  afterUpdate(chart: Chart, _unusedArgs: { mode: UpdateMode }, options: CrosshairPluginOptions): void {
    if (options.display === true) {
      this.syncedCharts.add(chart);
    } else {
      this.syncedCharts.delete(chart);
    }
  }

  stop(chart: Chart): void {
    this.syncedCharts.delete(chart);
  }

  afterEvent(
    chart: Chart,
    args: { event: ChartEvent; replay: boolean; changed?: boolean; cancelable: false; inChartArea: boolean },
    options: CrosshairPluginOptions
  ): void {
    if (options.display !== true) {
      return;
    }

    const oldCrosshairPosition = this.crosshairPosition;
    const newCrosshairPositionCandidate =
      args.event.x != undefined && args.inChartArea ? chart.scales.x.getValueForPixel(args.event.x) : undefined;
    this.crosshairPosition =
      typeof newCrosshairPositionCandidate === 'number' && isFinite(newCrosshairPositionCandidate)
        ? newCrosshairPositionCandidate
        : undefined;
    if (oldCrosshairPosition != this.crosshairPosition) {
      args.changed = true;
      this.syncedCharts.forEach((each) => {
        if (each != chart) {
          each.draw();
        }
      });
    }
  }

  afterDatasetsDraw(
    chart: Chart,
    _unusedArgs: EmptyObject,
    options: CrosshairPluginOptions,
    _unusedCancelable: false
  ): void {
    if (options.display !== true) {
      return;
    }
    if (this.crosshairPosition == undefined) {
      return;
    }

    const xScale = chart.scales.x;
    const yScale = chart.scales.y;
    const lineX = xScale.getPixelForValue(this.crosshairPosition);

    const { ctx } = chart;
    ctx.save();
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#F66';
    ctx.moveTo(lineX, yScale.getPixelForValue(yScale.max));
    ctx.lineTo(lineX, yScale.getPixelForValue(yScale.min));
    ctx.stroke();
    ctx.restore();
  }
}

declare module 'chart.js' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    crosshair: CrosshairPluginOptions;
  }
}

export default new ChartJsCrosshairPlugin();
