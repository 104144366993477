import type { FirebaseApp } from 'firebase/app';
import { getApps, initializeApp } from 'firebase/app';
import type { Auth, User as FirebaseUser, UserCredential } from 'firebase/auth';
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import type { CollectionReference, DocumentReference, Firestore, Transaction } from 'firebase/firestore';
import {
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
  initializeFirestore,
  runTransaction,
} from 'firebase/firestore';
import type { MessagePayload, Messaging } from 'firebase/messaging';
import { getMessaging, getToken, isSupported as isMessagingSupported, onMessage } from 'firebase/messaging';
import type { UserUpdate } from '../dataHandlers/UserStore';
import type { FirebaseTokenProvider, UserIdentity, UserSettings } from '../interfaces/IUser';
import googleAnalyticsInstance from '../utils/googleAnalytics';

if (!process.env.REACT_APP_FIREBASE_KEY) {
  throw new Error('The environment variable for firebase might be missing');
}

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
} as const;

const FIRESTORE_SETTINGS = {
  ignoreUndefinedProperties: true,
} as const;

type CollectionName = 'deviceNames' | 'farms' | 'sensors' | 'gateways' | 'users';

type SetAuthenticatedUser = (user: UserUpdate | null) => void;

function getFirestoreSettings(isForEmulator: boolean) {
  if (isForEmulator) {
    return { ...FIRESTORE_SETTINGS, experimentalForceLongPolling: true };
  } else {
    return FIRESTORE_SETTINGS;
  }
}

export default class FirebaseApi {
  private readonly auth: Auth;
  public readonly db: Firestore;
  private readonly messaging: Promise<Messaging | undefined>;
  private currentUserWriteTransaction: Promise<void> = Promise.resolve();

  constructor(setAuthenticatedUser: SetAuthenticatedUser) {
    const apps = getApps();
    if (apps.length > 0) {
      const app = apps[0];
      this.auth = getAuth(app);
      this.db = getFirestore(app);
      this.messaging = getMessagingCarefully(app);
    } else {
      const { REACT_APP_FIREBASE_EMULATOR_HOSTNAME: emulatorHostname } = process.env;
      const app = initializeApp(FIREBASE_CONFIG);
      this.auth = getAuth(app);
      this.db = initializeFirestore(app, getFirestoreSettings(emulatorHostname != undefined));
      this.messaging = getMessagingCarefully(app);
      if (emulatorHostname != undefined) {
        connectFirestoreEmulator(this.db, emulatorHostname, 8080);
        connectAuthEmulator(this.auth, `http://${emulatorHostname}:9099`);
      }
    }

    // FIXME listener never cleaned up
    this.onAuthUserListener(setAuthenticatedUser);
  }

  public async getToken(
    serviceWorkerRegistration: ServiceWorkerRegistration | undefined
  ): Promise<string | undefined> {
    const messaging = await this.messaging;
    if (messaging == undefined) {
      return undefined;
    }
    return await getToken(messaging, { serviceWorkerRegistration });
  }

  public async runTransaction(updateFunction: (transaction: Transaction) => Promise<void>): Promise<void> {
    await runTransaction(this.db, updateFunction);
  }

  public onMessage(listener: (payload: MessagePayload) => void): () => void {
    let alive = true;
    let unsubscribe: (() => void) | undefined;

    (async () => {
      const messaging = await this.messaging;
      if (messaging != undefined && alive) {
        unsubscribe = onMessage(messaging, listener);
      }
    })();

    return () => {
      alive = false;
      unsubscribe?.();
    };
  }

  public getCollections(): Readonly<{
    [collectionName in CollectionName]: () => CollectionReference;
  }> {
    return {
      deviceNames: () => collection(this.db, 'deviceNames'),
      farms: () => collection(this.db, 'farms'),
      sensors: () => collection(this.db, 'sensors'),
      gateways: () => collection(this.db, 'gateways'),
      users: () => collection(this.db, 'users'),
    };
  }

  public getDocRef(address: string): DocumentReference {
    return doc(this.db, address);
  }

  public async sendPasswordResetEmail(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }

  public async signInWithEmailAndPassword(email: string, password: string): Promise<void> {
    await signInWithEmailAndPassword(this.auth, email, password);
  }

  public async createUserWithEmailAndPassword(
    email: string,
    password: string,
    initializeUser: (authUser: UserCredential) => Promise<void>
  ): Promise<void> {
    this.currentUserWriteTransaction = (async () => {
      const authUser = await createUserWithEmailAndPassword(this.auth, email, password);
      await initializeUser(authUser);
    })();
    try {
      await this.currentUserWriteTransaction;
    } finally {
      this.currentUserWriteTransaction = Promise.resolve();
    }
  }

  public async logOut(): Promise<void> {
    await this.auth.signOut();
  }

  private provideUserToGoogleAnalytics(authUser: FirebaseUser) {
    googleAnalyticsInstance.setUser(authUser.uid);
  }

  public getUser(uid: string): DocumentReference {
    return this.getDocRef(`users/${uid}`);
  }

  private async getAndMergeUser(authUser: FirebaseUser | null): Promise<UserUpdate | null> {
    if (authUser == null) {
      return null;
    }

    const identity = toUserIdentity(authUser);
    const tokenProvider = toTokenProvider(authUser);
    const dbUserSnapshot = await getDoc(this.getUser(authUser.uid));
    const settings = dbUserSnapshot.data() as UserSettings;
    this.provideUserToGoogleAnalytics(authUser);
    return {
      user: { ...identity, ...settings },
      tokenProvider,
    };
  }

  private onAuthUserListener(setAuthenticatedUser: SetAuthenticatedUser): () => void {
    return this.auth.onIdTokenChanged(async (authUser: FirebaseUser | null) => {
      await this.currentUserWriteTransaction;
      const userData = await this.getAndMergeUser(authUser);
      setAuthenticatedUser(userData);
    });
  }
}

function toUserIdentity(user: FirebaseUser): UserIdentity {
  const { uid, email, emailVerified } = user;
  return { uid, email, emailVerified };
}

function toTokenProvider(user: FirebaseUser): FirebaseTokenProvider {
  return () => user.getIdToken();
}

async function getMessagingCarefully(app: FirebaseApp): Promise<Messaging | undefined> {
  // Calling getMessaging() when it is not supported leads to an unhandled
  // promise rejection. Firebase Cloud Messaging is not supported on Safari.
  const isSupported = await isMessagingSupported();
  if (isSupported) {
    return getMessaging(app);
  } else {
    console.warn('Firebase Cloud Messaging is not supported on this device');
    return undefined;
  }
}
