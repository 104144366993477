import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { green, grey, pink } from '@mui/material/colors';
import React, { useCallback, useState } from 'react';
import type { FirebaseTokenProvider } from '../../interfaces/IUser';
import { fetchAndSet } from '../../utils/fetchAndSet';
import getErrorMessage from '../../utils/getErrorMessage';
import StatusCircle from '../Circle';
import Progress from '../Progress';
import { showErrorSnackBar, showSuccessSnackBar } from '../SnackBar';
import { useStyles } from './styles';

interface IBrokerControlProps {
  getIdToken: FirebaseTokenProvider | undefined;
  brokerStatus: boolean | undefined;
  baseUrl: string;
}

export enum BrokerControlEndPoints {
  UPDATE_TRANSMISSION_INTERVAL = 'update-transmission-interval',
  UPDATE_SLEEP_TIME = 'update-sleep-time',
  GET_STATUS = 'get-status',
}

const BrokerControl = ({ getIdToken, brokerStatus, baseUrl }: IBrokerControlProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Typography variant='h6' className={classes.title}>
          Broker
          <StatusCircle
            color={brokerStatus === undefined ? grey[500] : brokerStatus ? green[500] : pink[500]}
            width={15}
            margin={5}
          />
        </Typography>
        <Typography variant='caption'>{brokerStatus}</Typography>
        <Divider />
        <Box paddingY={1} />
        <Typography variant='caption' className={classes.title}>
          Sleep Time Control
        </Typography>
        <SleepTimeControl getIdToken={getIdToken} baseUrl={baseUrl} />
        <Typography variant='caption' className={classes.title}>
          Transmission Interval Control
        </Typography>
        <TransmissionIntervalControl getIdToken={getIdToken} baseUrl={baseUrl} />
      </Grid>
    </div>
  );
};

interface IControlProps {
  getIdToken?: FirebaseTokenProvider;
  valueLabel: string;
  endpointString: string;
  baseUrl: string;
}

const Control = ({ getIdToken, valueLabel, endpointString, baseUrl }: IControlProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (event) => {
      const token = await getIdToken?.();
      event.preventDefault();
      const { gatewayNumber, value } = event.target.elements;
      setLoading(true);
      try {
        fetchAndSet(`${baseUrl}/${endpointString}/${gatewayNumber.value}/${value.value}`, token)
          .then(() => {
            showSuccessSnackBar(`Successfully published the ${valueLabel} message`);
            setLoading(false);
          })
          .catch((e) => {
            const message = getErrorMessage(e);
            showErrorSnackBar(message);
            console.error(message);
            setLoading(false);
          });
      } catch (error) {
        const message = getErrorMessage(error);
        showErrorSnackBar(message);
      }
    },
    [endpointString, getIdToken, valueLabel, baseUrl]
  );

  return (
    <div className={classes.demo}>
      <form onSubmit={onSubmit}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='gatewayNumber'
          label='Gateway Number'
          name='gatewayNumber'
          autoComplete='off'
          autoFocus
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='value'
          label={valueLabel}
          name='value'
          autoComplete='off'
          autoFocus
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          // className={classes.submit}
          disabled={loading}
        >
          Publish
          <p style={{ width: 5 }} />
          {loading ? <Progress size={20} /> : undefined}
        </Button>
      </form>
    </div>
  );
};

const TransmissionIntervalControl = ({
  getIdToken,
  baseUrl,
}: {
  getIdToken?: FirebaseTokenProvider;
  baseUrl: string;
}) => {
  return (
    <Control
      getIdToken={getIdToken}
      valueLabel={'Transimission Interval'}
      endpointString={BrokerControlEndPoints.UPDATE_TRANSMISSION_INTERVAL}
      baseUrl={baseUrl}
    />
  );
};

const SleepTimeControl = ({ getIdToken, baseUrl }: { getIdToken?: FirebaseTokenProvider; baseUrl: string }) => {
  return (
    <Control
      getIdToken={getIdToken}
      valueLabel={'Sleep Time'}
      endpointString={BrokerControlEndPoints.UPDATE_SLEEP_TIME}
      baseUrl={baseUrl}
    />
  );
};

export default BrokerControl;
