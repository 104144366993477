import axios from 'axios';

export const post = async (url: string, token: string, body: unknown): Promise<unknown> => {
  if (!token) {
    throw new Error('Token was not set but tried to fetch data');
  }
  return axios
    .post(url, body, {
      headers: { authorization: `Bearer ${token}` },
    })
    .then((resp) => resp.data);
};
