import type { IntlShape } from 'react-intl';

const toSnakeCase = (str: string) => str.split(' ').join('_').toLowerCase();

/* the depth names examples: "Moisture 5cm", "Moisture 10cm", "Moisture 20cm",
 "Box temperature", "Soil temperature 5cm", "Soil temperature 10cm", "Soil temperature 20cm" */
export const translateDethNames = (name: string, intl: IntlShape, defaultMessage?: string) => {
  // assuming the last element of split in space character
  // is the depth
  const split = name.split(' ');
  const depth = split[split.length - 1];
  const variableName = name.replace(depth, '').trim();
  const variableNameId = toSnakeCase(variableName);

  const translatedPrefix = intl.formatMessage({
    id: `depth_names_${variableNameId}`,
    defaultMessage: defaultMessage ?? variableName,
  });

  return `${translatedPrefix} ${depth}`;
};
