import type { SensorSlot } from '@soilsense/shared';

type SensorMeasurementType = 'moistureColor' | 'temperatureColor' | 'salinityColor';

type ChartColors = Readonly<
  {
    [key in SensorMeasurementType]: Readonly<{
      [slot in SensorSlot]: string;
    }>;
  } & {
    airTemperatureColor: string;
    safeRangeColor: string;
    safeRangeBackgroundColor: string;
  }
>;

export function getChartColors(): ChartColors {
  return {
    moistureColor: {
      cableTop: '#338FF2',
      cableMiddle: '#2364AA',
      cableBottom: '#183554',
    },
    temperatureColor: {
      cableTop: '#338FF2',
      cableMiddle: '#2364AA',
      cableBottom: '#183554',
    },
    salinityColor: {
      cableTop: '#338FF2',
      cableMiddle: '#2364AA',
      cableBottom: '#183554',
    },
    airTemperatureColor: '#2364AA',
    safeRangeColor: '#04724D',
    safeRangeBackgroundColor: 'rgba(132, 227, 127, 0.1)',
  };
}
