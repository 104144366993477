const delayRendering = (callback: () => void, setLoading: (x: boolean) => void, delay = 500): void => {
  setLoading(true);
  setTimeout(() => {
    callback();
  }, 100);
  setTimeout(() => {
    setLoading(false);
  }, delay);
};

export default delayRendering;
