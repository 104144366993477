import type { DataLoggerConfiguration, ObservationSite, SoilDataSource } from '@soilsense/shared';
import deepEqual from 'deep-equal';
import type { IValidatedSensorSetting } from '../components/Settings/settingsState';

const STRESS_RULE_NAME = 'Plant stress';
const OVERIRRIGATION_RULE_NAME = 'Over-irrigation';

export function determineObservationSiteUpdate(
  site: ObservationSite,
  lastSoilDataSource: SoilDataSource,
  settings: IValidatedSensorSetting,
  timestamp: number
): ObservationSite {
  const disabledRules = extractDisabledRules(settings);
  const name = settings.name ?? '';
  const { pawStressThreshold, pawOverIrrigationThreshold, vwcStressThreshold, vwcOverIrrigationThreshold } =
    settings;
  const coordinates = settings.location;

  const { deviceNumber, deviceName, configuration: lastConfiguration } = lastSoilDataSource;
  const configuration = updateDataLoggerConfiguration(lastSoilDataSource.configuration, settings);
  const soilDataSourceHistory = [...site.soilDataSourceHistory];
  if (deepEqual(lastConfiguration, configuration) === false) {
    soilDataSourceHistory.push({
      startTimestamp: timestamp,
      value: {
        deviceNumber,
        deviceName,
        configuration,
      },
    });
  }

  return {
    disabledRules,
    name,
    safeRanges: {
      plantAvailableWater:
        pawStressThreshold != undefined && pawOverIrrigationThreshold != undefined
          ? [pawStressThreshold, pawOverIrrigationThreshold]
          : undefined,
      volumetricWaterContent:
        vwcStressThreshold != undefined && vwcOverIrrigationThreshold != undefined
          ? [vwcStressThreshold, vwcOverIrrigationThreshold]
          : undefined,
      salinity: site.safeRanges.salinity,
    },
    coordinates: coordinates ?? site.coordinates,
    soilDataSourceHistory,
  };
}

export function extractDisabledRules(settings: IValidatedSensorSetting): string[] {
  const disabledRules = [];
  if (!settings.subscribeToPawStress) disabledRules.push(STRESS_RULE_NAME);
  if (!settings.subscribeToPawOverIrrigation) disabledRules.push(OVERIRRIGATION_RULE_NAME);
  return disabledRules;
}

function updateDataLoggerConfiguration(
  lastConfiguration: DataLoggerConfiguration,
  settings: IValidatedSensorSetting
): DataLoggerConfiguration {
  const { topDepth, midDepth, botDepth } = settings;
  const cableTop = { ...lastConfiguration.cableTop, depth: topDepth };
  const cableMiddle =
    midDepth == undefined || midDepth == 'disabled'
      ? undefined
      : { ...lastConfiguration.cableMiddle, depth: midDepth };
  const cableBottom = { ...lastConfiguration.cableBottom, depth: botDepth };
  const { topAndBottomCablesSwapped, placement } = lastConfiguration;
  return {
    cableTop,
    cableMiddle,
    cableBottom,
    topAndBottomCablesSwapped,
    placement,
  };
}

export function archivedObservationSite(site: ObservationSite, timestamp?: number): ObservationSite {
  let startTimestamp = timestamp;
  if (!startTimestamp) {
    startTimestamp = Date.now();
  }
  const soilDataSourceHistory = [...site.soilDataSourceHistory];
  soilDataSourceHistory.push({
    startTimestamp,
    value: undefined,
  });
  return { ...site, soilDataSourceHistory };
}
