export const cropTypeToDepths = [
  {
    label: 'Broccoli',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Brussel Sprouts',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Cabbage',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Carrots',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Cauliflower',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Celery',
    shallowDepth: 40,
    midDepth: 60,
    deeperDepth: 75,
  },
  {
    label: 'Garlic',
    shallowDepth: 35,
    midDepth: 55,
    deeperDepth: 80,
  },
  {
    label: 'Lettuce',
    shallowDepth: 35,
    midDepth: 55,
    deeperDepth: 80,
  },
  {
    label: 'Onions',
    shallowDepth: 35,
    midDepth: 55,
    deeperDepth: 80,
  },
  {
    label: 'Spinach',
    shallowDepth: 40,
    midDepth: 60,
    deeperDepth: 75,
  },
  {
    label: 'Radishes',
    shallowDepth: 35,
    midDepth: 55,
    deeperDepth: 80,
  },
  {
    label: 'Egg Plant',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Sweet Peppers (bell)',
    shallowDepth: 35,
    midDepth: 55,
    deeperDepth: 80,
  },
  {
    label: 'Tomato',
    shallowDepth: 30,
    midDepth: 50,
    deeperDepth: 75,
  },
  {
    label: 'Cucumber',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Pumpkin',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Squash, Zucchini',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Sweet Melons',
    shallowDepth: 30,
    midDepth: 50,
    deeperDepth: 75,
  },
  {
    label: 'Watermelon',
    shallowDepth: 30,
    midDepth: 50,
    deeperDepth: 75,
  },
  {
    label: 'Beets, table',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Cassava',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Parsnip',
    shallowDepth: 30,
    midDepth: 50,
    deeperDepth: 75,
  },
  {
    label: 'Potato',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Sweet Potato',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Turnip (and Rutabaga)',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Sugar Beet',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Beans',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Chickpea',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Fababean (broad bean)',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Grabanzo',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Green Gram and Cowpeas',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Groundnut (Peanut)',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Lentils',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Peas',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Soybeans',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Artichokes',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Asparagus',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Mint',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Strawberries',
    shallowDepth: 40,
    midDepth: 60,
    deeperDepth: 75,
  },
  {
    label: 'Cotton',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Flax',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Sisal',
    shallowDepth: 5,
    midDepth: 25,
    deeperDepth: 50,
  },
  {
    label: 'Castorbean (Ricinus)',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Rapeseed, Canola',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Safflower',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Sesame',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Sunflower',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Barley',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Oats',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Spring Wheat',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Winter Wheat',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Maize, Field (grain) (field corn)',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Maize, Sweet (sweet corn)',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Millet',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Sorghum',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Rice',
    shallowDepth: 40,
    midDepth: 60,
    deeperDepth: 80,
  },
  {
    label: 'Alfalfa',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Bermuda',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Clover hay, Berseem',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Rye Grass hay',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Grazing Pasture',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Turf grass',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Sugar Cane',
    shallowDepth: 5,
    midDepth: 25,
    deeperDepth: 50,
  },
  {
    label: 'Banana',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Cacao',
    shallowDepth: 25,
    midDepth: 45,
    deeperDepth: 70,
  },
  {
    label: 'Coffee',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Date Palms',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Palm Trees',
    shallowDepth: 5,
    midDepth: 25,
    deeperDepth: 50,
  },
  {
    label: 'Pineapple',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Rubber Trees',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Tea (non-shaded)',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Tea (shaded)',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Berries (bushes)',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Grapes (table or raisin)',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Grapes (Wine)',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Hops',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Almonds',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Apples',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Cherries',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Pears',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Peaches',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Apricots',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Genera Stone Fruit',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Avocado',
    shallowDepth: 15,
    midDepth: 35,
    deeperDepth: 60,
  },
  {
    label: 'Citrus',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
  {
    label: 'Conifer Trees',
    shallowDepth: 5,
    midDepth: 25,
    deeperDepth: 50,
  },
  {
    label: 'Kiwi',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Olives',
    shallowDepth: 5,
    midDepth: 25,
    deeperDepth: 50,
  },
  {
    label: 'Pistachios',
    shallowDepth: 20,
    midDepth: 40,
    deeperDepth: 65,
  },
  {
    label: 'Walnuts',
    shallowDepth: 10,
    midDepth: 30,
    deeperDepth: 55,
  },
].sort((a, b) => a.label.localeCompare(b.label));
