import type { Dispatch } from 'react';
import { useCallback, useState } from 'react';

export function useLocalStorage(key: string, initialValue = ''): [string, Dispatch<string>] {
  const [value, setValue] = useState(() => window.localStorage.getItem(key) ?? initialValue);
  const setLocalStorageAndValue = useCallback(
    (value: string) => {
      window.localStorage.setItem(key, value);
      setValue(value);
    },
    [key]
  );
  return [value, setLocalStorageAndValue];
}
