import { showInfoSnackBar } from 'components/SnackBar';
import { isDefined } from '../../utils/isDefined';
import type { ISettingsState } from './index';
import { INVALID_PHONE_NUMBER_MESSAGE, isValidPhoneNumber } from './PhoneNumbersInput';
import type { ISensorSetting, ISensorSettings, IValidatedSensorSettings } from './settingsState';

const atLeastOneInvalidPhoneNumber = (phoneNumbers: string[]) => {
  const res = phoneNumbers.map((p) => isValidPhoneNumber.test(p));
  if (!res.every((v) => v)) {
    return true;
  }
  return false;
};

const atLeastOneInvalidSensorThresholds = (sensorSettings: ISensorSettings) => {
  const singleSensorThresholdsSettingIsValid = (s: ISensorSetting) => {
    const pawRangeDefined = isDefined(s.pawStressThreshold) && isDefined(s.pawOverIrrigationThreshold);
    const vwcRangeDefined = isDefined(s.vwcStressThreshold) && isDefined(s.vwcOverIrrigationThreshold);
    const vwcRangeUndefined = !isDefined(s.vwcStressThreshold) && !isDefined(s.vwcOverIrrigationThreshold);
    return pawRangeDefined && (vwcRangeDefined || vwcRangeUndefined);
  };

  const validityArray = Object.values(sensorSettings).map(singleSensorThresholdsSettingIsValid);
  return !validityArray.every((v) => v);
};

const runValidations = (
  settingsState: ISettingsState,
  sensorSettings: ISensorSettings
): IValidatedSensorSettings | null => {
  if (settingsState.farm.enableWhatsappNotif && settingsState.farm.phoneNumbers.length === 0) {
    showInfoSnackBar('At least one phone number is required to enable instant notifications');
    return null;
  }

  if (settingsState.farm.phoneNumbers && atLeastOneInvalidPhoneNumber(settingsState.farm.phoneNumbers)) {
    showInfoSnackBar(INVALID_PHONE_NUMBER_MESSAGE);
    return null;
  }

  if (atLeastOneInvalidSensorThresholds(sensorSettings)) {
    showInfoSnackBar('Please provide a correct value for sensor threshold settings');
    return null;
  }

  return sensorSettings as IValidatedSensorSettings;
};

export default runValidations;
