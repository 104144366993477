import { useEffect, useRef } from 'react';

const DISABLED = false;

function useTraceUpdate(props: Record<string, unknown>, name?: string): void {
  const prev = useRef(props);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && !DISABLED) {
      const changedProps = Object.entries(props).reduce((ps: Record<string, unknown>, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      }, {});
      if (Object.keys(changedProps).length > 0) {
        if (name) {
          console.log(`${name} changed props:`, changedProps);
        } else {
          console.log(`Changed props:`, changedProps);
        }
      }
      prev.current = props;
    }
  });
}

export default useTraceUpdate;
