import type { FirebaseTokenProvider } from 'interfaces/IUser';
import { useTokenProvider } from '../dataHandlers/RootStore';

export default function useAuthToken(): FirebaseTokenProvider {
  const getIdToken = useTokenProvider();
  if (getIdToken == undefined) {
    throw new Error('No user is logged in');
  }
  return getIdToken;
}
