import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { calculateIrrigationStatusBoxes } from 'dataHandlers/SensorTransformer';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React from 'react';
import assertNever from 'utils/assertNever';
import type { IValidatedDateRangeObj } from '../../../components/RangeDatePicker';
import type { SiteDetails } from '../../../dataHandlers/ObservationSiteStore';
import { ChartJsColorBoxChart, CHART_HEIGHT } from './ChartJsColorBoxChart';

const useStyles = makeStyles(() => ({
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: CHART_HEIGHT,
  },
}));

type Props = Readonly<{
  timeRange: IValidatedDateRangeObj;
  siteDetails: SiteDetails;
}>;

export const ColorBoxChart: FC<Props> = observer(({ timeRange, siteDetails: { transformed } }) => {
  const classes = useStyles();
  switch (transformed.status) {
    case 'pending':
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    case 'error':
      return <ChartJsColorBoxChart boxes={calculateIrrigationStatusBoxes(timeRange, [])} />;
    case 'ready':
      return <ChartJsColorBoxChart boxes={transformed.value.statusBoxes} />;
    default:
      return assertNever(transformed);
  }
});
