import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Routes } from '../Routes';
import Logo, { LogoColor } from './Logo';
import Progress from './Progress';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      overflow: 'hidden',
    },
    body: {
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgb(255, 255, 255, 0.65)',
    padding: 20,
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  glowingText: {
    textShadow: `0 0 5px #fff`,
  },
}));

interface IProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  loading?: boolean;
}

const ForgotPassword: React.FC<IProps> = ({ onSubmit, loading }) => {
  const classes = useStyles();

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box className={classes.paper} boxShadow={3}>
        <Logo color={LogoColor.red} width={100} />
        <Typography component='h1' variant='h5'>
          <FormattedMessage id='forgot_password_title' />
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={loading}
          >
            <FormattedMessage id='forgot_password_submit' />
            <p style={{ width: 5 }} />
            {loading ? <Progress size={20} /> : undefined}
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Link href={Routes.LOGIN} variant='body2' className={classes.glowingText} underline='hover'>
                <FormattedMessage id='forgot_password_sign_in' />
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
