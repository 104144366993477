import HighlightIcon from '@mui/icons-material/Highlight';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import type { Sensor } from '@soilsense/shared';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDataLoggerStatusStore, useFirebase, useTokenProvider } from '../../dataHandlers/RootStore';
import { BASE_URL } from '../../utils/consts';
import { fetchAndSet } from '../../utils/fetchAndSet';
import { useStyles } from './styles';

const isWithinAnHour = (date: Moment) => {
  return moment().diff(date, 'minutes') <= 60;
};

const getIconBasedOnRssi = (rssi: number | undefined) => {
  if (!rssi) return <WifiOffIcon />;

  if (rssi < -90) return <SignalWifi0BarIcon />;

  if (rssi < -70) return <SignalWifi1BarIcon />;

  if (rssi < -50) return <SignalWifi2BarIcon />;

  if (rssi < -30) return <SignalWifi3BarIcon />;

  return <SignalWifi4BarIcon />;
};

const NbLoggerStatuses = observer(() => {
  const classes = useStyles();
  const dataLoggerStatusStore = useDataLoggerStatusStore();
  const firebase = useFirebase();
  // sensors state
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [lastReadings, setLastReadings] = useState<
    Record<string, { rawData: { rssi: number }; timestamp: string } | undefined>
  >({});
  const tokenProvider = useTokenProvider();

  // get everything from sensor collection in firestore
  useEffect(() => {
    const getNbLoggers = async () => {
      const sensors = await firebase.sensors.getAllNbLoggerSensors();
      setSensors(sensors as Sensor[]);
    };
    getNbLoggers();
  }, [firebase.sensors]);

  useEffect(() => {
    sensors.forEach((sensor) => {
      const getLastReading = async () => {
        if (!tokenProvider) return;
        const token = await tokenProvider();
        const readings = (await fetchAndSet(`${BASE_URL}/observations/last/${sensor.id}`, token)) as {
          rawData: { rssi: number };
          timestamp: string;
        };
        console.log({ readings });
        setLastReadings((prev) => ({ ...prev, [sensor.id]: readings }));
      };

      getLastReading();
    });

    return;
  }, [sensors, dataLoggerStatusStore, tokenProvider]);

  return (
    <div className={classes.root}>
      <Box display='flex' flexDirection='column' gap={1.5}>
        <Typography variant='h6' className={classes.title}>
          Sensors
        </Typography>
        <Typography variant='caption'>(green if communicated within last hour)</Typography>
        <div className={classes.demo}>
          <List dense={true}>
            {sensors
              .filter((sensor) => sensor.farm)
              .map((sensor) => {
                const sensorId = sensor.id;
                // const lastReadingTimestamp = dataLoggerStatusStore.getLastReadingTime(sensorId);
                const lastReading = lastReadings[sensorId];
                const lastReadingTimestamp = lastReading?.timestamp ? moment(lastReading.timestamp) : undefined;
                if (dataLoggerStatusStore.objectIsLoading(sensorId)) {
                  return (
                    <Item primaryText={sensorId} secondaryText={'Loading...'}>
                      <CircularProgress size={48} />
                    </Item>
                  );
                }

                return (
                  <Item
                    key={sensorId}
                    primaryText={
                      lastReading?.rawData?.rssi ? `${sensorId} (rssi: ${lastReading?.rawData?.rssi})` : sensorId
                    }
                    secondaryText={lastReadingTimestamp?.format('MMM Do hh:mm:ss A (YYYY)')}
                    colorClass={
                      !lastReadingTimestamp
                        ? classes.gray
                        : isWithinAnHour(lastReadingTimestamp)
                        ? classes.green
                        : classes.pink
                    }
                  >
                    <ListItemSecondaryAction>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => {
                          dataLoggerStatusStore.updateStatus(sensorId);
                        }}
                        size='large'
                      >
                        {getIconBasedOnRssi(lastReading?.rawData?.rssi)}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Item>
                );
              })}
            ----
            {sensors
              .filter((sensor) => !sensor.farm)
              .map((sensor) => {
                const sensorId = sensor.id;
                // const lastReadingTimestamp = dataLoggerStatusStore.getLastReadingTime(sensorId);
                const lastReading = lastReadings[sensorId];
                const lastReadingTimestamp = lastReading?.timestamp ? moment(lastReading.timestamp) : undefined;
                if (dataLoggerStatusStore.objectIsLoading(sensorId)) {
                  return (
                    <Item primaryText={sensorId} secondaryText={'Loading...'}>
                      <CircularProgress size={48} />
                    </Item>
                  );
                }

                return (
                  <Item
                    key={sensorId}
                    primaryText={
                      lastReading?.rawData?.rssi ? `${sensorId} (rssi: ${lastReading?.rawData?.rssi})` : sensorId
                    }
                    secondaryText={lastReadingTimestamp?.format('MMM Do hh:mm:ss A (YYYY)')}
                    colorClass={
                      !lastReadingTimestamp
                        ? classes.gray
                        : isWithinAnHour(lastReadingTimestamp)
                        ? classes.green
                        : classes.pink
                    }
                  >
                    <ListItemSecondaryAction>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => {
                          dataLoggerStatusStore.updateStatus(sensorId);
                        }}
                        size='large'
                      >
                        {getIconBasedOnRssi(lastReading?.rawData?.rssi)}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Item>
                );
              })}
          </List>
        </div>
      </Box>
    </div>
  );
});

interface IItemProps {
  primaryText: string;
  secondaryText?: string;
  colorClass?: string;
  children: React.ReactNode;
}

const Item = ({ primaryText, secondaryText, colorClass, children }: IItemProps) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={colorClass}>
          <HighlightIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={primaryText} secondary={secondaryText ? secondaryText : null} />
      {children}
    </ListItem>
  );
};

export default NbLoggerStatuses;
