import RouterIcon from '@mui/icons-material/Router';
import { Box, Button, CircularProgress, InputAdornment } from '@mui/material';
import type { Coordinates, NumericValueEvent } from '@soilsense/shared';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import Accordion from '../../components/Accordion';
import { useGatewayInfoStore } from '../../dataHandlers/RootStore';
import type { DeviceIdentifiers } from '../../dataHandlers/utils/formatters';
import { veryLongDeviceDisplayName } from '../../dataHandlers/utils/formatters';
import formatWithFallback from '../../utils/formatWithFallback';
import getErrorMessageIntl from '../../utils/getErrorMessageIntl';
import NumberField from '../NumberField';
import { showErrorSnackBar, showSuccessSnackBar } from '../SnackBar';
import { SecondarySummaryColored, StatusCircle, getLastUpdatedColor } from './statusComponents';
import { newDeviceColorStatusColor } from './styles';

export type GatewayInformation = DeviceIdentifiers &
  Readonly<{
    coordinates?: Coordinates;
  }>;

type Props = { gateways: readonly GatewayInformation[] };

const Gateways = observer(({ gateways }: Props) => {
  const gatewayInfoStore = useGatewayInfoStore();
  const intl = useIntl();

  return (
    <>
      {gateways
        .filter((gw) => gw.nblogger !== true)
        .map((gw) => {
          // if gw is an nbsensor then its a ghost gateway for
          // sleep time and monitoring purposes
          // and the sensor object is the only source of truth
          const status = gatewayInfoStore.getInfo(gw.id);
          const timestamp = status?.lastDataTimestamp;
          return (
            <OneGateway
              key={gw.id}
              gateway={gw}
              loading={status?.isLoading}
              lastUpdated={timestamp}
              lastUpdatedError={formatWithFallback(intl, undefined, status?.error)}
            />
          );
        })}
    </>
  );
});

const OneGateway = observer(
  ({
    gateway,
    loading,
    lastUpdated,
    lastUpdatedError,
  }: {
    gateway: GatewayInformation;
    loading?: boolean;
    lastUpdated?: Moment;
    lastUpdatedError?: string;
  }) => {
    const gatewayInfoStore = useGatewayInfoStore();
    const newGateway = gatewayInfoStore.gatewayExists(gateway.id) == false;
    const intl = useIntl();

    const info = gatewayInfoStore.getInfo(gateway.id);
    const sleepTimeHint = getSleepTimeHint(info?.sleepTime.lastAcknowledgement, intl);
    const [targetSleepMinutes, setTargetSleepMinutes] = useState<number | undefined>();
    useEffect(() => {
      if (info?.sleepTime.target?.value == undefined) {
        setTargetSleepMinutes(undefined);
      } else {
        setTargetSleepMinutes(Math.floor(info.sleepTime.target.value / 60));
      }
    }, [info?.sleepTime.target?.value]);

    const [savingTargetSleepTime, setSavingTargetSleepTime] = useState(false);

    const saveTargetSleepTime = useCallback(async () => {
      if (info == undefined || targetSleepMinutes == undefined) {
        return;
      }

      try {
        setSavingTargetSleepTime(true);
        await info.setTargetSleepTime(targetSleepMinutes * 60);
        // showSuccessSnackBar('Data logger sleep time saved successfully');
        showSuccessSnackBar(intl.formatMessage({ id: 'data_logger_sleep_time_saved_successfully' }));
      } catch (err) {
        showErrorSnackBar(getErrorMessageIntl(err, intl));
      } finally {
        setSavingTargetSleepTime(false);
      }
    }, [info, targetSleepMinutes, intl]);

    return (
      <Accordion
        primarySummary={veryLongDeviceDisplayName(gateway)}
        secondarySummary={
          newGateway ? (
            // 'New gateway. Save to see status'
            intl.formatMessage({ id: 'new_gateway_save_to_see_status' })
          ) : (
            <SecondarySummaryColored
              lastUpdated={lastUpdated}
              lastUpdatedError={lastUpdatedError}
              loading={loading}
            />
          )
        }
        summaryIcon={
          <StatusCircle
            color={
              newGateway
                ? newDeviceColorStatusColor
                : !lastUpdatedError
                ? getLastUpdatedColor(lastUpdated)
                : 'grey'
            }
          >
            <RouterIcon fontSize={'large'} color='secondary' />
          </StatusCircle>
        }
        expandable
        fullWidth
      >
        <Box py='10px' width='100%' display='flex' flexDirection='column'>
          <NumberField
            // label='Data logger sleep time'
            label={intl.formatMessage({ id: 'data_logger_sleep_time' })}
            value={targetSleepMinutes}
            setValue={setTargetSleepMinutes}
            disabled={info?.isLoading || savingTargetSleepTime}
            min={1}
            max={180}
            InputProps={{
              // endAdornment: <InputAdornment position='end'> min</InputAdornment>,
              endAdornment: (
                <InputAdornment position='end'>
                  {' '}
                  <FormattedMessage id='min' defaultMessage='min' />
                </InputAdornment>
              ),
            }}
            fullWidth
            hint={sleepTimeHint}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={saveTargetSleepTime}
            disabled={targetSleepMinutes == undefined || savingTargetSleepTime}
          >
            {savingTargetSleepTime ? <CircularProgress /> : <FormattedMessage id='save' defaultMessage='Save' />}
          </Button>
        </Box>
      </Accordion>
    );
  }
);

export function getSleepTimeHint(
  lastAcknowledgement: NumericValueEvent | undefined,
  intl: IntlShape
): string | undefined {
  if (lastAcknowledgement == undefined) {
    return undefined;
  }
  const minutes = Math.floor(lastAcknowledgement.value / 60);
  const seconds = lastAcknowledgement.value % 60;
  return intl.formatMessage(
    { id: 'last_ack_value' },
    {
      seconds_string: seconds > 0 ? `${seconds} sec` : '',
      minutes_string: `${minutes} min`,
      fromNow: moment(lastAcknowledgement.timestamp).fromNow(),
    }
  );
}

export default Gateways;
