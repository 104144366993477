import HighlightIcon from '@mui/icons-material/Highlight';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import type { SelectChangeEvent } from '@mui/material';
import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import { useDataLoggerStatusStore, useFarmStore, useObservationSiteStore } from '../../dataHandlers/RootStore';
import type { IFarm } from '../../interfaces/IFarm';
import { useStyles } from './styles';

const isWithinAnHour = (date: Moment) => {
  return moment().diff(date, 'minutes') <= 60;
};

const getIconBasedOnRssi = (rssi: number | undefined) => {
  if (!rssi) return <WifiOffIcon />;

  if (rssi < -90) return <SignalWifi0BarIcon />;

  if (rssi < -70) return <SignalWifi1BarIcon />;

  if (rssi < -50) return <SignalWifi2BarIcon />;

  if (rssi < -30) return <SignalWifi3BarIcon />;

  return <SignalWifi4BarIcon />;
};

interface ISensorStatusesProps {
  selectedFarmId: string | undefined;
  setSelectedFarmId: React.Dispatch<string>;
}

const SensorStatuses = observer(({ selectedFarmId, setSelectedFarmId }: ISensorStatusesProps) => {
  const classes = useStyles();
  const observationSiteStore = useObservationSiteStore();
  const dataLoggerStatusStore = useDataLoggerStatusStore();
  const farms = useFarmStore().availableFarms;
  const label = 'Selected farm';

  const SelectFarm = ({ disabled }: { disabled?: boolean }) => {
    return (
      <FormControl variant='outlined'>
        <InputLabel id='selected-farm-label'>{label}</InputLabel>
        <Select
          disabled={disabled}
          label={label}
          labelId='selected-farm-label'
          id='selected-farm'
          value={selectedFarmId}
          onChange={(e: SelectChangeEvent<string>) => setSelectedFarmId(String(e.target.value))}
        >
          {farms.map((farm: IFarm) => (
            <MenuItem key={farm.id} value={farm.id}>
              {`${farm.id} ${farm.name ? `: ${farm.name}` : ''}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <div className={classes.root}>
      <Box display='flex' flexDirection='column' gap={1.5}>
        <Typography variant='h6' className={classes.title}>
          Sensors
        </Typography>
        <SelectFarm />
        <Typography variant='caption'>(green if communicated within last hour)</Typography>
        <div className={classes.demo}>
          {observationSiteStore.activeSiteIdentifiers.length > 0 ? (
            <List dense={true}>
              {observationSiteStore.activeSiteIdentifiers.map(({ deviceIds: { id: sensorId } }) => {
                const lastReadingTimestamp = dataLoggerStatusStore.getLastReadingTime(sensorId);
                const lastReading = dataLoggerStatusStore.getLastReading(sensorId);
                if (dataLoggerStatusStore.objectIsLoading(sensorId)) {
                  return (
                    <Item primaryText={sensorId} secondaryText={'Loading...'}>
                      <CircularProgress size={48} />
                    </Item>
                  );
                }

                return (
                  <Item
                    key={sensorId}
                    primaryText={
                      lastReading?.rawData?.rssi ? `${sensorId} (rssi: ${lastReading?.rawData?.rssi})` : sensorId
                    }
                    secondaryText={lastReadingTimestamp?.format('MMM Do hh:mm:ss A (YYYY)')}
                    colorClass={
                      !lastReadingTimestamp
                        ? classes.gray
                        : isWithinAnHour(lastReadingTimestamp)
                        ? classes.green
                        : classes.pink
                    }
                  >
                    <ListItemSecondaryAction>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => {
                          dataLoggerStatusStore.updateStatus(sensorId);
                        }}
                        size='large'
                      >
                        {getIconBasedOnRssi(lastReading?.rawData?.rssi)}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Item>
                );
              })}
            </List>
          ) : (
            'No sensors to display'
          )}
        </div>
      </Box>
    </div>
  );
});

interface IItemProps {
  primaryText: string;
  secondaryText?: string;
  colorClass?: string;
  children: React.ReactNode;
}

const Item = ({ primaryText, secondaryText, colorClass, children }: IItemProps) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={colorClass}>
          <HighlightIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={primaryText} secondary={secondaryText ? secondaryText : null} />
      {children}
    </ListItem>
  );
};

export default SensorStatuses;
