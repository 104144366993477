import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useUserStore } from 'dataHandlers/RootStore';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import * as serviceWorker from './serviceWorkerRegistration';

const ServiceWorkerWrapper: FC = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);
  const userStore = useUserStore();

  useEffect(() => {
    const onUpdate = (registration: ServiceWorkerRegistration) => {
      setShowReload(true);
      setWaitingWorker(registration.waiting);
    };

    const onRegister = (registration: ServiceWorkerRegistration) => {
      userStore.setServiceWorkerRegistration(registration, isMobile);
    };
    serviceWorker.register({ onUpdate, onRegister });
  }, [userStore]);

  const reloadPage = () => {
    if ('serviceWorker' in navigator && waitingWorker) {
      // Send the 'SKIP_WAITING' message to the waiting service worker
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });

      // Listen for the 'controllerchange' event
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // Reload the page when the new service worker takes control
        window.location.reload();
      });
    } else {
      window.location.reload();
      console.log('Service worker not found');
    }
  };

  return (
    <Dialog
      open={showReload}
      onClose={(event, reason) => (reason === 'backdropClick' ? undefined : reloadPage())}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      disableEscapeKeyDown
    >
      <DialogTitle id='alert-dialog-title'>A new version is available!</DialogTitle>
      <DialogContent>Please refresh the page to update to the latest version of the application.</DialogContent>
      <DialogActions style={{ padding: '20px' }}>
        <Button onClick={reloadPage} variant='outlined' color='primary' autoFocus fullWidth>
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceWorkerWrapper;
