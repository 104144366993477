import { Close } from '@mui/icons-material';
import type { DialogProps } from '@mui/material';
import { AppBar, Button, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import type { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import type { FC, ReactElement } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type StyleProps = Readonly<{
  minWidth?: string;
}>;

const useStyles = makeStyles<Theme, StyleProps, 'appBar' | 'title'>((theme) => ({
  appBar: ({ minWidth }) => ({
    position: 'relative',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    minWidth,
  }),
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function SlideUp(
  props: TransitionProps & { children: ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface IProps {
  title?: string;
  handleClose?: () => void;
  open?: boolean;
  onOpen?: () => void;
  handleSave?: () => void;
  saveDisabled?: boolean;
  saveLabel?: string;
}

const CustomDialog: FC<IProps & StyleProps & DialogProps> = ({
  children,
  title,
  handleClose,
  open = false,
  onOpen,
  handleSave,
  saveDisabled = false,
  saveLabel,
  minWidth,
  ...props
}) => {
  const classes = useStyles({ minWidth });

  const TopElement = () => {
    if (title || handleSave) {
      return (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close' size='large'>
              <Close />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              {title}
            </Typography>
            {handleSave && (
              <Button autoFocus color='inherit' onClick={handleSave} disabled={saveDisabled}>
                {saveLabel ?? <FormattedMessage id='save' defaultMessage='Save' />}
              </Button>
            )}
          </Toolbar>
        </AppBar>
      );
    }

    return (
      <Toolbar>
        <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close' size='large'>
          <Close />
        </IconButton>
        {title && (
          <Typography variant='h6' className={classes.title}>
            {title}
          </Typography>
        )}
        {handleSave && (
          <Button autoFocus color='inherit' onClick={handleSave} disabled={saveDisabled}>
            {saveLabel}
          </Button>
        )}
      </Toolbar>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth='lg'
      TransitionProps={{ onEnter: onOpen }}
      style={{
        backgroundColor: '',
      }}
      {...props}
    >
      <TopElement />
      {children}
    </Dialog>
  );
};

export default CustomDialog;
