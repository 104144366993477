import Icon from '@mdi/react';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Box } from '@mui/material';
import type { ChartData, ChartOptions } from 'chart.js';
import type { AnnotationOptions } from 'chartjs-plugin-annotation';
import CardBottomButton from 'components/CardBottomButton';
import moment from 'moment';
import type { Dispatch } from 'react';
import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import type { ChartCoordinates, ChartJsHandle } from './ChartJsComponent';
import ChartJsComponent from './ChartJsComponent';

const CHART_HEIGHT_PX = 300;
const CHART_HEIGHT_SMALLER_PX = 200;

export type DatasetSpec = Readonly<{
  label: string;
  data: (number | null)[];
  color: string;
  segment?: any;
}>;

export type RightAxisSpec = Readonly<{
  datasetSpecs: readonly DatasetSpec[];
  yLabel: string;
  yUnit: string;
}>;

export type SafeRangeOptions = Readonly<{
  range: readonly [number, number];
  label: string;
  backgroundColor: string;
  borderColor: string;
}>;

export type DomainBounds = readonly [number, number];

export type ChartZoom = Readonly<
  | {
      allowed: false;
      bounds: DomainBounds | undefined;
    }
  | {
      allowed: true;
      bounds: DomainBounds | undefined;
      setBounds: Dispatch<DomainBounds | undefined>;
    }
>;

export type AggregateInfo = Readonly<{
  iconPath: string;
  iconColor: string;
  title: string;
  value: number;
  unit: string;
}>;

export type TimeSeriesSpec = Readonly<{
  labels: number[];
  chartZoom: ChartZoom;
  datasetSpecs: DatasetSpec[];
  rightAxisSpec: RightAxisSpec | undefined;
  safeRangeOptions: SafeRangeOptions | undefined;
  annotations: AnnotationOptions[];
  timeUnit: 'hour' | 'day';
  aggregates: readonly AggregateInfo[];
}>;

type Props = TimeSeriesSpec &
  Readonly<{
    yLabel: string;
    yMin: number | undefined;
    yMax: number | undefined;
    yUnit: string;
    animations?: boolean;
    small?: boolean;
    flattenXAxisLabels?: boolean;
    showCrosshair?: boolean;
    onClick?: (coordinates: ChartCoordinates) => void;
    onDoubleClick?: (coordinates: ChartCoordinates) => void;
  }>;

export function TimeSeriesChart({
  labels,
  chartZoom,
  datasetSpecs,
  rightAxisSpec,
  safeRangeOptions,
  annotations,
  timeUnit,
  aggregates,
  yLabel,
  yMin,
  yMax,
  yUnit,
  animations = false,
  small = false,
  flattenXAxisLabels = false,
  showCrosshair = false,
  onClick,
  onDoubleClick,
}: Props): JSX.Element {
  const chartJsRef = useRef<ChartJsHandle | null>(null);
  const intl = useIntl();
  const data = useMemo<ChartData>(
    () => ({
      labels,
      datasets: [
        ...datasetSpecs.map((each) => ({
          label: each.label,
          data: each.data,
          segment: each.segment,
          backgroundColor: each.color,
          borderColor: each.color,
          borderWidth: 2,
        })),
        ...(safeRangeOptions == undefined
          ? [
              // inject dummy invisible safe range datasets to avoid weird
              // occasional doubling of right Y axis values when the PAW toggle
              // is clicked
              {
                label: '_',
                data: Array(labels.length).fill(undefined),
              },
              {
                label: '_',
                data: Array(labels.length).fill(undefined),
              },
            ]
          : [
              {
                label: '_',
                data: Array(labels.length).fill(safeRangeOptions.range[0]),
                backgroundColor: safeRangeOptions.backgroundColor,
                borderColor: safeRangeOptions.borderColor,
                borderWidth: 1,
              },
              {
                label: safeRangeOptions?.label,
                data: Array(labels.length).fill(safeRangeOptions.range[1]),
                backgroundColor: safeRangeOptions.backgroundColor,
                borderColor: safeRangeOptions.borderColor,
                borderWidth: 1,
                fill: '-1',
              },
            ]),
        ...(rightAxisSpec == null
          ? []
          : rightAxisSpec.datasetSpecs.map(({ label, data, color: backgroundColor }) => ({
              label,
              data,
              backgroundColor,
              borderColor: 'rgba(0, 0, 0, 0.0)',
              fill: 'origin',
              yAxisID: 'y2',
              stepped: true,
            }))),
      ],
    }),
    [rightAxisSpec, datasetSpecs, labels, safeRangeOptions]
  );

  const options = useMemo<ChartOptions>(
    () => ({
      animation: animations ? undefined : false, // note: animations are quite slow
      maintainAspectRatio: false, // the default really messes up with how responsiveness works
      normalized: true, // see https://www.chartjs.org/docs/next/api/interfaces/controllerdatasetoptions.html#normalized
      spanGaps: true,
      elements: {
        point: {
          radius: 0,
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      plugins: {
        annotation: {
          annotations,
        },
        decimation: {
          enabled: true,
          algorithm: 'min-max',
        },
        legend: {
          labels: {
            filter: (item) => !item.text.startsWith('_'),
          },
          onClick: function (_unusedEvent, _unusedLegendItem, _unusedLegend) {
            // we do not want the users to show/hide legend items because it's
            // mostly just confusing
          },
        },
        tooltip: {
          filter: (item) => !item.dataset.label?.startsWith('_'),
          yAlign: 'bottom',
          position: 'nearest',
          callbacks: {
            label: (context) => {
              const label = context.dataset.label ?? '';
              if (label === safeRangeOptions?.label) {
                return `${label}: ${safeRangeOptions?.range[0]} - ${safeRangeOptions?.range[1]} ${yUnit}`;
              } else if ((rightAxisSpec?.datasetSpecs ?? []).map((spec) => spec.label).includes(label)) {
                return `${label}: ${context.parsed.y.toFixed(2)} ${rightAxisSpec?.yUnit}`;
              } else {
                return `${label}: ${context.parsed.y.toFixed(2)} ${yUnit}`;
              }
            },
          },
        },
        zoom: chartZoom.allowed
          ? {
              zoom: {
                drag: {
                  enabled: true,
                },
                mode: 'x',
                onZoomComplete: ({ chart }) => {
                  const { min, max } = chart.getDatasetMeta(0)?.xScale ?? {};
                  chartZoom.setBounds(min != null && max != null ? [min, max] : undefined);
                },
              },
            }
          : undefined,
        crosshair: showCrosshair
          ? {
              display: true,
            }
          : undefined,
      },
      scales: {
        x: {
          ticks: {
            major: {
              enabled: true,
            },
            maxRotation: flattenXAxisLabels ? 0 : 50,
            callback: (label, index, ticks) => {
              const date = moment(ticks[index].value);
              const major = ticks[index].major;

              if (date.isValid()) {
                return intl.formatDate(date.toDate(), {
                  month: major ? 'long' : 'short',
                  day: 'numeric',
                });
              }
              return label;
            },
          },
          type: 'time',
          min: chartZoom.bounds?.[0],
          max: chartZoom.bounds?.[1],
          grid: {
            display: true,
            drawOnChartArea: false,
          },
          time: {
            unit: timeUnit,
            displayFormats: {
              millisecond: 'HH:mm',
              second: 'HH:mm',
              minute: 'HH:mm',
              hour: 'HH:mm',
              day: 'MMM Do',
              week: 'MMM Do',
              month: 'MMMM YYYY',
              quarter: 'MMMM YYYY',
              year: 'YYYY',
            },
          },
        },
        y: {
          type: 'linear',
          min: yMin,
          max: yMax,
          title: {
            display: true,
            text: `${yLabel} [${yUnit}]`,
          },
          ticks: {
            callback: function (value: string | number) {
              if (typeof value === 'number' && Number.isInteger(value) === false) {
                return `${value.toFixed(1)}`;
              } else {
                return `${value}`;
              }
            },
          },
        },
        y2: {
          type: 'linear',
          position: 'right',
          stacked: true,
          display: rightAxisSpec != null,
          grid: {
            display: false,
          },
          title: {
            display: rightAxisSpec != undefined,
            text: `${rightAxisSpec?.yLabel} [${rightAxisSpec?.yUnit}]`,
          },
          ticks: {
            callback: function (value: string | number) {
              if (typeof value === 'number' && Number.isInteger(value) === false) {
                return `${value.toFixed(1)}`;
              } else {
                return `${value}`;
              }
            },
          },
        },
      },
      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove', 'dblclick'],
      onHover:
        onClick == undefined && onDoubleClick == undefined
          ? undefined
          : (event) => {
              const callback =
                event.type == 'click' ? onClick : event.type == 'dblclick' ? onDoubleClick : undefined;
              if (callback != undefined) {
                const coordinates = chartJsRef.current?.getChartCoordinates(event);
                if (coordinates != undefined) {
                  callback(coordinates);
                }
              }
            },
    }),
    [
      animations,
      annotations,
      rightAxisSpec,
      chartZoom,
      safeRangeOptions?.label,
      safeRangeOptions?.range,
      timeUnit,
      yLabel,
      yMin,
      yMax,
      yUnit,
      flattenXAxisLabels,
      showCrosshair,
      onClick,
      onDoubleClick,
      intl,
    ]
  );

  const zoomOut = useCallback(() => {
    if (chartZoom.allowed) {
      chartZoom.setBounds(undefined);
    }
  }, [chartZoom]);

  const height = small ? CHART_HEIGHT_SMALLER_PX : CHART_HEIGHT_PX;
  return (
    <>
      <ChartJsComponent
        ref={chartJsRef}
        type='line'
        data={data}
        options={options}
        width='100%'
        height={`${height}px`}
      />
      <Box display='flex' fontSize='70%' justifyContent='center'>
        {aggregates.map(({ iconPath, iconColor, title, value, unit }, index) => (
          <Fragment key={title}>
            <Box ml={index == 0 ? '0' : '20px'} my='auto'>
              <Icon path={iconPath} title={title} size={1} color={iconColor} />
              &ensp;
            </Box>
            <Box lineHeight='40px'>
              {`${title}: `}
              {value.toFixed(1)}&thinsp;{unit}
            </Box>
          </Fragment>
        ))}
      </Box>
      {chartZoom.allowed && chartZoom.bounds && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <CardBottomButton
            onClick={zoomOut}
            icon={<ZoomOutMapIcon />}
            text={'Zoom Out'}
            style={{
              width: '200px',
            }}
          />
        </div>
      )}
    </>
  );
}
