import type { Moment } from 'moment';
import moment from 'moment';
import SunCalc from 'suncalc';
import tzLookup from 'tz-lookup';

const getSunriseSunsetBasedOnLocation = (
  latitude: number,
  longitude: number,
  approximateDate: Moment
): Readonly<{ sunrise: number; sunset: number; timezone: string }> => {
  const tzB = tzLookup(latitude, longitude);
  const timeAtB = moment.tz(approximateDate, tzB);
  const suntimesAtB_in_tzA = SunCalc.getTimes(timeAtB.toDate(), latitude, longitude);

  const sunriseAtB_in_tzB = moment.tz(suntimesAtB_in_tzA.sunrise.toISOString(), tzB);
  const sunsetAtB_in_tzB = moment.tz(suntimesAtB_in_tzA.sunset.toISOString(), tzB);

  const sunrise = sunriseAtB_in_tzB.hour();
  const sunset = sunsetAtB_in_tzB.hour();
  return { sunrise, sunset, timezone: tzB };
};

export default getSunriseSunsetBasedOnLocation;
