// SleepTimeSetter component
// This component is used to set the sleep time for the nb data logger

import { Box, Button, CircularProgress, InputAdornment } from '@mui/material';
import type { NumericDeviceSettingsItem } from '@soilsense/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchSettings, patchTargetSleepTime } from '../../dataHandlers/GatewayInfoStore';
import { useTokenProvider } from '../../dataHandlers/RootStore';
import type { FirebaseTokenProvider } from '../../interfaces/IUser';
import getErrorMessageIntl from '../../utils/getErrorMessageIntl';
import NumberField from '../NumberField';
import { showErrorSnackBar, showSuccessSnackBar } from '../SnackBar';
import { getSleepTimeHint } from './Gateways';

type Props = {
  deviceId: string;
  deviceType: 'gateway' | 'nbsensor';
};

const SleepTimeSetter = ({ deviceId, deviceType }: Props) => {
  const tokenProvider = useTokenProvider();
  // const [deviceSettings, setDeviceSettings] = useState<DeviceSettings | undefined>();
  const [sleepTime, setSleepTime] = useState<NumericDeviceSettingsItem | undefined>();
  const intl = useIntl();

  const setTargetSleepTime = useCallback(
    async (sleepTime: number) => {
      if (tokenProvider == undefined) {
        throw new Error('Cannot set sleep time: token provider is undefined');
      }

      patchTargetSleepTime(deviceId, sleepTime, tokenProvider, deviceType);
    },
    [tokenProvider, deviceId, deviceType]
  );

  useEffect(() => {
    const fetchDeviceSettings = async (tokenProvider: FirebaseTokenProvider) => {
      const settings = await fetchSettings(deviceId, tokenProvider);
      // setDeviceSettings(settings);
      setSleepTime(settings?.dataLoggerSleepSeconds);
    };

    if (tokenProvider != undefined) {
      fetchDeviceSettings(tokenProvider);
    }
  }, [tokenProvider, deviceId]);

  const sleepTimeHint = getSleepTimeHint(sleepTime?.lastAcknowledgement, intl);
  const [targetSleepMinutes, setTargetSleepMinutes] = useState<number | undefined>();
  useEffect(() => {
    if (sleepTime?.target?.value == undefined) {
      setTargetSleepMinutes(undefined);
    } else {
      setTargetSleepMinutes(Math.floor(sleepTime.target.value / 60));
    }
  }, [sleepTime?.target?.value]);

  const [savingTargetSleepTime, setSavingTargetSleepTime] = useState(false);

  const saveTargetSleepTime = useCallback(async () => {
    if (targetSleepMinutes == undefined) {
      return;
    }

    try {
      setSavingTargetSleepTime(true);
      await setTargetSleepTime(targetSleepMinutes * 60);
      // showSuccessSnackBar('Data logger sleep time saved successfully');
      showSuccessSnackBar(intl.formatMessage({ id: 'data_logger_sleep_time_saved_successfully' }));
    } catch (err) {
      showErrorSnackBar(getErrorMessageIntl(err, intl));
    } finally {
      setSavingTargetSleepTime(false);
    }
  }, [targetSleepMinutes, setTargetSleepTime, intl]);

  return (
    <Box py='10px' width='100%' display='flex' flexDirection='column'>
      <NumberField
        // label='Data logger sleep time'
        label={intl.formatMessage({ id: 'data_logger_sleep_time' })}
        value={targetSleepMinutes}
        setValue={setTargetSleepMinutes}
        // disabled={info?.isLoading || savingTargetSleepTime}
        min={1}
        max={180}
        InputProps={{
          // endAdornment: <InputAdornment position='end'> min</InputAdornment>,
          endAdornment: (
            <InputAdornment position='end'>
              {' '}
              <FormattedMessage id='min' defaultMessage='min' />
            </InputAdornment>
          ),
        }}
        fullWidth
        hint={sleepTimeHint}
      />
      <Button
        variant='contained'
        color='primary'
        onClick={saveTargetSleepTime}
        disabled={targetSleepMinutes == undefined || savingTargetSleepTime}
      >
        {/* {savingTargetSleepTime ? <CircularProgress /> : 'Save'} */}
        {savingTargetSleepTime ? <CircularProgress /> : <FormattedMessage id='save' defaultMessage='Save' />}
      </Button>
    </Box>
  );
};

export default SleepTimeSetter;
