import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { AccordionProps } from '@mui/material/Accordion';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { makeStyles, withStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  accordion: {
    outline: 'none !important',
    '-webkit-tap-highlight-color': 'transparent',
  },
  summary: {
    alignItems: 'center',
  },
  headingsContainer: { display: 'flex', flexDirection: 'column', paddingLeft: '10px' },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.text.secondary,
    lineHeight: '0.8em',
  },
}));

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)} `,
  },
}))(MuiAccordionDetails);

interface IProps extends Omit<AccordionProps, 'children'> {
  id?: string;
  primarySummary: React.ReactChild;
  secondarySummary?: React.ReactChild;
  summaryIcon?: JSX.Element;
  expandIcon?: JSX.Element;
  fullWidth?: boolean;
  expanded?: boolean;
  expandable?: boolean;
  className?: string;
  children?: React.ReactNode;
  onSummaryClick?: () => void;
}

const Accordion: React.FC<IProps> = ({
  id,
  primarySummary,
  secondarySummary,
  summaryIcon,
  expandIcon,
  fullWidth,
  children,
  className,
  expanded,
  expandable = true,
  onSummaryClick,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <MuiAccordion
      TransitionProps={{ timeout: { appear: 500, enter: 400, exit: 1000 } }}
      style={{ width: fullWidth ? '100%' : 'inherit', cursor: expandable && !expanded ? 'pointer' : 'default' }}
      expanded={expandable && expanded}
      className={className ?? classes.accordion}
      {...otherProps}
    >
      <AccordionSummary
        style={{ cursor: expandable && !expanded ? 'pointer' : 'default', padding: '10px 20px' }}
        expandIcon={expandIcon ?? <ExpandMoreIcon />}
        aria-controls={id ?? 'accordion'}
        id={id}
        className={classes.summary}
        onClick={onSummaryClick}
      >
        {summaryIcon ?? null}
        <div className={classes.headingsContainer}>
          <Typography className={classes.heading}>{primarySummary}</Typography>
          {secondarySummary && <Typography className={classes.secondaryHeading}>{secondarySummary}</Typography>}
        </div>
      </AccordionSummary>
      {children ? (
        <AccordionDetails style={{ cursor: 'default' }} onClick={(e) => e.preventDefault()}>
          {children}
        </AccordionDetails>
      ) : null}
    </MuiAccordion>
  );
};

export default Accordion;
