import type { Coordinates } from '@soilsense/shared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LOCATION_ACCURACY_MAX_WAIT_TIME_SECONDS,
  LOCATION_ACCURACY_THRESHOLD_METERS,
  LOCATION_GOOD_ACCURACY,
  LOCATION_MIN_WAIT_TIME_SECONDS,
} from './config';

const PROGRESS_UPDATE_DELAY = 100;
export const GEOLOCATION_API_NOT_AVAILABLE = 'Geolocation API not available';

type Position = Readonly<{
  location: Coordinates;
  accuracy: number;
}>;

type GeolocatorState = Readonly<
  | { status: 'inactive' }
  | {
      status: 'reading';
      bestPosition: Position | undefined;
      bestAccuracy: number | undefined;
      lastPosition: Position | undefined;
      lastAccuracy: number | undefined;
      accuracyImprovement: number[];
      progress: number;
      startTime: number;
      lastUpdateTime: number | undefined;
    }
  | ({ status: 'done' } & Position)
  | { status: 'error'; errorMessage: string }
>;

type ExtractVariant<T, K> = T extends { status: K } ? T : never;
type MeasurementProgressState = ExtractVariant<GeolocatorState, 'reading'>;

export type Geolocator = GeolocatorState &
  Readonly<{
    reset: () => void;
    restart: () => void;
  }>;

export default function useGeolocator(): Geolocator {
  const [state, setState] = useState<GeolocatorState>({ status: 'inactive' });

  const reset = useCallback(() => {
    setState({ status: 'inactive' });
  }, [setState]);

  const restart = useCallback(() => {
    setState({
      status: 'reading',
      bestPosition: undefined,
      bestAccuracy: undefined,
      lastPosition: undefined,
      lastAccuracy: undefined,
      accuracyImprovement: [],
      progress: 0,
      startTime: Date.now(),
      lastUpdateTime: undefined,
    });
  }, [setState]);

  useEffect(() => {
    if (state.status !== 'reading') {
      return;
    }

    if (!navigator.geolocation) {
      setState({
        status: 'error',
        errorMessage: GEOLOCATION_API_NOT_AVAILABLE,
      });
      return;
    }

    let unsubscribe: () => void = () => undefined;

    function updateReadingState(update: (currentState: MeasurementProgressState) => GeolocatorState) {
      setState((state) => {
        if (state.status !== 'reading') {
          return state;
        }
        return update(state);
      });
    }

    function addPosition(position: Position) {
      updateReadingState((state) => {
        const lastAccuracy = position.accuracy;
        const accuracyImprovement = [
          ...state.accuracyImprovement,
          state.lastAccuracy ? Math.max(0, state.lastAccuracy - position.accuracy) : 100,
        ];

        const firstReadingAfterPermissions = state.lastAccuracy == null && lastAccuracy;

        if (position.accuracy > 5 * LOCATION_ACCURACY_THRESHOLD_METERS) {
          // discard any potential measurement followed by an insufficiently
          // accurate one to protect against stale but accurate measurements
          // that sometimes get supplied initially on some devices (e.g. iPhone 8)
          return {
            ...state,
            lastAccuracy,
            bestAccuracy: undefined,
            bestPosition: undefined,
            accuracyImprovement,
            progress: firstReadingAfterPermissions ? 0 : state.progress,
            startTime: firstReadingAfterPermissions ? Date.now() : state.startTime,
          };
        }

        if (lastAccuracy > LOCATION_ACCURACY_THRESHOLD_METERS) {
          return {
            ...state,
            lastAccuracy,
            lastPosition: position,
            accuracyImprovement,
            progress: firstReadingAfterPermissions ? 0 : state.progress,
            startTime: firstReadingAfterPermissions ? Date.now() : state.startTime,
          };
        }

        if (state.bestPosition && state.bestPosition.accuracy < lastAccuracy) {
          return {
            ...state,
            progress: firstReadingAfterPermissions ? 0 : state.progress,
            startTime: firstReadingAfterPermissions ? Date.now() : state.startTime,
          };
        }

        return {
          ...state,
          lastAccuracy,
          lastPosition: position,
          bestAccuracy: lastAccuracy,
          bestPosition: position,
          lastUpdateTime: Date.now(),
          accuracyImprovement,
          progress: firstReadingAfterPermissions ? 0 : state.progress,
          startTime: firstReadingAfterPermissions ? Date.now() : state.startTime,
        };
      });
    }

    function setMeasurementError(errorMessage: string) {
      updateReadingState(() => {
        unsubscribe();
        return { status: 'error', errorMessage };
      });
    }

    function advanceMeasurementProgress() {
      updateReadingState((state) => {
        const elapsed = Date.now() - state.startTime;
        const newProgress = Math.min(100, (elapsed / (LOCATION_ACCURACY_MAX_WAIT_TIME_SECONDS * 1000)) * 100);

        if (newProgress < 100) {
          if (newProgress / 100 > LOCATION_MIN_WAIT_TIME_SECONDS / LOCATION_ACCURACY_MAX_WAIT_TIME_SECONDS) {
            if (state.bestPosition && state.bestAccuracy && state.bestAccuracy < LOCATION_GOOD_ACCURACY) {
              console.log('stopped because of good accuracy');
              unsubscribe();
              return { status: 'done', ...state.bestPosition };
            }

            const averageImprovementLast5 = state.accuracyImprovement.slice(-5).reduce((a, b) => a + b, 0) / 5;
            console.log('averageImprovementLast5', averageImprovementLast5, state.accuracyImprovement);
            if (state.bestPosition && state.accuracyImprovement.length > 4 && averageImprovementLast5 < 0.3) {
              console.log('stopped because of low improvement');
              unsubscribe();
              return { status: 'done', ...state.bestPosition };
            }
          }

          const averageImprovementLast10 = state.accuracyImprovement.slice(-10).reduce((a, b) => a + b, 0) / 10;
          console.log('averageImprovementLast5', averageImprovementLast10, state.accuracyImprovement);
          if (state.bestPosition && state.accuracyImprovement.length >= 10 && averageImprovementLast10 < 0.3) {
            console.log('stopped because of low improvement');
            unsubscribe();
            return { status: 'done', ...state.bestPosition };
          }

          console.log(state.lastUpdateTime, state.lastUpdateTime && Date.now() - state.lastUpdateTime);

          const SECONDS_WITHOUT_PROGRESS = 8;
          if (
            state.bestPosition &&
            state.lastUpdateTime &&
            Date.now() - state.lastUpdateTime > SECONDS_WITHOUT_PROGRESS * 1000
          ) {
            console.log(`stopped because of no improvement for ${SECONDS_WITHOUT_PROGRESS} seconds`);
            unsubscribe();
            return { status: 'done', ...state.bestPosition };
          }

          return { ...state, progress: newProgress };
        } else {
          unsubscribe();
          if (state.bestPosition == null) {
            return {
              status: 'error',
              errorMessage: 'Failed reaching desired accuracy',
            };
          } else {
            return { status: 'done', ...state.bestPosition };
          }
        }
      });
    }

    // use for testing
    // unsubscribe = mockGeolocator(addPosition, advanceMeasurementProgress, PROGRESS_UPDATE_DELAY);

    const geolocationWatchId = navigator.geolocation.watchPosition(
      (position) => {
        const location = { lat: position.coords.latitude, lng: position.coords.longitude };
        const accuracy = position.coords.accuracy;
        addPosition({ location, accuracy });
      },
      (positionError) => {
        setMeasurementError(positionError.message);
      },
      { enableHighAccuracy: true, maximumAge: 0, timeout: LOCATION_ACCURACY_MAX_WAIT_TIME_SECONDS * 1000 }
    );
    const progressIntervalId = window.setInterval(() => advanceMeasurementProgress(), PROGRESS_UPDATE_DELAY);

    unsubscribe = () => {
      navigator.geolocation.clearWatch(geolocationWatchId);
      clearInterval(progressIntervalId);
    };

    return unsubscribe;
  }, [state.status]);

  return useMemo(() => ({ reset, restart, ...state }), [reset, restart, state]);
}

function mockGeolocator(
  addPosition: (position: { location: { lat: number; lng: number }; accuracy: number }) => void,
  advanceMeasurementProgress: () => void,
  PROGRESS_UPDATE_DELAY: number
) {
  const positionsSequence = [
    { location: { lat: 52.52, lng: 13.405 }, accuracy: 100 },
    { location: { lat: 53.52, lng: 13.405 }, accuracy: 50 },
    { location: { lat: 54.52, lng: 13.405 }, accuracy: 20 },
    { location: { lat: 55.52, lng: 13.405 }, accuracy: 10 },
    { location: { lat: 56.52, lng: 13.405 }, accuracy: 5 },
    { location: { lat: 57.52, lng: 13.405 }, accuracy: 2 },
    { location: { lat: 58.52, lng: 13.405 }, accuracy: 1 },
    { location: { lat: 59.52, lng: 13.405 }, accuracy: 20 },
    { location: { lat: 60.52, lng: 13.405 }, accuracy: 1 },
    { location: { lat: 61.52, lng: 13.405 }, accuracy: 20 },
  ];
  const positionsDelayMs = 1000;
  const positionsIntervalId = window.setInterval(() => {
    if (positionsSequence.length === 0) {
      clearInterval(positionsIntervalId);
      return;
    }
    addPosition(positionsSequence.shift()!);
  }, positionsDelayMs);
  const progressIntervalId = window.setInterval(() => advanceMeasurementProgress(), PROGRESS_UPDATE_DELAY);

  return () => {
    clearInterval(positionsIntervalId);
    clearInterval(progressIntervalId);
  };
}
