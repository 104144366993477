import { TextField } from '@mui/material';
import type { Dispatch } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

export default function DeviceNameInput({
  deviceName,
  setDeviceName,
}: {
  deviceName: string;
  setDeviceName: Dispatch<string>;
}): JSX.Element {
  const intl = useIntl();
  return (
    <TextField
      label={intl.formatMessage({ id: 'device_id' })}
      variant='outlined'
      value={deviceName.charAt(0).toUpperCase() + deviceName.slice(1)}
      onChange={(e) => {
        setDeviceName(e.target.value);
      }}
      fullWidth
    />
  );
}
