const getErrorMessage = (e: any, fallbackString = 'Error occurred'): string => {
  let error: string | { status: number } | Error | undefined;
  let message: string = fallbackString;

  console.error(e);
  if (
    (typeof e.response?.data?.error === 'string' && e.response?.data?.error?.includes('token has expired')) ||
    (Object.prototype.hasOwnProperty.call(e, 'code') && e.code && e.code === 'auth/id-token-expired')
  ) {
    return 'Your session has expired. Please refresh the page to keep using the application';
  }

  if (e.message && e.message === 'Network Error') {
    return 'Failed reaching the data servers';
  }

  if (e.response?.data?.error || e.response?.data?.detail || e.response?.data?.message) {
    error = e.response?.data?.message || e.response?.data?.error || e.response?.data?.detail;
    if ((error as { status: number })?.status) {
      message = `${fallbackString} (${(error as { status: number }).status})`;
    } else if (typeof error === 'string') {
      message = error;
    } else {
      message = fallbackString;
    }
  } else if (e.message) {
    message = e.message;
  } else {
    message = fallbackString;
  }

  if (Array.isArray(message) && message[0].msg === 'field required') {
    if (message[0].loc && message[0].loc.length && message[0].loc.length >= 3) {
      if (message[0].loc[2] === 'country') {
        message = `The sensor needs a timezone set in order to perform this operation`;
      } else {
        message = `Missing field: ${message[0].loc[2]} in the request to AI Api`;
      }
    } else {
      message = message[0].msg;
    }
  }

  if (message === 'Request timeout') {
    if (e.response?.data?.object) {
      message = `Fetching the data took too long for ${e.response.data.object} -
      try selecting shorter time period or
      improving your network connection`;
    } else {
      message = `Fetching the data took too long -
      try selecting shorter time period or
      improving your network connection`;
    }
  }

  return message;
};

export default getErrorMessage;
