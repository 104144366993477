import type { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { withSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import type React from 'react';
import { useEffect } from 'react';

export enum SeverityLevel {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
}

const DEFAULT_AUTOHIDE_DURATION = 6000;

export const SNACK_BAR_TOPIC = 'SNACK_BAR_TOPIC';

type EnqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;

type Data = OptionsObject & {
  text: string;
};

const CustomizedSnackbar: React.FC<{ enqueueSnackbar: EnqueueSnackbar }> = ({ enqueueSnackbar }) => {
  useEffect(() => {
    const token = PubSub.subscribe(SNACK_BAR_TOPIC, (msg: unknown, data: Data) => {
      const { text, ...options } = data;
      enqueueSnackbar(text, options);
    });
    return () => {
      PubSub.unsubscribe(token);
    };
  }, [enqueueSnackbar]);

  return null;
};

export default withSnackbar(CustomizedSnackbar);

export function showSnackBar(
  variant: SeverityLevel,
  text: unknown,
  duration: number = DEFAULT_AUTOHIDE_DURATION
): void {
  const data: Data = {
    variant,
    text: typeof text == 'object' ? `${(text as { message?: unknown } | null)?.message}` : `${text}`,
    autoHideDuration: duration,
  };
  PubSub.publish(SNACK_BAR_TOPIC, data);
}

export const showErrorSnackBar = (text: string, duration: number = DEFAULT_AUTOHIDE_DURATION): void =>
  showSnackBar(SeverityLevel.error, text, duration);

export const showSuccessSnackBar = (text: string, duration: number = DEFAULT_AUTOHIDE_DURATION): void =>
  showSnackBar(SeverityLevel.success, text, duration);

export const showInfoSnackBar = (text: string, duration: number = DEFAULT_AUTOHIDE_DURATION): void =>
  showSnackBar(SeverityLevel.info, text, duration);
