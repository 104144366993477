import { Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import RangeDatePicker from '../../components/RangeDatePicker';
import { useFarmStore } from '../../dataHandlers/RootStore';
import type { AreaId } from '../../interfaces/Area';
import useTraceUpdate from '../../utils/useTraceUpdate';
import { AreaOverviewCard } from './AreaOverviewCard';

type Props = Readonly<{
  selectedArea: AreaId | undefined;
  setSelectedArea: React.Dispatch<AreaId>;
  forcedShowSelectionCounter: number;
  uiReactionLoading: boolean;
}>;

const OverviewControlPanel: React.FC<Props> = observer((props) => {
  useTraceUpdate(props, 'OverviewControlPanel');
  const { selectedArea, setSelectedArea, forcedShowSelectionCounter, uiReactionLoading } = props;

  const farmStore = useFarmStore();
  const screenIsLarge = useMediaQuery(useTheme().breakpoints.up('lg'));

  return (
    <div style={{ paddingTop: 0, opacity: uiReactionLoading ? 0.5 : 1, overflow: 'auto' }}>
      {
        <>
          <div style={{ paddingTop: '2px', paddingBottom: '2px' }}>
            <RangeDatePicker
              size='small'
              isPending={uiReactionLoading}
              format={screenIsLarge ? 'DD MMMM YYYY' : 'DD MMM YYYY'}
            />
          </div>
          <Divider />
        </>
      }

      {farmStore.areas.map((area) => (
        <AreaOverviewCard
          key={area.kind == 'field' ? area.fieldDetails.id : area.siteDetails.site.id}
          area={area}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          forcedShowSelectionCounter={forcedShowSelectionCounter}
        />
      ))}
    </div>
  );
});

export default OverviewControlPanel;
