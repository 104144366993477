export const toSnakeCase = (str: string) => {
  // Convert the string to lowercase
  let lowercaseStr = str.toLowerCase();

  // Replace all non-alphanumeric characters with underscores
  let snakeCaseStr = lowercaseStr.replace(/[^a-z0-9]+/g, '_');

  // Remove any leading or trailing underscores
  snakeCaseStr = snakeCaseStr.replace(/^_+|_+$/g, '');

  return snakeCaseStr;
};
