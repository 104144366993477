import React from 'react';

const useOnClickOutside = (
  ref: React.MutableRefObject<Element | null>,
  handler: (event?: { target: EventTarget | null }) => void
): void => {
  React.useEffect(() => {
    const listener = (event: { target: EventTarget | null }) => {
      if (event.target instanceof Node) {
        const dialogElements = document.querySelectorAll('[role="dialog"]');
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        for (const el of Array.from(dialogElements)) {
          if (el.contains(event.target)) {
            return;
          }
        }

        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
