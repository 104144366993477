import type {
  DeviceSettings,
  Gateway,
  GatewayStatus,
  NumericDeviceSettingsItem,
  NumericValueEvent,
} from '@soilsense/shared';
import { GATEWAY_STATUS_DECODER } from '@soilsense/shared';
import type { FirebaseTokenProvider } from 'interfaces/IUser';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import type { IPromiseBasedObservable } from 'mobx-utils';
import { fromPromise } from 'mobx-utils';
import type { Moment } from 'moment';
import moment from 'moment';
import { Err, JsonDecoder, Ok } from 'ts.data.json';
import { BASE_URL } from 'utils/consts';
import { fetchAndSet } from 'utils/fetchAndSet';
import getErrorMessage from 'utils/getErrorMessage';
import type { Base } from '../firebase/base';
import { CError, Errors } from '../utils/errors';
import type { FarmStore } from './FarmStore';
import type { UserStore } from './UserStore';

type GatewayInfo = Readonly<{
  settings: DeviceSettings | undefined;
  lastStatus: GatewayStatus | undefined;
}>;

class UpdatableGatewayInfo {
  @observable private info: IPromiseBasedObservable<GatewayInfo>;

  constructor(public readonly gateway: Gateway, private readonly userStore: UserStore) {
    makeObservable(this);
    this.info = this.fetchInfo();
  }

  @action update(): void {
    this.info = this.fetchInfo();
  }

  private fetchInfo(): IPromiseBasedObservable<GatewayInfo> {
    const { currentTokenProvider } = this.userStore;
    if (currentTokenProvider == undefined) {
      return fromPromise.reject(
        new CError('Cannot fetch sensor data: No user is logged in', {
          code: Errors.CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN,
        })
      );
    }
    return fromPromise(fetchInfo(this.gateway.id, currentTokenProvider));
  }

  @computed get isLoading(): boolean {
    return this.info.state == 'pending';
  }

  @computed get error(): string | undefined {
    return this.info.state == 'rejected' ? getErrorMessage(this.info.value) : undefined;
  }

  @computed get lastDataTimestamp(): Moment | undefined {
    if (this.info.state != 'fulfilled' || this.info.value.lastStatus == undefined) {
      return undefined;
    }
    return moment(this.info.value.lastStatus.timestamp);
  }

  @computed get sleepTime(): NumericDeviceSettingsItem {
    if (this.info.state != 'fulfilled') {
      return {};
    }
    return this.info.value.settings?.dataLoggerSleepSeconds ?? {};
  }

  async setTargetSleepTime(value: number, deviceType: 'gateway' | 'nbsensor' = 'gateway'): Promise<void> {
    const { currentTokenProvider } = this.userStore;
    if (currentTokenProvider == undefined) {
      throw new CError('Cannot fetch sensor data: No user is logged in', {
        code: Errors.CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN,
      });
    }
    const result = await patchTargetSleepTime(this.gateway.id, value, currentTokenProvider, deviceType);
    if (result.status == 'error') {
      throw new Error(result.error);
    }
    runInAction(() => {
      this.update();
    });
  }
}

export async function patchTargetSleepTime(
  deviceId: string,
  value: number,
  currentTokenProvider: FirebaseTokenProvider,
  deviceType: 'gateway' | 'nbsensor' = 'gateway'
): Promise<
  | {
      status: 'ok';
    }
  | {
      status: 'error';
      error: string;
    }
> {
  if (deviceType == 'gateway') {
    const response = await fetch(`${BASE_URL}/deviceSettings/${deviceId}/dataLoggerSleepSeconds/target`, {
      method: 'PATCH',
      headers: { Authorization: `Bearer ${await currentTokenProvider()}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({ value }),
    });

    if (response.status != 204) {
      const body = await response.json();
      return { status: 'error', error: body.error };
    }
  }

  if (deviceType == 'nbsensor') {
    const response = await fetch(`${BASE_URL}/deviceSettings/nb/${deviceId}/dataLoggerSleepSeconds/target`, {
      method: 'PATCH',
      headers: { Authorization: `Bearer ${await currentTokenProvider()}`, 'Content-Type': 'application/json' },
      body: JSON.stringify({ value }),
    });

    if (response.status != 204) {
      const body = await response.json();
      return { status: 'error', error: body.error };
    }
  }

  return { status: 'ok' };
}

async function fetchInfo(gatewayId: string, getIdToken: FirebaseTokenProvider): Promise<GatewayInfo> {
  const [settings, lastStatus] = await Promise.all([
    fetchSettings(gatewayId, getIdToken),
    fetchLastStatus(gatewayId, getIdToken),
  ]);
  return {
    settings,
    lastStatus,
  };
}

export async function fetchSettings(
  gatewayId: string,
  getIdToken: FirebaseTokenProvider
): Promise<DeviceSettings | undefined> {
  const response = await fetch(`${BASE_URL}/deviceSettings/${gatewayId}`, {
    headers: { authorization: `Bearer ${await getIdToken()}` },
  });
  if (response.status == 200) {
    return await DEVICE_SETTINGS_DECODER.decodeToPromise(await response.json());
  }
  if (response.status == 404) {
    return undefined;
  }
  throw new CError(`Device settings request failed. Response code ${response.status}`, {
    code: Errors.DEVICE_SETTINGS_REQUEST_FAILED,
    details: `Text: ${await response.text()}, Status: ${response.status}`,
  });
}

async function fetchLastStatus(
  gatewayId: string,
  getIdToken: FirebaseTokenProvider
): Promise<GatewayStatus | undefined> {
  const data = await fetchAndSet(`${BASE_URL}/gateway-statuses/last/${gatewayId}`, await getIdToken());
  if (data) {
    return await GATEWAY_STATUS_DECODER.decodeToPromise(data);
  }
  return undefined;
}

export class GatewayInfoStore {
  @observable private readonly idToInfo: Map<string, UpdatableGatewayInfo> = new Map();

  constructor(
    private readonly firebase: Base,
    private readonly userStore: UserStore,
    private readonly farmStore: FarmStore
  ) {
    makeObservable(this);
  }

  @action updateGateways(): void {
    this.idToInfo.clear();
    const { selectedFarmId } = this.farmStore;
    if (selectedFarmId != undefined) {
      this.fetchAndSetGateways(selectedFarmId);
    }
  }

  @action update(): void {
    if (this.userStore.currentUser == undefined) {
      this.idToInfo.clear();
      return;
    }

    for (const info of Array.from(this.idToInfo.values())) {
      info.update();
    }
  }

  gatewayExists(gatewayId: string): boolean {
    return this.idToInfo.get(gatewayId) != undefined;
  }

  getInfo(gatewayId: string): UpdatableGatewayInfo | undefined {
    return this.idToInfo.get(gatewayId);
  }

  @computed get gatewayInfosArray(): readonly Gateway[] {
    return Array.from(this.idToInfo.values()).map(({ gateway }) => gateway);
  }

  private async fetchAndSetGateways(farmId: string): Promise<void> {
    try {
      const gateways = await this.firebase.gateways.getGatewaysBelongingToFarm(farmId);
      runInAction(() => {
        this.idToInfo.clear();
        for (const gateway of gateways) {
          this.idToInfo.set(gateway.id, new UpdatableGatewayInfo(gateway, this.userStore));
        }
      });
    } catch (err) {
      runInAction(() => {
        this.idToInfo.clear();
      });
      console.error(err);
    }
  }
}

function createDeviceSettingsDecoder(): JsonDecoder.Decoder<DeviceSettings> {
  const { object: obj, optional: opt, number: num } = JsonDecoder;

  const dateDecoder = new JsonDecoder.Decoder<Date>((input) => {
    if (typeof input === 'string') {
      const timestamp = Date.parse(input);
      if (isNaN(timestamp)) {
        return new Err(`Invalid date: ${input}`);
      }
      return new Ok(new Date(timestamp));
    }
    return new Err(`Invalid date field type: ${typeof input}`);
  });

  const numericValueEventDecoder: JsonDecoder.Decoder<NumericValueEvent> = obj(
    { value: num, timestamp: dateDecoder },
    'NumericValueEvent'
  );

  const numericDeviceSettingsItemDecoder: JsonDecoder.Decoder<NumericDeviceSettingsItem> = JsonDecoder.object(
    {
      target: opt(numericValueEventDecoder),
      lastCommand: opt(numericValueEventDecoder),
      lastAcknowledgement: opt(numericValueEventDecoder),
    },
    'NumericDeviceSettingsItem'
  );

  return JsonDecoder.object(
    {
      deviceNumber: num,
      dataLoggerSleepSeconds: opt(numericDeviceSettingsItemDecoder),
    },
    'DeviceSettings'
  );
}

const DEVICE_SETTINGS_DECODER = createDeviceSettingsDecoder();
