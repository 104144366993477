import ReactGA from 'react-ga';

class GoogleAnalytics {
  constructor() {
    this.init();
  }

  private init = () => {
    if (process.env.NODE_ENV === 'development') {
      ReactGA.initialize('UA-158117571-2');
    } else if (process.env.NODE_ENV === 'test') {
      // intentionally empty
    } else {
      ReactGA.initialize('UA-158117571-1');
    }
  };

  public pageView = (path?: string) => {
    ReactGA.pageview(path ?? window.location.pathname + window.location.search);
  };

  public setUser = (userId: string) => {
    ReactGA.set({ userId });
  };

  public modalView = (path: string) => {
    ReactGA.modalview(path);
  };
}

const googleAnalyticsInstance = new GoogleAnalytics();

export default googleAnalyticsInstance;
