import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import type { RouteComponentProps, StaticContext } from 'react-router';
import { Redirect, withRouter } from 'react-router';
import { SoilsenseBackgroundOverlay } from '../components/BackgroundOverlayContainer';
import { LoadingScreen } from '../components/LoadingScreen';
import type { ILoginForm } from '../components/Login';
import Login from '../components/Login';
import { SeverityLevel, showErrorSnackBar, showSnackBar } from '../components/SnackBar';
import { useCurrentUser, useUserStore } from '../dataHandlers/RootStore';
import getErrorMessageIntl from '../utils/getErrorMessageIntl';

type Props = RouteComponentProps<Record<string, never>, StaticContext, { from?: string } | undefined>;

const LoginPage = observer(({ history, location }: Props) => {
  const [loading, setLoading] = useState(false);
  const userStore = useUserStore();
  const currentUser = useCurrentUser();
  const intl = useIntl();

  const getPathname = useCallback(() => {
    let pathname = '/';
    if (location.state?.from != null) {
      pathname = location.state.from;
    }
    return pathname;
  }, [location.state]);

  const handleLogin = useCallback(
    async (event: React.FormEvent<HTMLFormElement & ILoginForm>) => {
      setLoading(true);
      event.preventDefault();
      const {
        email: { value: email },
        password: { value: password },
      } = (event.target as HTMLFormElement & ILoginForm).elements;
      try {
        await userStore.signInWithEmailAndPassword(email.trim(), password);
        showSnackBar(SeverityLevel.success, intl.formatMessage({ id: 'login_success' }));
        // keep loading flag on, expecting currentUser to get set
      } catch (err) {
        showErrorSnackBar(
          intl.formatMessage(
            { id: 'login_error' },
            {
              error: getErrorMessageIntl(err, intl),
            }
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [userStore, intl]
  );

  if (currentUser === undefined) {
    return <LoadingScreen />;
  } else if (currentUser === null) {
    return (
      <SoilsenseBackgroundOverlay>
        <Login onSubmit={handleLogin} loading={loading} />
      </SoilsenseBackgroundOverlay>
    );
  } else {
    const pathname = getPathname();
    history.push(pathname);
    return <Redirect to={{ pathname, state: { from: 'login' } }} />;
  }
});

export default withRouter(LoginPage);
