import React from 'react';

const NotInProductionWrapper = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}): React.ReactElement | null => {
  const isStagingOrDev =
    window.location.hostname.includes('staging') || window.location.hostname.includes('localhost');

  if (isStagingOrDev) {
    return (
      <div
        style={{
          border: '1px dashed red',
        }}
      >
        {children}
        <div
          style={{
            position: 'relative',
            zIndex: 1000,
            backgroundColor: 'white',
            color: 'red',
            padding: '10px',
            textAlign: 'center',
            fontSize: '7px',
          }}
        >
          This element is only visible in staging and development environments
        </div>
      </div>
    );
  }

  return null;
};

export default NotInProductionWrapper;
