import BlurOnIcon from '@mui/icons-material/BlurOn';
import TuneIcon from '@mui/icons-material/Tune';
import { Box } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import type { SiteInfo } from 'dataHandlers/ObservationSiteStore';
import { longDeviceDisplayName } from 'dataHandlers/utils/formatters';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFarmStore } from '../../../dataHandlers/RootStore';
import type { ISwitchWithLabelProps } from '../../Switch';
import SwitchWithLabel from '../../Switch';

const useStyles = makeStyles((theme) => ({
  chartTitleBar: {
    display: 'flex',
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: '100%',
    padding: '5px 0 0 15px',
    marginBottom: '-8px',
    justifyContent: 'space-between',
    '&:hover $sensorSettingsIcon': {
      visibility: 'visible',
      [theme.breakpoints.down('md')]: {
        display: 'initial',
      },
    },
    '&:hover $switch': {
      visibility: 'visible',
      [theme.breakpoints.down('md')]: {
        display: 'initial',
      },
    },
  },
  sensorTitle: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '85%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    /* Hide scrollbar for IE and Edge */
    '&': {
      '-ms-overflow-style': 'none',
    },
  },
  sensorNameContainer: {
    display: 'inline-block',
    alignContent: 'center',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '2.2em',
  },
  sensorSettingsIcon: {
    visibility: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    // marginLeft: '5px',
  },
  sensorNameCaption: {
    marginTop: '-18px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-10px',
    },
  },
  switch: {
    visibility: 'visible',
    [theme.breakpoints.down('md')]: {
      display: 'initial',
    },
  },
}));

interface IProps {
  siteInfo: SiteInfo;
  fontColor: 'textPrimary' | 'textSecondary';
  isSmall?: boolean;
  switchProps?: ISwitchWithLabelProps & { visible: boolean };
  settingsVisible?: boolean;
  onSettingsClick?: (sensorId: string) => void;
  onMagicClick?: () => void;
}

const ChartTitleBar: FC<IProps> = observer(
  ({
    siteInfo,
    fontColor,
    switchProps,
    isSmall = false,
    children,
    settingsVisible = false,
    onSettingsClick,
    onMagicClick,
  }) => {
    const classes = useStyles();
    const sensorDisplayName = longDeviceDisplayName({ name: siteInfo.site.name, ...siteInfo.deviceIds });
    const farm = useFarmStore().selectedFarm;
    const pawAvailable = !switchProps?.disabled;

    const tools = (
      <Box display='flex' flexDirection='row'>
        {children}
      </Box>
    );

    return (
      <div
        className={classes.chartTitleBar}
        style={{ flexDirection: isSmall ? 'column' : 'initial', padding: 0, margin: 0 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {pawAvailable || farm?.disableAutoCalibration ? null : (
            <div
              style={{
                position: 'relative',
                top: 0,
                left: 0,
                // height: '20px',
                width: '100%',
                // backgroundColor: '#ccc',
                backgroundColor: '#FFDB59',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 7,
                lineHeight: '1em',
              }}
            >
              {/* <WarningAmberIcon
                style={{
                  marginRight: 5,
                }}
              /> */}
              <FormattedMessage id='waiting_for_heavy_irrigation' />
            </div>
          )}

          <div
            className={classes.chartTitleBar}
            style={{
              display: 'flex',
              flexDirection: isSmall ? 'column' : 'initial',
            }}
          >
            <Typography color={fontColor} className={classes.sensorTitle} variant='h6' noWrap display='inline'>
              <div className={classes.sensorNameContainer}>
                {sensorDisplayName}
                {settingsVisible && onSettingsClick && (
                  <Hidden mdDown>
                    <IconButton
                      className={classes.sensorSettingsIcon}
                      color='inherit'
                      aria-label='sensor settings'
                      onClick={() => onSettingsClick(siteInfo.deviceIds.id)}
                      size='large'
                    >
                      <TuneIcon />
                    </IconButton>
                  </Hidden>
                )}
              </div>
            </Typography>
            {switchProps && switchProps.visible && (
              <SwitchWithLabel
                checked={switchProps.checked}
                label={switchProps.label}
                onChange={switchProps.onChange}
                disabled={switchProps.disabled}
                className={classes.switch}
              />
            )}
            {onMagicClick && (
              <IconButton
                className={classes.sensorSettingsIcon}
                onClick={onMagicClick}
                aria-label='predict future'
                color='inherit'
                size='large'
              >
                <BlurOnIcon />
              </IconButton>
            )}
            <Hidden mdUp>
              <Box position='absolute' right='0' top='0'>
                {tools}
              </Box>
            </Hidden>
            <Hidden mdDown>{tools}</Hidden>
          </div>
        </div>
      </div>
    );
  }
);

export default ChartTitleBar;
