import { Button, CircularProgress, Tooltip } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';
import React from 'react';

interface IProps {
  onClick?: () => void;
  style?: React.CSSProperties;
  icon?: JSX.Element;
  loading?: boolean;
  disabled?: boolean;
  hoverText?: string;
  text: string | JSX.Element;
  className?: string;
}
const CardBottomButton = ({
  onClick,
  loading,
  disabled,
  style,
  className,
  icon,
  hoverText,
  text,
  ...props
}: IProps & ButtonProps): JSX.Element => {
  const button = (
    <Button
      className={className}
      disabled={loading || disabled}
      onClick={onClick ?? (() => undefined)}
      style={{ border: '1px solid #eee', width: '100%', padding: '10px 0', ...style }}
      {...props}
    >
      {loading && <CircularProgress size={24} />}
      {(!loading && icon) ?? null}
      &nbsp;&nbsp;{text}
    </Button>
  );

  if (hoverText) {
    return (
      <Tooltip title={hoverText} disableInteractive>
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};
export default CardBottomButton;
