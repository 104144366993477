import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import LocalFloristTwoToneIcon from '@mui/icons-material/LocalFloristTwoTone';
import { Card } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { GoogleMap } from '@react-google-maps/api';
import type { Coordinates } from '@soilsense/shared';
import { isPointInPolygon } from 'geolib';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { JsonDecoder } from 'ts.data.json';
import type { SiteDetails } from '../../dataHandlers/ObservationSiteStore';
import { useFarmStore } from '../../dataHandlers/RootStore';
import { customerVisibleDeviceId } from '../../dataHandlers/utils/formatters';
import type { AreaId } from '../../interfaces/Area';
import type { FieldWithId } from '../../interfaces/Field';
import {
  BRIGHT_NEUTRAL_COLOR,
  DARK_NEUTRAL_COLOR,
  getIrrigationStatus,
  getMarkerColorForIrrigationStatus,
  INTERACTION_COLOR,
  NEUTRAL_COLOR,
} from '../../utils/getMarkerColorFromReadings';
import type { AddFieldOverlayHandle } from './AddFieldOverlay';
import AddFieldOverlay from './AddFieldOverlay';
import { FieldShape } from './FieldShape';
import { MouseEventFriendlyMarker } from './MouseEventFriendlyMarker';

type NdviDate = Readonly<{
  date: string;
}>;

const useStyles = makeStyles(() => ({
  innerContainer: {
    borderRadius: (props: { borderRadius: number }) => props.borderRadius,
    height: '100%',
    width: '100%',
  },
  largeButton: {
    padding: 20,
  },
  largeIcon: {
    fontSize: '2.5em',
  },
}));

const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

interface IProps {
  siteDetails: readonly SiteDetails[];
  selectedArea?: AreaId;
  setSelectedArea?: React.Dispatch<AreaId | undefined>;
  handleSiteClick?: React.Dispatch<string>;
  handleFieldClick?: React.Dispatch<string>;
  borderRadius: number;
  zoomControl?: boolean;
  searchBoxVisible?: boolean;
  center: Coordinates | undefined;
  zoom: number;
  onZoomChange?: (zoom: number) => void;
  showNdviLayer?: boolean;
}

const NDVI_OPACITY = 0.7;

const MarkersMap: FC<IProps> = observer(
  ({
    siteDetails,
    selectedArea,
    setSelectedArea,
    handleSiteClick,
    handleFieldClick,
    borderRadius,
    zoomControl = false,
    center,
    zoom,
    onZoomChange,
    showNdviLayer = false,
  }) => {
    const classes = useStyles({ borderRadius });

    const [mapInstance, setMapInstance] = useState<google.maps.Map>();
    const mapOnLoad = useCallback((map: google.maps.Map) => {
      setMapInstance(map);
    }, []);

    useEffect(() => {
      if (mapInstance != undefined && center != undefined) {
        if (mapInstance.getCenter() == undefined) {
          // forcefully initialize map center because panTo() does not have any
          // effect while the map instance is not initialized
          mapInstance.setCenter(center);
        } else {
          mapInstance.panTo(center);
        }
      }
    }, [center, mapInstance]);

    const handleZoomChanged = useCallback(() => {
      const zoom = mapInstance?.getZoom();
      if (zoom !== undefined) {
        onZoomChange?.(zoom);
      }
    }, [mapInstance, onZoomChange]);

    const [ndviShown, setNdviShown] = useState(false);
    const [ndviLayer, setNdviLayer] = useState<google.maps.ImageMapType>();
    const [availableNdviDates, setAvailableNdviDates] = useState<readonly NdviDate[]>([]);
    const [selectedNdviDate, setSelectedNdviDate] = useState(0);

    useEffect(() => {
      let active = true;
      (async () => {
        if (showNdviLayer) {
          const dates = await fetchNdviDates();
          if (active) {
            setAvailableNdviDates(dates);
          }
        } else {
          setAvailableNdviDates([]);
        }
      })();
      return () => {
        active = false;
      };
    }, [showNdviLayer]);

    useEffect(() => {
      let active = true;
      if (availableNdviDates && availableNdviDates.length && selectedNdviDate != null) {
        (async () => {
          const { date } = availableNdviDates[selectedNdviDate];
          const mapid = await fetchNdviLayerId(date);
          if (active && mapid != undefined) {
            const eeMapOptions = {
              opacity: NDVI_OPACITY,
              getTileUrl: (tile: google.maps.Point, tileZoom: number) => {
                const url = [
                  'https://earthengine.googleapis.com/v1alpha',
                  mapid,
                  'tiles',
                  tileZoom,
                  tile.x,
                  tile.y,
                ].join('/');
                return url;
              },
              tileSize: new google.maps.Size(256, 256),
            };

            setNdviLayer(new google.maps.ImageMapType(eeMapOptions));
          }
        })();
      }
      return () => {
        active = false;
      };
    }, [availableNdviDates, selectedNdviDate]);

    useEffect(() => {
      if (ndviShown) {
        mapInstance?.overlayMapTypes.clear();
        if (ndviLayer != null) {
          mapInstance?.overlayMapTypes.push(ndviLayer);
        }
      }
    }, [ndviLayer, mapInstance?.overlayMapTypes, ndviShown]);

    const toggleNdvi = () => {
      const overlayMapTypes = mapInstance?.overlayMapTypes;
      if (overlayMapTypes?.getLength() === 0) {
        if (ndviLayer != null) {
          overlayMapTypes.push(ndviLayer);
        }
      } else {
        overlayMapTypes?.clear();
      }
      setNdviShown((v) => !v);
    };

    const NdviNavigation = () => {
      return (
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            right: 100,
            backgroundColor: 'white',
            color: 'black',
            height: 100,
            width: 100,
            bottom: 5,
          }}
        >
          <div style={{ textAlign: 'center', fontWeight: 900, height: 23, paddingTop: 5 }}>NDVI</div>
          <div style={{ textAlign: 'center' }}>
            <IconButton
              onClick={() => {
                setSelectedNdviDate((p) => {
                  if (p == null) {
                    return 0;
                  }
                  return Math.min((p as number) + 1, availableNdviDates.length);
                });
              }}
              size='large'
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedNdviDate((p) => {
                  if (p == null) {
                    return 0;
                  }
                  return Math.max((p as number) - 1, 0);
                });
              }}
              size='large'
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
          {selectedNdviDate != null && availableNdviDates.length && (
            <div style={{ textAlign: 'center' }}>{availableNdviDates[selectedNdviDate].date}</div>
          )}
        </Card>
      );
    };

    const farmStore = useFarmStore();
    const [fieldDraft, setFieldDraft] = useState<FieldWithId>();
    // make a copy of the field draft coordinates just to make geolib types happy
    const fieldDraftPath = useMemo(() => (fieldDraft == undefined ? [] : [...fieldDraft.path]), [fieldDraft]);
    const addFieldOverlayRef = useRef<AddFieldOverlayHandle | null>(null);

    return (
      <GoogleMap
        mapContainerClassName={classes.innerContainer}
        zoom={zoom}
        onZoomChanged={handleZoomChanged}
        options={{
          zoomControl,
          styles: mapStyles,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeId: google.maps.MapTypeId.HYBRID,
        }}
        onLoad={mapOnLoad}
        onClick={(event) => {
          const { latLng } = event;
          if (latLng != undefined) {
            const action = addFieldOverlayRef.current?.handleMapClick({
              lat: latLng.lat(),
              lng: latLng.lng(),
            });
            if (action == 'stop') {
              event.stop();
            } else {
              setSelectedArea?.(undefined);
            }
          }
        }}
      >
        {setSelectedArea != undefined && (
          <AddFieldOverlay
            ref={addFieldOverlayRef}
            fieldDraft={fieldDraft}
            setFieldDraft={setFieldDraft}
            setSelectedArea={setSelectedArea}
          />
        )}
        {farmStore.selectedFarmFields.map((field) => (
          <FieldShape
            key={field.id}
            field={field}
            center={field.center}
            strokeColor={BRIGHT_NEUTRAL_COLOR}
            fillColor={field.color}
            zoom={zoom}
            selected={selectedArea?.kind == 'field' && selectedArea.fieldId == field.id}
            onClick={() => handleFieldClick?.(field.id)}
          />
        ))}
        {siteDetails.map((details) => {
          const coordinates = toJS(details.site.coordinates);
          const color =
            fieldDraft == undefined
              ? getMarkerColorForIrrigationStatus(getIrrigationStatus(details))
              : details.fieldId == undefined && isPointInPolygon(coordinates, fieldDraftPath)
              ? INTERACTION_COLOR
              : NEUTRAL_COLOR;
          return (
            <MouseEventFriendlyMarker
              key={details.site.id}
              coordinates={coordinates}
              fill={color}
              fillOpacity={fieldDraft == undefined ? 1.0 : 0.7}
              stroke={BRIGHT_NEUTRAL_COLOR}
              strokeWidth={1}
              label={
                zoom >= 16 && (details.fieldId == undefined || zoom >= 18)
                  ? customerVisibleDeviceId(details.deviceIds)
                  : undefined
              }
              labelColor={color == INTERACTION_COLOR ? DARK_NEUTRAL_COLOR : BRIGHT_NEUTRAL_COLOR}
              highlighted={
                (selectedArea?.kind == 'site' && details.site.id == selectedArea.siteId) ||
                (selectedArea?.kind == 'field' && details.fieldId == selectedArea.fieldId)
              }
              handleClick={
                fieldDraft == undefined
                  ? () => {
                      if (details.fieldId == undefined) {
                        handleSiteClick?.(details.site.id);
                      } else {
                        handleFieldClick?.(details.fieldId);
                      }
                    }
                  : undefined
              }
            />
          );
        })}
        <div style={{ position: 'absolute', right: 5, bottom: 5, color: 'white' }}>
          {showNdviLayer && (
            <IconButton
              className={classes.largeButton}
              color='inherit'
              aria-label=''
              onClick={() => {
                toggleNdvi();
              }}
              edge='start'
              size='large'
            >
              {ndviShown ? (
                <LocalFloristIcon className={classes.largeIcon} />
              ) : (
                <LocalFloristTwoToneIcon className={classes.largeIcon} />
              )}
            </IconButton>
          )}
          {ndviShown && <NdviNavigation />}
        </div>
      </GoogleMap>
    );
  }
);

const IRRISAT_DATES_RESPONSE_DECODER = JsonDecoder.object(
  {
    items: JsonDecoder.array(
      JsonDecoder.object<NdviDate>({ date: JsonDecoder.string }, 'IrrisatDate'),
      'IrrisatDatesResponseItems'
    ),
  },
  'IrrisatDatesResponse'
);

async function fetchNdviDates(): Promise<readonly NdviDate[]> {
  const response = await fetch('https://irrisat-cloud.appspot.com/_ah/api/irrisat/v1/services/maps/dates');
  const rawData = await response.json();
  const data = await IRRISAT_DATES_RESPONSE_DECODER.decodeToPromise(rawData);
  return data.items;
}

const IRRISAT_LAYERS_RESPONSE_DECODER = JsonDecoder.object(
  {
    items: JsonDecoder.array(
      JsonDecoder.object({ mapid: JsonDecoder.string, name: JsonDecoder.string }, 'IrrisatLayer'),
      'IrrisatLayersResponseItems'
    ),
  },
  'IrrisatLayersResponse'
);

async function fetchNdviLayerId(date: string): Promise<string | undefined> {
  const layersUrl = `https://irrisat-cloud.appspot.com/_ah/api/irrisat/v1/services/maps/layers/${date}`;
  const response = await fetch(layersUrl);
  const rawData = await response.json();
  const data = await IRRISAT_LAYERS_RESPONSE_DECODER.decodeToPromise(rawData);
  const ndviLayer = data.items.find((layer) => layer.name === 'NDVI');
  return ndviLayer?.mapid;
}

export default MarkersMap;
