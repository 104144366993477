import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import type { FarmSettingsUpdateFunc } from './index';
import {
  CustomList,
  FarmName,
  InstantNotificationChannel,
  WhatsappNotifications,
  WhatsappNumbers,
} from './inputComponents';
import type { IFarmSettings } from './settingsState';

const FarmSettingsComponent: FC<{
  state: IFarmSettings;
  updateState: FarmSettingsUpdateFunc;
}> = ({ state, updateState }) => {
  const intl = useIntl();
  return (
    <>
      <CustomList
        // title='General'
        title={intl.formatMessage({ id: 'settings_general' })}
      >
        <FarmName value={state.farmName} update={updateState} />
        {/* <CountrySelector value={state.country} update={updateState} /> */}
      </CustomList>
      <CustomList
        // title='Notifications'
        title={intl.formatMessage({ id: 'settings_notifications' })}
      >
        <WhatsappNotifications value={state.enableWhatsappNotif} update={updateState} />
        {state.enableWhatsappNotif && <WhatsappNumbers value={state.phoneNumbers} update={updateState} />}
        {state.enableWhatsappNotif && (
          <InstantNotificationChannel value={state.instantNotificationChannel} update={updateState} />
        )}
      </CustomList>
    </>
  );
};

export default FarmSettingsComponent;
