import { useMediaQuery } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import { makeStyles, useTheme } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import topBarBackground from '../assets/login_background_narrow.jpg';
import logo from '../assets/soilsense.png';
import BarWithDrawer from '../components/BarWithDrawer';
import {
  BAR_HEIGHT,
  BOTTOM_NAVIGATION_HEIGHT,
  CONTROLS_DEFAULT,
  CONTROL_OPTIONS,
} from '../components/Board/config';
import DrawerContent from '../components/DrawerContent';
import Loading from '../components/Loading';
import BottomNavigationPanel from '../components/Navigation/BottomNavigationPanel';
import { TabLabels, tabLabels } from '../components/Navigation/Config';
import PersistentDrawerLeft from '../components/PersistentDrawerLeft';
import SensorData from '../components/SensorData/SensorData';
import SensorOverview from '../components/SensorData/SensorOverview';
import Spacer from '../components/Spacer';
import TopBar from '../components/TopBar';
import { useFarmStore, useObservationSiteStore } from '../dataHandlers/RootStore';
import type { AreaId } from '../interfaces/Area';
import delayRendering from '../utils/delayRendering';
import formatWithFallback from '../utils/formatWithFallback';
import googleAnalyticsInstance from '../utils/googleAnalytics';
import { useLocalStorage } from '../utils/useLocalStorage';
import useTraceUpdate from '../utils/useTraceUpdate';

const useStyles = makeStyles((theme) => ({
  boardRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: `calc(${100}% - ${BAR_HEIGHT}px)`,
    marginTop: BAR_HEIGHT,
    [theme.breakpoints.down('md')]: {
      height: `calc(${100}% - ${BAR_HEIGHT + BOTTOM_NAVIGATION_HEIGHT}px)`,
    },
  },
  confirmButton: {
    width: '50%',
  },
  confirmButtonContainer: {
    width: '100%',
    textAlign: 'end',
    marginBottom: 10,
  },
}));

const DEFAULT_TAB = TabLabels.Overview;

const BoardPage: FC = observer((props) => {
  useTraceUpdate(props, 'Board');

  const classes = useStyles();
  const intl = useIntl();

  const [forceUiReactionLoading, setForceUiReactionLoading] = useState(false);
  const [selectedTabString, setSelectedTab] = useLocalStorage('SoilSense.selectedTab', DEFAULT_TAB.toString());
  const selectedTab = Number(selectedTabString);
  const [selectedArea, setSelectedArea] = React.useState<AreaId>();

  const farmStore = useFarmStore();
  const observationSiteStore = useObservationSiteStore();
  const { availableFarms, selectedFarm } = farmStore;

  const uiReactionLoading = forceUiReactionLoading || farmStore.isLoading;

  // FIXME move state into stores
  const [controls, setControls] = useState(CONTROLS_DEFAULT);
  React.useEffect(() => {
    observationSiteStore.setShowArchivedSites(controls[CONTROL_OPTIONS.ArchivedLocations]);
  }, [controls, observationSiteStore]);

  const controlsOnChange = (key: CONTROL_OPTIONS) => {
    delayRendering(() => {
      setControls({
        ...controls,
        [key]: !controls[key],
      });
    }, setForceUiReactionLoading);
  };

  const handleTabChange = (newValue: number | string) => {
    if (newValue === selectedTab) {
      return;
    }

    delayRendering(() => {
      if (typeof newValue === 'string') {
        setSelectedTab(tabLabels.indexOf(newValue).toString());
        return;
      }
      setSelectedTab(newValue.toString());
    }, setForceUiReactionLoading);
  };

  useEffect(() => {
    if (selectedTab != null) {
      googleAnalyticsInstance.pageView(`/${tabLabels[selectedTab]}`);
    }
  }, [selectedTab]);

  const updateAvailableFarms = useCallback(() => {
    farmStore.updateAvailableFarms();
  }, [farmStore]);

  const setSelectedFarmId = useCallback(
    (id: string) => {
      farmStore.setSelectedFarmId(id);
    },
    [farmStore]
  );

  const errorMessage =
    farmStore.errorMessage ??
    (farmStore.isLoading == false && observationSiteStore.activeSiteIdentifiers.length == 0)
      ? intl.formatMessage({
          id: 'no_sensors_assigned',
        })
      : undefined;

  const [forcedShowSelectionCounter, setForcedShowSelectionCounter] = useState(0);

  const drawer = (
    <DrawerContent
      selectedTab={selectedTab}
      handleTabChange={handleTabChange}
      tabLabels={tabLabels}
      selectedArea={selectedArea}
      setSelectedArea={setSelectedArea}
      forcedShowSelectionCounter={forcedShowSelectionCounter}
      uiReactionLoading={uiReactionLoading}
      controls={controls}
      controlsOnChange={controlsOnChange}
      disabled={Boolean(errorMessage)}
    />
  );

  return (
    <div className={classes.boardRoot}>
      <TopBar
        backgroundImage={topBarBackground}
        farmName={selectedFarm?.name}
        farms={availableFarms}
        updateAvailableFarms={updateAvailableFarms}
        selectedFarm={selectedFarm}
        setSelectedFarmId={setSelectedFarmId}
        titleImage={logo}
        topSpacing={0}
        zIndex={5}
        titleSize={useMediaQuery(useTheme().breakpoints.down('md')) ? 'h6' : 'h5'}
      />
      <Hidden mdUp>
        {selectedTab == TabLabels.Charts && (
          <BarWithDrawer
            id={0}
            topSpacing={BAR_HEIGHT}
            zIndex={4}
            titleElement={<FormattedMessage id='controls' />}
            canOpen={true}
            appBarClassName='appBarWhite'
            drawerContent={drawer}
          />
        )}
      </Hidden>
      <div style={{ display: 'flex', height: '100%', width: '100%' }}>
        <PersistentDrawerLeft id={0} open drawerContent={drawer}>
          <Hidden mdUp>
            {selectedTab == TabLabels.Charts && ( // spacer needed when the Controls menu bar is visible
              <Spacer height={BAR_HEIGHT} />
            )}
          </Hidden>
          {uiReactionLoading && <Loading showAsTopBar />}
          {errorMessage ? (
            <h2 style={{ color: '#5d5d5d', textAlign: 'center', paddingTop: '10px' }}>
              {formatWithFallback(intl, undefined, errorMessage)}
            </h2>
          ) : (
            selectedFarm && (
              <>
                <SensorData
                  hidden={selectedTab !== TabLabels.Charts}
                  loading={uiReactionLoading}
                  controls={controls}
                  country={selectedFarm.country}
                />
                <SensorOverview
                  hidden={selectedTab !== TabLabels.Overview}
                  controls={controls}
                  loading={uiReactionLoading}
                  selectedFarm={selectedFarm}
                  selectedArea={selectedArea}
                  setSelectedArea={setSelectedArea}
                  forceShowSelection={() => setForcedShowSelectionCounter((value) => value + 1)}
                  overviewCards={drawer}
                />
              </>
            )
          )}
        </PersistentDrawerLeft>
      </div>
      <Hidden mdUp>
        <BottomNavigationPanel
          disabled={uiReactionLoading}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      </Hidden>
    </div>
  );
});

export default BoardPage;
