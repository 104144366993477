import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { observer } from 'mobx-react-lite';
import { SnackbarProvider } from 'notistack';
import React, { createRef } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import Routing from './Routing';
import ErrorBoundary from './components/ErrorBoundary';
import CustomizedSnackbar, { showInfoSnackBar } from './components/SnackBar';
import { useUserStore } from './dataHandlers/RootStore';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';

// deploy
const App: React.FC = observer(() => {
  const userStore = useUserStore();
  const locale = userStore.locale;
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: string | number | undefined) => () => {
    if (notistackRef && notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  // display firebase notifications as a snackbar when the app
  // is on instead of sending a push notification
  React.useEffect(() => {
    return userStore.onMessage((payload) => {
      const message = payload.data?.text.split('Go to')[0];
      if (message != undefined) {
        showInfoSnackBar(message);
      }
    });
  }, [userStore]);

  return (
    <ErrorBoundary>
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.ENGLISH}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <IconButton
                color='inherit'
                aria-label='dismiss pop up'
                onClick={onClickDismiss(key)}
                edge='start'
                size='large'
              >
                <CloseIcon />
              </IconButton>
            )}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            dense={false}
            preventDuplicate={true}
          >
            <CustomizedSnackbar />
            <BrowserRouter>
              <Routing />
            </BrowserRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </IntlProvider>
    </ErrorBoundary>
  );
});

export default App;
