export const saveToLocalStorage = (key: string, value: any) => {
  const valueToSave = typeof value === 'string' ? value : JSON.stringify(value);

  localStorage.setItem(key, valueToSave);
};

export const loadFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

export const getFromLocalStorageAndParse = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : undefined;
};
