import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { useFarmStore, useObservationSiteStore } from '../../dataHandlers/RootStore';
import type { IMinMax } from '../../dataHandlers/SensorTransformer';
import useTraceUpdate from '../../utils/useTraceUpdate';
import type { IControls } from '../Board/config';
import { CONTROL_OPTIONS } from '../Board/config';
import { AreaDataCard } from './AreaDataCard';
import { CalibrationHistoryDialog } from './CalibrationHistoryDialog';
import FcCalibrationDialog from './FcCalibrationDialog';
import { SensorDataCard } from './SensorDataCard';

const useStyles = makeStyles(() => ({
  wrapper: {
    opacity: (props: StyleProps) => (props.loading ? 0.2 : 1.0),
    display: (props: StyleProps) => (props.hidden ? 'none' : 'flex'),
    flexDirection: 'column',
    padding: 15,
    gap: 20,

    // mobile
    '@media (max-width: 700px)': {
      padding: 0,
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
}));

type StyleProps = Readonly<{
  loading: boolean;
  hidden: boolean;
}>;

type Props = StyleProps &
  Readonly<{
    startDate?: Moment;
    endDate?: Moment;
    controls: IControls;
    country: string | undefined;
  }>;

const SensorData: FC<Props> = observer((props) => {
  const { hidden, controls, loading, country } = props;
  useTraceUpdate(props, 'SensorData');

  const classes = useStyles({ loading, hidden });
  const farmStore = useFarmStore();
  const observationSiteStore = useObservationSiteStore();

  // FIXME use siteId instead
  const [fcModalSensorId, setFcModalSensorId] = useState('');
  const fcModalSiteDetails = observationSiteStore.activeSiteDetails.find(
    (siteDetails) => siteDetails.deviceIds.id == fcModalSensorId
  );

  const openDialog = useCallback((sensorId: string) => setFcModalSensorId(sensorId), [setFcModalSensorId]);

  const minMax = observationSiteStore.overallScale;
  const scaleLoading = controls[CONTROL_OPTIONS.AutoScale] == false && minMax == undefined;

  const showHistoricalCalibrations = farmStore.selectedFarm?.showHistoricalCalibrations ?? true;

  return (
    <div className={classes.wrapper}>
      <AllCharts controls={controls} openDialog={openDialog} minMax={minMax} scaleLoading={scaleLoading} />
      {observationSiteStore.showArchivedSites
        ? observationSiteStore.archivedSiteDetails.map((siteDetails) => {
            return (
              <SensorDataCard
                key={siteDetails.site.id}
                tag={siteDetails.site.id}
                siteDetails={siteDetails}
                scaleLoading={scaleLoading}
                scale={undefined}
                displaySalinity={controls[CONTROL_OPTIONS.Salinity]}
                displayTemperature={controls[CONTROL_OPTIONS.Temperature]}
                displayAirTemperature={controls[CONTROL_OPTIONS.BoxTemperature]}
                displayPrecipitation={controls[CONTROL_OPTIONS.Precipitation]}
                openDialog={undefined}
              />
            );
          })
        : null}
      {fcModalSiteDetails?.transformed.status == 'ready' ? (
        showHistoricalCalibrations ? (
          <CalibrationHistoryDialog
            siteDetails={fcModalSiteDetails}
            sensorData={fcModalSiteDetails.transformed.value}
            handleClose={() => setFcModalSensorId('')}
          />
        ) : (
          <FcCalibrationDialog
            siteDetails={fcModalSiteDetails}
            close={() => setFcModalSensorId('')}
            country={country}
          >
            <SensorDataCard
              tag='dialog-chart'
              siteDetails={fcModalSiteDetails}
              scaleLoading={scaleLoading}
              scale={controls[CONTROL_OPTIONS.AutoScale] ? undefined : minMax}
              displaySalinity={false}
              displayTemperature={false}
              displayAirTemperature={false}
              displayPrecipitation={false}
              openDialog={undefined}
            />
          </FcCalibrationDialog>
        )
      ) : null}
    </div>
  );
});

type AllChartsProps = Readonly<{
  controls: IControls;
  openDialog: (sensorId: string) => void;
  minMax: IMinMax | undefined;
  scaleLoading: boolean;
}>;

const AllCharts = observer((props: AllChartsProps): JSX.Element => {
  useTraceUpdate(props, 'AllCharts');
  const { controls, openDialog, minMax, scaleLoading } = props;
  const farmStore = useFarmStore();

  return (
    <>
      {farmStore.areas.map((area) => (
        <AreaDataCard
          key={area.kind == 'field' ? area.fieldDetails.id : area.siteDetails.site.id}
          area={area}
          scaleLoading={scaleLoading}
          scale={controls[CONTROL_OPTIONS.AutoScale] ? undefined : minMax}
          displaySalinity={controls[CONTROL_OPTIONS.Salinity]}
          displayTemperature={controls[CONTROL_OPTIONS.Temperature]}
          displayAirTemperature={controls[CONTROL_OPTIONS.BoxTemperature]}
          displayPrecipitation={controls[CONTROL_OPTIONS.Precipitation]}
          openDialog={openDialog}
        />
      ))}
    </>
  );
});

export default SensorData;
