import type { Theme } from '@mui/material/styles';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { getFromLocalStorageAndParse } from './dataHandlers/utils/localStorage';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

import * as Sentry from '@sentry/react';

const isProduction = process.env.NODE_ENV === 'production' && window.location.hostname.includes('app');
const isStaging = process.env.NODE_ENV === 'production' && window.location.hostname.includes('staging');

Sentry.init({
  dsn: 'https://dc307b972a1e36bd0822d501ce80dcaa@o4507643884863488.ingest.de.sentry.io/4507643938209872',
  environment: isProduction ? 'production' : isStaging ? 'staging' : 'development',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.2, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api.app.soilsense\.io/,
    /^https:\/\/api.staging.soilsense\.io/,
  ],
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

// in production (app.soilsense.io) hide console messages from users
// unless debug mode
if (process.env.NODE_ENV === 'production' && window.location.hostname.includes('app')) {
  if (!getFromLocalStorageAndParse('debug_mode')) {
    console.error = () => undefined;
    console.log = () => undefined;
    console.time = () => undefined;
    console.timeEnd = () => undefined;
    console.warn = () => undefined;
  }
}

console.log('Firebase project used:', process.env.REACT_APP_FIREBASE_PROJECT_ID);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <App />
      <ServiceWorkerWrapper />
    </MuiThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('app')
);

reportWebVitals();
