export const cropTypeOptions = [
  {
    label: 'Other (crop not on the list)',
    id: 'Other (crop not on the list)',
    low: 50,
    high: 100,
  },
  {
    label: 'Broccoli',
    id: 'Broccoli',
    low: 55,
    high: 100,
  },
  {
    label: 'Brussel Sprouts',
    id: 'Brussel Sprouts',
    low: 55,
    high: 100,
  },
  {
    label: 'Cabbage',
    id: 'Cabbage',
    low: 55,
    high: 100,
  },
  {
    label: 'Carrots',
    id: 'Carrots',
    low: 65,
    high: 100,
  },
  {
    label: 'Cauliflower',
    id: 'Cauliflower',
    low: 55,
    high: 100,
  },
  {
    label: 'Celery',
    id: 'Celery',
    low: 80,
    high: 100,
  },
  {
    label: 'Garlic',
    id: 'Garlic',
    low: 70,
    high: 100,
  },
  {
    label: 'Lettuce',
    id: 'Lettuce',
    low: 70,
    high: 100,
  },
  {
    label: 'Onions',
    id: 'Onions',
    low: 70,
    high: 100,
  },
  {
    label: 'Spinach',
    id: 'Spinach',
    low: 80,
    high: 100,
  },
  {
    label: 'Radishes',
    id: 'Radishes',
    low: 70,
    high: 100,
  },
  {
    label: 'Egg Plant',
    id: 'Egg Plant',
    low: 55,
    high: 100,
  },
  {
    label: 'Sweet Peppers (bell)',
    id: 'Sweet Peppers (bell)',
    low: 70,
    high: 100,
  },
  {
    label: 'Tomato',
    id: 'Tomato',
    low: 60,
    high: 100,
  },
  {
    label: 'Cucumber',
    id: 'Cucumber',
    low: 50,
    high: 100,
  },
  {
    label: 'Pumpkin',
    id: 'Pumpkin',
    low: 65,
    high: 100,
  },
  {
    label: 'Squash, Zucchini',
    id: 'Squash, Zucchini',
    low: 50,
    high: 100,
  },
  {
    label: 'Sweet Melons',
    id: 'Sweet Melons',
    low: 60,
    high: 100,
  },
  {
    label: 'Watermelon',
    id: 'Watermelon',
    low: 60,
    high: 100,
  },
  {
    label: 'Beets, table',
    id: 'Beets, table',
    low: 50,
    high: 100,
  },
  {
    label: 'Cassava',
    id: 'Cassava',
    low: 65,
    high: 100,
  },
  {
    label: 'Parsnip',
    id: 'Parsnip',
    low: 60,
    high: 100,
  },
  {
    label: 'Potato',
    id: 'Potato',
    low: 65,
    high: 100,
  },
  {
    label: 'Sweet Potato',
    id: 'Sweet Potato',
    low: 35,
    high: 100,
  },
  {
    label: 'Turnip (and Rutabaga)',
    id: 'Turnip (and Rutabaga)',
    low: 50,
    high: 100,
  },
  {
    label: 'Sugar Beet',
    id: 'Sugar Beet',
    low: 45,
    high: 100,
  },
  {
    label: 'Beans',
    id: 'Beans',
    low: 55,
    high: 100,
  },
  {
    label: 'Chickpea',
    id: 'Chickpea',
    low: 50,
    high: 100,
  },
  {
    label: 'Fababean (broad bean)',
    id: 'Fababean (broad bean)',
    low: 55,
    high: 100,
  },
  {
    label: 'Green Gram and Cowpeas',
    id: 'Green Gram and Cowpeas',
    low: 55,
    high: 100,
  },
  {
    label: 'Groundnut (Peanut)',
    id: 'Groundnut (Peanut)',
    low: 50,
    high: 100,
  },
  {
    label: 'Lentils',
    id: 'Lentils',
    low: 50,
    high: 100,
  },
  {
    label: 'Peas',
    id: 'Peas',
    low: 65,
    high: 100,
  },
  {
    label: 'Soybeans',
    id: 'Soybeans',
    low: 50,
    high: 100,
  },
  {
    label: 'Artichokes',
    id: 'Artichokes',
    low: 55,
    high: 100,
  },
  {
    label: 'Asparagus',
    id: 'Asparagus',
    low: 55,
    high: 100,
  },
  {
    label: 'Mint',
    id: 'Mint',
    low: 60,
    high: 100,
  },
  {
    label: 'Strawberries',
    id: 'Strawberries',
    low: 80,
    high: 100,
  },
  {
    label: 'Cotton',
    id: 'Cotton',
    low: 35,
    high: 100,
  },
  {
    label: 'Flax',
    id: 'Flax',
    low: 50,
    high: 100,
  },
  {
    label: 'Sisal',
    id: 'Sisal',
    low: 20,
    high: 100,
  },
  {
    label: 'Castorbean (Ricinus)',
    id: 'Castorbean (Ricinus)',
    low: 50,
    high: 100,
  },
  {
    label: 'Rapeseed, Canola',
    id: 'Rapeseed, Canola',
    low: 40,
    high: 100,
  },
  {
    label: 'Safflower',
    id: 'Safflower',
    low: 40,
    high: 100,
  },
  {
    label: 'Sesame',
    id: 'Sesame',
    low: 40,
    high: 100,
  },
  {
    label: 'Sunflower',
    id: 'Sunflower',
    low: 55,
    high: 100,
  },
  {
    label: 'Barley',
    id: 'Barley',
    low: 45,
    high: 100,
  },
  {
    label: 'Oats',
    id: 'Oats',
    low: 45,
    high: 100,
  },
  {
    label: 'Spring Wheat',
    id: 'Spring Wheat',
    low: 45,
    high: 100,
  },
  {
    label: 'Winter Wheat',
    id: 'Winter Wheat',
    low: 45,
    high: 100,
  },
  {
    label: 'Maize, Field (grain) (field corn)',
    id: 'Maize, Field (grain) (field corn)',
    low: 45,
    high: 100,
  },
  {
    label: 'Maize, Sweet (sweet corn)',
    id: 'Maize, Sweet (sweet corn)',
    low: 50,
    high: 100,
  },
  {
    label: 'Millet',
    id: 'Millet',
    low: 45,
    high: 100,
  },
  {
    label: 'Sorghum',
    id: 'Sorghum',
    low: 50,
    high: 100,
  },
  {
    label: 'Rice',
    id: 'Rice',
    low: 80,
    high: 100,
  },
  {
    label: 'Alfalfa',
    id: 'Alfalfa',
    low: 45,
    high: 100,
  },
  {
    label: 'Bermuda',
    id: 'Bermuda',
    low: 45,
    high: 100,
  },
  {
    label: 'Clover hay, Berseem',
    id: 'Clover hay, Berseem',
    low: 50,
    high: 100,
  },
  {
    label: 'Rye Grass hay',
    id: 'Rye Grass hay',
    low: 40,
    high: 100,
  },
  {
    label: 'Grazing Pasture',
    id: 'Grazing Pasture',
    low: 40,
    high: 100,
  },
  {
    label: 'Turf grass',
    id: 'Turf grass',
    low: 55,
    high: 100,
  },
  {
    label: 'Sugar Cane',
    id: 'Sugar Cane',
    low: 35,
    high: 100,
  },
  {
    label: 'Banana',
    id: 'Banana',
    low: 65,
    high: 100,
  },
  {
    label: 'Cacao',
    id: 'Cacao',
    low: 70,
    high: 100,
  },
  {
    label: 'Coffee',
    id: 'Coffee',
    low: 60,
    high: 100,
  },
  {
    label: 'Date Palms',
    id: 'Date Palms',
    low: 50,
    high: 100,
  },
  {
    label: 'Palm Trees',
    id: 'Palm Trees',
    low: 35,
    high: 100,
  },
  {
    label: 'Pineapple',
    id: 'Pineapple',
    low: 50,
    high: 100,
  },
  {
    label: 'Rubber Trees',
    id: 'Rubber Trees',
    low: 60,
    high: 100,
  },
  {
    label: 'Tea (non-shaded)',
    id: 'Tea (non-shaded)',
    low: 60,
    high: 100,
  },
  {
    label: 'Tea (shaded)',
    id: 'Tea (shaded)',
    low: 55,
    high: 100,
  },
  {
    label: 'Berries (bushes)',
    id: 'Berries (bushes)',
    low: 50,
    high: 100,
  },
  {
    label: 'Grapes (table or raisin)',
    id: 'Grapes (table or raisin)',
    low: 65,
    high: 100,
  },
  {
    label: 'Grapes (Wine)',
    id: 'Grapes (Wine)',
    low: 55,
    high: 100,
  },
  {
    label: 'Hops',
    id: 'Hops',
    low: 50,
    high: 100,
  },
  {
    label: 'Almonds',
    id: 'Almonds',
    low: 60,
    high: 100,
  },
  {
    label: 'Apples',
    id: 'Apples',
    low: 50,
    high: 100,
  },
  {
    label: 'Cherries',
    id: 'Cherries',
    low: 50,
    high: 100,
  },
  {
    label: 'Pears',
    id: 'Pears',
    low: 50,
    high: 100,
  },
  {
    label: 'Peaches',
    id: 'Peaches',
    low: 50,
    high: 100,
  },
  {
    label: 'Apricots',
    id: 'Apricots',
    low: 50,
    high: 100,
  },
  {
    label: 'Genera Stone Fruit',
    id: 'Genera Stone Fruit',
    low: 50,
    high: 100,
  },
  {
    label: 'Avocado',
    id: 'Avocado',
    low: 30,
    high: 100,
  },
  {
    label: 'Citrus',
    id: 'Citrus',
    low: 50,
    high: 100,
  },
  {
    label: 'Conifer Trees',
    id: 'Conifer Trees',
    low: 30,
    high: 100,
  },
  {
    label: 'Kiwi',
    id: 'Kiwi',
    low: 65,
    high: 100,
  },
  {
    label: 'Olives',
    id: 'Olives',
    low: 35,
    high: 100,
  },
  {
    label: 'Pistachios',
    id: 'Pistachios',
    low: 60,
    high: 100,
  },
  {
    label: 'Walnuts',
    id: 'Walnuts',
    low: 50,
    high: 100,
  },
];
