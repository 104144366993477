import { Box, Button, InputAdornment, LinearProgress, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Coordinates } from '@soilsense/shared';
import type { Dispatch } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import NumberField from '../NumberField';
import { showErrorSnackBar } from '../SnackBar';
import { LOCATION_ACCURACY_THRESHOLD_METERS } from './config';
import type { Geolocator } from './useGeolocator';
import { GEOLOCATION_API_NOT_AVAILABLE } from './useGeolocator';

const useStyles = makeStyles({
  geolocatorProgress: {
    borderRadius: 3,
  },
});

type Props = Readonly<{
  coordinates: Partial<Coordinates>;
  setCoordinates: Dispatch<Partial<Coordinates>>;
  geolocator: Geolocator;
}>;

export default function CoordinatesInput({ coordinates, setCoordinates, geolocator }: Props): JSX.Element {
  const classes = useStyles();
  const intl = useIntl();
  const locationError = geolocator.status === 'error' ? geolocator.errorMessage : undefined;
  React.useEffect(() => {
    if (geolocator.status === 'done') {
      setCoordinates(geolocator.location);
      geolocator.reset();
    }
  }, [geolocator, setCoordinates]);

  React.useEffect(() => {
    if (locationError) {
      showErrorSnackBar(locationError);
    }
  }, [geolocator, locationError]);

  const accuracy =
    geolocator.status === 'reading'
      ? geolocator.bestAccuracy
      : geolocator.status === 'done'
      ? geolocator.accuracy
      : undefined;

  let position;
  if (geolocator.status === 'done') {
    position = geolocator.location;
  }
  if (geolocator.status === 'reading') {
    position = geolocator.bestPosition?.location;
  }

  return (
    <Box display='flex' flexDirection='column' gap={1.5}>
      {locationError && (
        <Typography color='error' variant='caption' marginBottom={-1}>
          {locationError}. <Link onClick={geolocator.restart}>Try again.</Link>
        </Typography>
      )}
      <Box display='flex' flexDirection='column' gap={0.5}>
        <ReadLocationButtons
          geolocator={geolocator}
          resetLocation={() => setCoordinates({})}
          setCoordinates={setCoordinates}
        />
        {geolocator.status === 'reading' && (
          <LinearProgress
            className={classes.geolocatorProgress}
            variant='determinate'
            value={geolocator.progress}
            color={!accuracy ? 'secondary' : accuracy > LOCATION_ACCURACY_THRESHOLD_METERS ? 'error' : 'primary'}
          />
        )}
        {geolocator.status === 'reading' && accuracy == null && (
          <Typography variant='caption'>{intl.formatMessage({ id: 'warming_up_the_gps' })}</Typography>
        )}
        {accuracy != null && (
          <span style={{ color: accuracy && accuracy > LOCATION_ACCURACY_THRESHOLD_METERS ? 'red' : 'green' }}>
            {intl.formatMessage({ id: 'accuracy' })}:{' '}
            <span style={{ fontWeight: 'bold' }}>{accuracy.toFixed(1)}&thinsp;m</span>
          </span>
        )}
        {position && (
          <Typography
            variant='caption'
            marginTop='-8px'
            style={{ color: accuracy && accuracy > LOCATION_ACCURACY_THRESHOLD_METERS ? 'red' : 'green' }}
          >
            {intl.formatMessage({ id: 'coordinates' })}: {position.lat.toFixed(7)}°, {position.lng.toFixed(7)}°
          </Typography>
        )}
      </Box>
      {!coordinates.lat || !coordinates.lng ? (
        <Typography variant='subtitle2'>
          ...{intl.formatMessage({ id: 'or_type_manually' }).toLowerCase()}:
        </Typography>
      ) : null}
      <NumberField
        label={intl.formatMessage({ id: 'latitude' })}
        value={coordinates?.lat ? Math.round(coordinates.lat * 1e7) / 1e7 : undefined}
        setValue={(value) => {
          geolocator.reset();
          setCoordinates({ ...coordinates, lat: value });
        }}
        min={-90}
        max={90}
        disabled={geolocator.status == 'reading'}
        InputProps={{
          endAdornment: <InputAdornment position='end'> °</InputAdornment>,
        }}
        hint={intl.formatMessage({ id: 'geographic_latitude_hint' })}
        fullWidth
      />
      <NumberField
        label={intl.formatMessage({ id: 'longitude' })}
        value={coordinates?.lng ? Math.round(coordinates.lng * 1e7) / 1e7 : undefined}
        setValue={(value) => {
          geolocator.reset();
          setCoordinates({ ...coordinates, lng: value });
        }}
        min={-180}
        max={180}
        disabled={geolocator.status == 'reading'}
        InputProps={{
          endAdornment: <InputAdornment position='end'> °</InputAdornment>,
        }}
        hint={intl.formatMessage({ id: 'geographic_longitude_hint' })}
        fullWidth
      />
    </Box>
  );
}

function ReadLocationButtons({
  geolocator,
  resetLocation,
  setCoordinates,
}: {
  geolocator: Geolocator;
  resetLocation: () => void;
  setCoordinates: Dispatch<Partial<Coordinates>>;
}) {
  function restart() {
    resetLocation();
    geolocator.restart();
  }

  function acceptLocation() {
    // setCoordinates(geolocator.location);
    // geolocator.reset();
    if (geolocator.status === 'reading') {
      setCoordinates(geolocator.lastPosition?.location ?? {});
      geolocator.reset();
    }

    if (geolocator.status === 'done') {
      setCoordinates(geolocator.location);
      geolocator.reset();
    }
  }

  const intl = useIntl();
  const geolocationDenied =
    geolocator.status === 'error' && geolocator.errorMessage === GEOLOCATION_API_NOT_AVAILABLE;
  const cancelClick = geolocator.reset;
  // const acceptClick = acceptLocation;
  const startClick = restart;

  return (
    <Box display='flex' gap={1} width={'100%'} paddingBottom={1}>
      {geolocator.status === 'reading' ? (
        <Button variant='contained' color='primary' onClick={cancelClick} fullWidth>
          {intl.formatMessage({ id: 'cancel' })}
        </Button>
      ) : (
        <Button variant='contained' color='primary' onClick={startClick} disabled={geolocationDenied}>
          {intl.formatMessage({ id: 'get_gps_coordinates' })}
        </Button>
      )}
    </Box>
  );
}
