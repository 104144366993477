import type { IntlShape } from 'react-intl';
import { isCerror } from './errors';

const formatWithFallback = (
  intl: IntlShape,
  errorObject?: any,
  id?: string,
  fallbackString = 'Error occurred'
): string => {
  if (!id) return '';
  console.error(id);

  if (isCerror(errorObject)) {
    console.log('CERROR', errorObject, errorObject.info?.code, errorObject.info?.details, 'END CERROR');
    return intl.formatMessage(
      {
        id: errorObject.info?.code,
      },
      {
        details: errorObject.info?.details,
      }
    );
  }

  try {
    return intl.formatMessage({ id });
  } catch (e) {
    return fallbackString;
  }
};

export default formatWithFallback;
