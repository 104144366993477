import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { makeStyles } from '@mui/styles';
import type { ReactNode } from 'react';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    boxShadow: '0px 2px 2px 2px #ddd',
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
}));

function Alert({
  open,
  titleContent,
  description,
  close,
  children,
}: {
  open: boolean;
  titleContent: string;
  description?: string | ReactNode;
  close: () => void;
  children: ReactNode;
}) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      onClose={close}
    >
      <DialogTitle id='alert-dialog-title'>{titleContent}</DialogTitle>
      {description ||
        (children && (
          <DialogContent>
            {children}
            <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
          </DialogContent>
        ))}
      <DialogActions>
        <Button onClick={close} color='primary' autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DialogBehindIcon = ({
  imageElement,
  title,
  description,
  startOpen,
}: {
  imageElement: ReactNode;
  title: string;
  description?: string;
  startOpen?: boolean;
}): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(startOpen ?? false);
  const [timeooutRef, setTimeoutRef] = React.useState<NodeJS.Timeout>();
  const close = () => setOpen(false);

  React.useEffect(() => {
    setTimeoutRef(
      setTimeout(() => {
        setOpen(false);
      }, 5000)
    );
  }, []);

  return (
    <>
      <Zoom in={!open} timeout={1000}>
        <IconButton
          classes={{ root: classes.iconRoot }}
          onClick={() => {
            setOpen((p) => !p);
            if (timeooutRef) {
              clearTimeout(timeooutRef);
              setTimeoutRef(undefined);
            }
          }}
          size='large'
        >
          <ImageSearchIcon />
        </IconButton>
      </Zoom>
      <Alert open={open} close={close} titleContent={title} description={description}>
        {imageElement}
      </Alert>
    </>
  );
};

export default DialogBehindIcon;
