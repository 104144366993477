import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: (props: IProps) => (props.orientation === 'horizontal' ? 'column' : 'row'),
  },
  tabs: {
    borderRight: (props: IProps) => (props.orientation === 'vertical' ? `1px solid ${theme.palette.divider}` : ''),
    borderBottom: (props: IProps) =>
      props.orientation === 'horizontal' ? `1px solid ${theme.palette.divider}` : '',
  },
}));

interface IProps {
  tabNames: string[];
  tabIcons: React.ReactElement[];
  tabContents: React.ReactNode[];
  orientation: 'vertical' | 'horizontal';
}

const Tabbed = (props: IProps): JSX.Element => {
  const { tabNames, tabIcons, tabContents, orientation = 'vertical' } = props;
  const classes = useStyles(props);
  const [value, setValue] = React.useState(0);

  return (
    <div className={classes.root}>
      <Tabs
        orientation={orientation}
        variant='scrollable'
        value={value}
        indicatorColor='primary'
        textColor='inherit'
        onChange={(event, newValue) => {
          if (typeof newValue == 'number') {
            setValue(newValue);
          }
        }}
        aria-label='Vertical tabs example'
        className={classes.tabs}
      >
        {tabNames.map((name: string, i: number) => {
          return <Tab icon={tabIcons[i]} label={name} {...a11yProps(i)} key={i} />;
        })}
      </Tabs>
      {tabContents.map((c: React.ReactNode, i: number) => {
        return (
          <TabPanel value={value} index={i} key={i}>
            {c}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default Tabbed;
