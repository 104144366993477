import type { Coordinates } from '@soilsense/shared';
import type { AnnotationOptions } from 'chartjs-plugin-annotation';
import type { Moment } from 'moment';
import moment from 'moment';
import getSunriseSunsetBasedOnLocation from '../../../../dataHandlers/utils/getSunriseSunsetBasedOnLocation';

export const isNightLocation = (timestamp: Moment, location: Coordinates): boolean => {
  const { sunrise, sunset, timezone } = getSunriseSunsetBasedOnLocation(location.lat, location.lng, timestamp);
  const time = timestamp.tz(timezone).hour() + timestamp.tz(timezone).minute() / 60;
  return time < sunrise || time > sunset;
};

export const isNightMoment = (timestamp: Moment, sunrise: Moment, sunset: Moment): boolean => {
  const time = timestamp.hour() + timestamp.minute() / 60;
  return time < sunrise.hour() + sunrise.minute() / 60 || time > sunset.hour() + sunset.minute() / 60;
};

export function calculateNightTimeAnnotations(
  firstTimestamp: Moment,
  lastTimestamp: Moment,
  location: Coordinates
): [AnnotationOptions[], Moment?, Moment?] | [[]] {
  if (lastTimestamp.diff(firstTimestamp, 'days') > 62) {
    return [[]];
  }

  const { sunrise, sunset, timezone } = getSunriseSunsetBasedOnLocation(
    location.lat,
    location.lng,
    firstTimestamp
  );
  const startOfFirstDay = moment(firstTimestamp).tz(timezone).startOf('day');
  const sunsetTime = moment(startOfFirstDay).subtract(1, 'day').add(sunset, 'hour');
  const sunriseTime = moment(startOfFirstDay).add(sunrise, 'hour');

  const nights: [number, number][] = [];

  while (sunsetTime < lastTimestamp) {
    if (sunriseTime >= firstTimestamp) {
      const nightFrom = moment.max(firstTimestamp, sunsetTime);
      const nightTo = moment.min(lastTimestamp, sunriseTime);
      nights.push([nightFrom.valueOf(), nightTo.valueOf()]);
    }
    sunsetTime.add(1, 'day');
    sunriseTime.add(1, 'day');
  }

  const result: AnnotationOptions[] = nights.map(([from, to]) => ({
    type: 'box',
    xMin: from,
    xMax: to,
    backgroundColor: 'rgba(192, 192, 192, 0.2)',
    borderColor: 'rgba(0, 0, 0, 0)',
  }));
  return [result, sunriseTime, sunsetTime];
}
