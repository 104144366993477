import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';
import type { SiteDetails } from '../../dataHandlers/ObservationSiteStore';
import { useFarmStore } from '../../dataHandlers/RootStore';
import { customerVisibleDeviceId } from '../../dataHandlers/utils/formatters';
import { highlightColor } from '../../utils/consts';
import useTraceUpdate from '../../utils/useTraceUpdate';
import { ColorBoxChart } from './Chart/ColorBoxChart';
import { WeightedAverageChart } from './Chart/WeightedAverageChart';

function shadow(color1: string, color2: string, color3: string): string {
  return [`0px 8px 9px -5px ${color1}`, `0px 15px 22px 2px ${color2}`, `0px 6px 28px 5px ${color3}`].join(',');
}

export const TREE_WIDTH = 120;

export const useStyles = makeStyles(() => ({
  cardWrapper: () => ({
    padding: '15px 20px',
  }),
  card: () => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '10px 0 10px 0',
    zIndex: 1,
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    cursor: 'pointer',
  }),
  clickableCard: {
    '&:hover': {
      boxShadow: shadow('rgba(0,0,0,0)', 'rgba(0,0,0,0.06)', 'rgba(0,0,0,0.2)'),
    },
  },
  selectedCard: {
    boxShadow: shadow(highlightColor(0.9), highlightColor(0.2), highlightColor(0)),
    border: `1px solid ${highlightColor(0.5)}`,
  },
  cardTitleBar: {
    padding: '0 20px',
  },
  cardTitleWrapper: {
    maxWidth: '90%',
    '& span': {
      marginTop: '-5px',
    },
  },
  cardMainArea: {
    padding: '10px 20px 0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

type Props = Readonly<{
  siteDetails: SiteDetails;
  selected: boolean;
  onClick: () => void;
}>;

export interface ICurrentReadings {
  top?: number;
  mid?: number;
  bot?: number;
}

const SensorOverviewCard: FC<Props> = observer((props) => {
  const { siteDetails, selected, onClick } = props;
  useTraceUpdate(props, 'SensorOverviewCard');
  const classes = useStyles();
  const farmStore = useFarmStore();
  const isCalibrated =
    siteDetails.configuration.cableTop.calibration != undefined &&
    siteDetails.configuration.cableBottom.calibration != undefined;

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selected) {
      wrapperRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selected]);

  const siteHasName = siteDetails.site.name.trim() != '';
  const deviceIdString = `ID: ${customerVisibleDeviceId(siteDetails.deviceIds)}`;

  return (
    <div ref={wrapperRef} className={classes.cardWrapper} onClick={onClick}>
      <Card
        className={clsx(classes.card, {
          [classes.clickableCard]: !selected,
          [classes.selectedCard]: selected,
        })}
      >
        <div className={classes.cardTitleBar}>
          <div className={classes.cardTitleWrapper}>
            <Typography variant='h6' noWrap display='block'>
              {siteHasName ? siteDetails.site.name : deviceIdString}
            </Typography>
            {siteHasName && (
              <Typography variant={'caption'} noWrap display='block'>
                {deviceIdString}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.cardMainArea}>
          {isCalibrated ? (
            <ColorBoxChart timeRange={farmStore.selectedDates} siteDetails={siteDetails} />
          ) : (
            <WeightedAverageChart timeRange={farmStore.selectedDates} siteDetails={siteDetails} />
          )}
        </div>
      </Card>
    </div>
  );
});

export default SensorOverviewCard;
