import { makeStyles } from '@mui/styles';
import Ping from 'ping.js';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import logo from '../assets/soilsense.png';
import googleAnalyticsInstance from '../utils/googleAnalytics';

const TIMEOUT_SECONDS = 20;

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: 'radial-gradient(50% 176%, #90a4ae 80%, #a8bdaa 120%)',
    minHeight: '100vh',
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    color: theme.palette.primary.contrastText,
  },
  heroContent: {
    zIndex: 2,
    position: 'relative',
    alignSelf: 'center',
    textAlign: 'center',
    padding: '3rem',
  },
}));

const p = new Ping();

export const LoadingScreen = (): JSX.Element => {
  const classes = useStyles();
  const [firstTimeoutState, setFirstTimeoutState] = useState(false);
  const [timeoutState, setTimeoutState] = useState(false);
  const [internetState, setInternetState] = useState(true);

  const handleConnectionChange = () => {
    // console.log('CONNECTION CHANGE', window.navigator);
    if (window.navigator.onLine) {
      window.location.reload();
    }
  };

  const checkInternet = async () => {
    p.ping('https://google.com', (err: string) => {
      if (err) {
        console.error('Failed pinging. Detected lost internet connection.', err);
        setInternetState(false);
      }
      setInternetState(true);
    });
  };

  useEffect(() => {
    googleAnalyticsInstance.modalView('/loading');
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    const timeout = setTimeout(async () => {
      setFirstTimeoutState(true);
      checkInternet();
    }, (TIMEOUT_SECONDS * 1000) / 2);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!internetState && firstTimeoutState) {
      setTimeoutState(true);
    } else {
      if (firstTimeoutState) {
        setTimeout(async () => {
          setTimeoutState(true);
        }, TIMEOUT_SECONDS * 2 * 1000);
      }
    }
  }, [internetState, firstTimeoutState]);

  const LoadingFailed = () => {
    googleAnalyticsInstance.modalView('/loading-failed');
    return (
      <div className={classes.heroContent}>
        <img width={'148px'} src={logo} alt='Logo' style={{ margin: '0 auto' }} />
        <div>
          <h2>
            <FormattedMessage id='loading_screen_loading_failed' />
          </h2>
          <h5>
            <FormattedMessage id='loading_screen_loading_failed_description' />
          </h5>
          <h6>
            <FormattedMessage id='loading_screen_loading_failed_contact' />
          </h6>
        </div>
      </div>
    );
  };

  const style = `
  body {
    margin: 0px;
    background: radial-gradient(#cdcdcd, #fff);
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .loading {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  .loading .dot {
    position: relative;
    width: 2em;
    height: 2em;
    margin: 0.8em;
    border-radius: 50%;
  }
  .loading .dot::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    animation: wave 2s ease-out infinite;
  }
  .loading .dot:nth-child(1) {
    background: #ddffdd;
  }
  .loading .dot:nth-child(1)::before {
    animation-delay: 0.2s;
  }
  .loading .dot:nth-child(2) {
    background: #89e089;
  }
  .loading .dot:nth-child(2)::before {
    animation-delay: 0.4s;
  }
  .loading .dot:nth-child(3) {
    background: #46c046;
  }
  .loading .dot:nth-child(3)::before {
    animation-delay: 0.6s;
  }
  .loading .dot:nth-child(4) {
    background: #0fa00f;
  }
  .loading .dot:nth-child(4)::before {
    animation-delay: 0.8s;
  }
  .loading .dot:nth-child(5) {
    background: #008000;
  }
  .loading .dot:nth-child(5)::before {
    animation-delay: 1s;
  }

  @keyframes wave {
    50%,
    75% {
      transform: scale(2.5);
    }
    80%,
    100% {
      opacity: 0;
    }
  }`;

  return (
    <>
      {!timeoutState ? (
        <div className='loading'>
          <div className='dot' />
          <div className='dot' />
          <div className='dot' />
          <div className='dot' />
          <div className='dot' />
        </div>
      ) : (
        <LoadingFailed />
      )}
      <style type='text/css' media='screen' dangerouslySetInnerHTML={{ __html: style }} />
    </>
  );
};
