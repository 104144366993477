import CloseIcon from '@mui/icons-material/Close';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import type { FC } from 'react';
import React, { useState } from 'react';
import { DEFAULT_ZOOM } from 'utils/consts';
import type { SiteDetails } from '../../dataHandlers/ObservationSiteStore';
import MapWrapper from '../Map/MapWrapper';
import MarkersMap from '../Map/MarkersMap';

interface IProps {
  siteDetails: SiteDetails;
}

const MapIcon: FC<IProps> = ({ siteDetails }) => {
  const [mapHiddenState, setMapHiddenState] = useState(true);
  const [mapZoomEntered, setMapZoomEntered] = useState(false);

  const mapStyle: React.CSSProperties = {
    position: 'absolute',
    right: 50,
    top: 5,
    width: '200px',
    height: '200px',
  };

  return (
    <div>
      <IconButton
        color='inherit'
        aria-label='dismiss pop up'
        onClick={() => setMapHiddenState(!mapHiddenState)}
        edge='start'
        size='large'
      >
        {mapHiddenState ? <RoomOutlinedIcon /> : <CloseIcon />}
      </IconButton>
      <Zoom
        in={!mapHiddenState}
        mountOnEnter={true}
        onEntered={() => {
          setMapZoomEntered(true);
        }}
      >
        <MapWrapper style={mapStyle}>
          {mapZoomEntered ? (
            <MarkersMap
              siteDetails={[siteDetails]}
              borderRadius={5}
              center={siteDetails.site.coordinates}
              zoom={DEFAULT_ZOOM}
              showNdviLayer={false}
            />
          ) : undefined}
        </MapWrapper>
      </Zoom>
    </div>
  );
};

export default MapIcon;
