import type { ChartData, ChartOptions } from 'chart.js';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { IrrigationStatusBox } from '../../../dataHandlers/SensorTransformer';
import { getMarkerColorForIrrigationStatus } from '../../../utils/getMarkerColorFromReadings';
import ChartJsComponent from './ChartJsComponent';

export const CHART_HEIGHT = '85px';

type Props = Readonly<{
  boxes: readonly IrrigationStatusBox[];
}>;

export const ChartJsColorBoxChart: FC<Props> = observer(({ boxes }) => {
  const intl = useIntl();
  const data: ChartData = useMemo(
    () => ({
      labels: boxes.map((box) => (box.timeRange[0] + box.timeRange[1]) / 2),
      datasets: [
        {
          data: Array(boxes.length).fill(1),
        },
      ],
    }),
    [boxes]
  );
  const options: ChartOptions = useMemo(
    () => ({
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 0,
        },
        line: {
          borderWidth: 0,
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          type: 'time',
          min: boxes[0].timeRange[0],
          max: boxes[boxes.length - 1].timeRange[1],
          ticks: {
            maxRotation: 0,
            callback: (label, index, ticks) => {
              const date = moment(ticks[index].value);
              const major = ticks[index].major;

              if (date.isValid()) {
                return intl.formatDate(date.toDate(), {
                  month: major ? 'long' : 'short',
                  day: 'numeric',
                });
              }
              return label;
            },
          },
        },
        y: {
          min: 0,
          max: 1,
          display: false,
        },
      },
      plugins: {
        tooltip: {
          xAlign: 'center',
          yAlign: 'bottom',
          titleAlign: 'center',
          titleFont: {
            size: 10,
          },
          bodyAlign: 'center',
          bodyFont: {
            size: 14,
          },
          callbacks: {
            title: (items) => {
              const box = boxes[items[0]?.dataIndex];
              if (box == undefined) {
                return '';
              }
              const start = moment(box.timeRange[0]);
              const end = moment(box.timeRange[1]);
              return `${intl.formatDate(start.toDate(), {
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })} - ${intl.formatDate(end.toDate(), {
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}`;
            },
            beforeLabel: () => '',
            label: () => '',
            beforeBody: (items) => {
              const box = boxes[items[0].dataIndex];
              if (box == undefined) {
                return '';
              }
              return intl.formatMessage({ id: `soil_status_label_${box.status}` });
            },
          },
        },
        annotation: {
          annotations: boxes.map((box) => {
            const color = getMarkerColorForIrrigationStatus(box.status);
            return {
              type: 'box',
              xMin: box.timeRange[0],
              xMax: box.timeRange[1],
              yMin: 0,
              yMax: 1,
              borderWidth: 1,
              borderColor: color,
              backgroundColor: color,
            };
          }),
        },
        legend: {
          display: false,
        },
      },
    }),
    [boxes, intl]
  );
  return <ChartJsComponent type='line' data={data} options={options} width='100%' height={CHART_HEIGHT} />;
});
