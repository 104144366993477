import { makeStyles } from '@mui/styles';

export const newDeviceColorStatusColor = 'rgba(50, 50, 200, 0.8)';

export const defaultColumnStyle = { display: 'flex', flexDirection: 'column' } as const;
export const defaultRowStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
} as const;

export const useStyles = makeStyles((theme) => ({
  oneSensorWrapper: {
    width: '100%',
    ...defaultColumnStyle,
    '& > *': {
      marginTop: 15,
    },
  },
  sensorRowWrapper: {
    ...defaultRowStyle,
    '& > *': {
      paddingLeft: 10,
    },
  },
  thresholdRow: {
    ...defaultRowStyle,
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    '& > *': {
      paddingLeft: 5,
    },
    [theme.breakpoints.down('md')]: {
      ...defaultColumnStyle,
      '& > *': {
        marginBottom: 10,
      },
    },
  },
  nested: {
    width: '100%',
    paddingLeft: theme.spacing(7),
  },
  addSensorBox: {
    padding: 10,
  },
}));
