import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { DeviceIdentifiers } from 'dataHandlers/utils/formatters';
import { customerVisibleDeviceId } from 'dataHandlers/utils/formatters';
import type { Dispatch } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = Readonly<{
  devices: readonly DeviceIdentifiers[];
  deviceId: string;
  setDeviceId: Dispatch<string>;
}>;

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

export default function DeviceSelector({ devices, deviceId, setDeviceId }: Props): JSX.Element {
  const classes = useStyles();
  const intl = useIntl();
  const onChange = (event: SelectChangeEvent<string>) => {
    if (typeof event.target.value === 'string') {
      setDeviceId(event.target.value);
    }
  };

  const label = intl.formatMessage({ id: 'device_id' });
  return (
    <FormControl variant='outlined' fullWidth className={classes.formControl}>
      <InputLabel id='device-selector-label'>{label}</InputLabel>
      <Select key={deviceId} labelId='device-selector-label' label={label} value={deviceId} onChange={onChange}>
        {devices.map((device) => (
          <MenuItem key={device.id} value={device.id}>
            ID: {customerVisibleDeviceId(device)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
