import type { Coordinates } from '@soilsense/shared';
import type { TransformedData } from 'dataHandlers/SensorTransformer';
import type { MomentInput } from 'moment';
import moment from 'moment';
import { default as React, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getChartColors } from './Config';
import type { ChartZoom, DatasetSpec, TimeSeriesSpec } from './TimeSeriesChart';
import { TimeSeriesChart } from './TimeSeriesChart';
import { calculateNightTimeAnnotations } from './utils/calculateNightTimeAnnotations';

type Props = {
  data: readonly TransformedData[];
  chartZoom: ChartZoom;
  coordinates: Coordinates;
};

export function ChartJsBoxTemperatureChart({ data, chartZoom, coordinates }: Props): JSX.Element {
  const intl = useIntl();
  const Y_LABEL = intl.formatMessage({ id: 'box_temperature' });

  const timeSeriesSpec = useMemo((): TimeSeriesSpec => {
    const { airTemperatureColor } = getChartColors();
    const labels: number[] = [];
    let spec: DatasetSpec = {
      label: `${intl.formatMessage({ id: 'box_temperature', defaultMessage: 'Box temperature' })}*`,
      data: [],
      color: airTemperatureColor,
      segment: undefined,
    };
    for (const each of data) {
      labels.push(each.timestamp);
      // const timestamp = moment(each.timestamp);
      spec.data.push(each.airTemp ?? null);

      // if (isNight(timestamp, coordinates)) {
      //   spec.data.push(each.airTemp ?? null);
      // } else {
      //   spec.data.push(null);
      // }
      // spec.data.push(each.airTemp ?? null);
    }

    const firstTimestamp = moment(labels[0]);
    const lastTimestamp = moment(labels[labels.length - 1]);
    const timeUnit = lastTimestamp.diff(firstTimestamp, 'day') <= 3 ? 'hour' : 'day';

    const [nightTimeAnnotations, sunrise, sunset] = calculateNightTimeAnnotations(
      moment(chartZoom.bounds?.[0] ?? firstTimestamp),
      moment(chartZoom.bounds?.[1] ?? lastTimestamp),
      coordinates
    );

    spec = {
      ...spec,
      segment: {
        borderColor: (ctx: any) => {
          console.log({ ctx });
          const chart = ctx.chart;
          const { p0, p1 } = ctx;
          const value = p0.raw;
          const time = p0.parsed.x as MomentInput;
          function hexToRGBA(hex: string, opacity: number): string {
            // Remove the hash at the start if it's there
            hex = hex.replace('#', '');

            // Parse the hex color
            let r = 0,
              g = 0,
              b = 0;
            if (hex.length === 3) {
              // Shorthand hex color
              r = parseInt(hex[0] + hex[0], 16);
              g = parseInt(hex[1] + hex[1], 16);
              b = parseInt(hex[2] + hex[2], 16);
            } else if (hex.length === 6) {
              // Full hex color
              r = parseInt(hex.substring(0, 2), 16);
              g = parseInt(hex.substring(2, 4), 16);
              b = parseInt(hex.substring(4, 6), 16);
            }

            // Return the RGBA color
            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
          }
          if (!sunrise || !sunset) {
            return undefined;
          }

          // scaled to 0-20
          const distanceAbove30 = Math.min(Math.max(value - 20, 0), 20);
          const opacity = Math.max(1 - distanceAbove30 / 20.0, 0.1);
          // if (!isNightMoment(moment(time), sunrise, sunset)) {
          //   return hexToRGBA(airTemperatureColor, opacity);
          // }

          return airTemperatureColor;
        },
        borderDash: (ctx: any) => {
          const chart = ctx.chart;
          const { p0, p1 } = ctx;
          const value = p0.raw;
          const time = p0.parsed.x as MomentInput;
          if (!sunrise || !sunset) {
            return undefined;
          }
          // if (!isNightMoment(moment(time), sunrise, sunset)) {
          //   return [16, 1];
          // }

          return undefined;
        },
      },
    };

    const datasetSpecs = [spec];

    return {
      labels,
      chartZoom,
      datasetSpecs,
      rightAxisSpec: undefined,
      safeRangeOptions: undefined,
      timeUnit,
      annotations: nightTimeAnnotations,
      aggregates: [],
    };
  }, [chartZoom, data, intl, coordinates]);

  return (
    <>
      <TimeSeriesChart {...timeSeriesSpec} yLabel={Y_LABEL} yMin={undefined} yMax={undefined} yUnit='°C' />
      <p
        style={{
          padding: '0 40px',
          color: 'gray',
          fontSize: '0.8rem',
        }}
      >
        *{intl.formatMessage({ id: 'box_temperature_warning' })}
      </p>
    </>
  );
}
