import { action, comparer, computed, makeObservable, observable, toJS } from 'mobx';
import type { IPromiseBasedObservable } from 'mobx-utils';
import { fromPromise } from 'mobx-utils';
import type { Moment } from 'moment';
import moment from 'moment';
import { BASE_URL } from 'utils/consts';
import { fetchAndSet } from 'utils/fetchAndSet';
import getErrorMessage from 'utils/getErrorMessage';
import {
  BOT_SENSOR_ATTRIBUTE,
  MID_SENSOR_ATTRIBUTE,
  TOP_SENSOR_ATTRIBUTE,
  WATER_VOLUME_ATTRIBUTE,
} from '../consts/backendAttributes';
import type { IDataElement } from '../interfaces/IDataElement';
import { DATA_ELEMENT_DECODER } from '../interfaces/IDataElement';
import type { FirebaseTokenProvider } from '../interfaces/IUser';
import type { ObservationSiteStore, SiteInfo } from './ObservationSiteStore';
import { getTransformFunctions } from './SensorTransformer';
import type { UserStore } from './UserStore';
import type { DeviceIdentifiers } from './utils/formatters';
import { customerVisibleDeviceId } from './utils/formatters';
import { hasCalibration } from './utils/typeGuards';

export class DataLoggerStatus {
  @observable private lastData: IPromiseBasedObservable<IDataElement>;

  constructor(private readonly siteInfo: SiteInfo, private readonly userStore: UserStore) {
    makeObservable(this);
    this.lastData = this.fetchStatus();
  }

  @action updateStatus(): void {
    this.lastData = this.fetchStatus();
  }

  private fetchStatus(): IPromiseBasedObservable<IDataElement> {
    const { currentTokenProvider } = this.userStore;
    if (currentTokenProvider == undefined) {
      return fromPromise.reject(new Error('Cannot fetch sensor data: No user is logged in'));
    }

    return fromPromise(fetchLastStatus(toJS(this.siteInfo.deviceIds), currentTokenProvider));
  }

  @computed get isLoading(): boolean {
    return this.lastData.state == 'pending';
  }

  @computed get error(): string | undefined {
    return this.lastData.state == 'rejected' ? getErrorMessage(this.lastData.value) : undefined;
  }

  @computed get rawData(): IDataElement | undefined {
    return this.lastData.state == 'fulfilled' ? this.lastData.value : undefined;
  }

  @computed({ equals: comparer.shallow })
  get lastPawReading(): { top: number | undefined; mid: number | undefined; bot: number | undefined } | undefined {
    if (this.lastData.state != 'fulfilled') {
      return undefined;
    }

    const { configuration } = this.siteInfo;
    const lastReading = getVwcFromLastReading(this.lastData.value);
    if (!lastReading || !hasCalibration(configuration)) {
      return undefined;
    }

    const { transformTop, transformMid, transformBot } = getTransformFunctions(configuration);
    let { top, bot } = lastReading;
    const mid = lastReading.mid;

    if (configuration.topAndBottomCablesSwapped) {
      const temp = top;
      top = bot;
      bot = temp;
    }

    const t = top && transformTop ? transformTop(top) : undefined;
    const m = mid && transformMid ? transformMid(mid) : undefined;
    const b = bot && transformBot ? transformBot(bot) : undefined;

    return {
      top: t && t < 0 ? 0 : t,
      mid: m && m < 0 ? 0 : m,
      bot: b && b < 0 ? 0 : b,
    };
  }

  @computed({ equals: comparer.shallow })
  get lastVwcReading(): { top: number | undefined; mid: number | undefined; bot: number | undefined } | undefined {
    if (this.lastData.state != 'fulfilled') {
      return undefined;
    }

    const { configuration } = this.siteInfo;

    const lastReading = getVwcFromLastReading(this.lastData.value);
    if (!lastReading) {
      return undefined;
    }

    let { top, bot } = lastReading;
    const mid = lastReading.mid;

    if (configuration.topAndBottomCablesSwapped) {
      const temp = top;
      top = bot;
      bot = temp;
    }

    return {
      top,
      mid,
      bot,
    };
  }

  @computed get lastDataTimestamp(): Moment | undefined {
    if (this.lastData.state != 'fulfilled') {
      return undefined;
    }
    return moment(this.lastData.value.timestamp);
  }
}

async function fetchLastStatus(
  identifiers: DeviceIdentifiers,
  getIdToken: FirebaseTokenProvider
): Promise<IDataElement> {
  const response = await fetchAndSet(`${BASE_URL}/observations/last/${identifiers.id}`, await getIdToken());
  if (!response) {
    throw new Error(`Failed fetching data for ${identifiers.id}`);
  }

  const result = await DATA_ELEMENT_DECODER.decodeToPromise(response);
  if (
    result.data[BOT_SENSOR_ATTRIBUTE][WATER_VOLUME_ATTRIBUTE] == null &&
    result.data[TOP_SENSOR_ATTRIBUTE][WATER_VOLUME_ATTRIBUTE] == null
  ) {
    throw new Error(
      `No water volume data found on last readings for sensor ${customerVisibleDeviceId(identifiers)}`
    );
  }
  return result;
}

const getVwcFromLastReading = (reading: IDataElement | undefined) => {
  if (!reading) {
    return;
  }

  return {
    [TOP_SENSOR_ATTRIBUTE]: reading.data[TOP_SENSOR_ATTRIBUTE][WATER_VOLUME_ATTRIBUTE],
    [MID_SENSOR_ATTRIBUTE]: reading.data[MID_SENSOR_ATTRIBUTE]?.[WATER_VOLUME_ATTRIBUTE],
    [BOT_SENSOR_ATTRIBUTE]: reading.data[BOT_SENSOR_ATTRIBUTE][WATER_VOLUME_ATTRIBUTE],
  };
};

export class DataLoggerStatusStore {
  @observable private readonly idToStatus: Map<string, DataLoggerStatus> = new Map();

  constructor(private readonly userStore: UserStore, private readonly observationSiteStore: ObservationSiteStore) {
    makeObservable(this);
  }

  @action updateStatuses(): void {
    if (this.userStore.currentUser == undefined) {
      this.idToStatus.clear();
      return;
    }

    const dataLoggerIdsToKeep: Set<string> = new Set();

    for (const siteDetails of this.observationSiteStore.activeSiteDetails) {
      const id = siteDetails.deviceIds.id;
      dataLoggerIdsToKeep.add(id);
      const lastData = this.idToStatus.get(id);
      if (lastData == undefined) {
        this.idToStatus.set(id, new DataLoggerStatus(siteDetails, this.userStore));
      } else {
        lastData.updateStatus();
      }
    }

    for (const id of Array.from(this.idToStatus.keys())) {
      if (dataLoggerIdsToKeep.has(id) == false) {
        this.idToStatus.delete(id);
      }
    }
  }

  @action updateStatus(id: string): void {
    const siteDetails = this.observationSiteStore.getSiteInfoByDeviceId(id);
    if (siteDetails == undefined) {
      return;
    }

    const lastData = this.idToStatus.get(id);
    if (lastData == undefined) {
      this.idToStatus.set(id, new DataLoggerStatus(siteDetails, this.userStore));
    } else {
      lastData.updateStatus();
    }
  }

  getLastReading(dataLoggerId: string): DataLoggerStatus | undefined {
    return this.idToStatus.get(dataLoggerId);
  }

  getLastReadingTime(dataLoggerId: string): moment.Moment | undefined {
    return this.idToStatus.get(dataLoggerId)?.lastDataTimestamp;
  }

  @computed get isLoading(): boolean {
    return Array.from(this.idToStatus.values()).some(({ isLoading }) => isLoading);
  }

  objectIsLoading(dataLoggerId: string): boolean {
    return this.idToStatus.get(dataLoggerId)?.isLoading ?? false;
  }

  error(dataLoggerId: string): string | undefined {
    return this.idToStatus.get(dataLoggerId)?.error;
  }
}
