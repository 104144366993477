import { green, pink } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      // overflow: 'hidden',
    },
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  demo: {
    // backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(3),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    // margin: theme.spacing(2, 0, 0),
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    backgroundColor: 'rgb(255, 255, 255, 0.65)',
    padding: 20,
    borderRadius: 10,
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  gray: {
    color: '#fff',
    backgroundColor: theme.palette.grey[500],
  },
}));
