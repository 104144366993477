import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React from 'react';
import type { RouteProps } from 'react-router';
import { Redirect } from 'react-router';
import { LoadingScreen } from '../components/LoadingScreen';
import { useCurrentUser } from '../dataHandlers/RootStore';
import { Routes } from '../Routes';
import { NotFound } from './NotFound';
import PrivateRoute from './PrivateRoute';

const AdminPrivateRoute: FC<RouteProps> = observer(({ location, component, ...rest }) => {
  const currentUser = useCurrentUser();
  if (currentUser === undefined) {
    return <LoadingScreen />;
  }

  if (currentUser == null) {
    return <Redirect to={{ pathname: Routes.LOGIN, state: { from: location?.pathname } }} />;
  }

  if (!currentUser?.isAdmin) {
    return <NotFound message="You don't have access to this page" />;
  }

  return <PrivateRoute component={component} {...rest} />;
});

export default AdminPrivateRoute;
