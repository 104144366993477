import type { Coordinates } from '@soilsense/shared';
import type { NameMap, TransformedData } from 'dataHandlers/SensorTransformer';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { translateDethNames } from '../../../i18n/utils';
import { getChartColors } from './Config';
import type { ChartZoom, DatasetSpec, TimeSeriesSpec } from './TimeSeriesChart';
import { TimeSeriesChart } from './TimeSeriesChart';
import { calculateNightTimeAnnotations } from './utils/calculateNightTimeAnnotations';

type Props = {
  data: readonly TransformedData[];
  chartZoom: ChartZoom;
  displayMidDepth: boolean;
  nameMap: NameMap;
  useSingleChart: boolean;
  coordinates: Coordinates;
};

export function ChartJsTemperatureChart({
  data,
  chartZoom,
  displayMidDepth,
  nameMap,
  useSingleChart,
  coordinates,
}: Props): JSX.Element {
  const intl = useIntl();
  const Y_LABEL = intl.formatMessage({ id: 'controls_soil_temperature' });
  const timeSeriesSpec = useMemo((): TimeSeriesSpec => {
    const { temperatureColor } = getChartColors();
    const labels: number[] = [];
    const topSpec: DatasetSpec = {
      label: translateDethNames(nameMap.tempTop, intl),
      data: [],
      color: temperatureColor.cableTop,
    };
    const midSpec: DatasetSpec = {
      label: translateDethNames(nameMap.tempMid, intl),
      data: [],
      color: temperatureColor.cableMiddle,
    };
    const botSpec: DatasetSpec = {
      label: translateDethNames(nameMap.tempBot, intl),
      data: [],
      color: temperatureColor.cableBottom,
    };
    for (const each of data) {
      labels.push(each.timestamp);
      topSpec.data.push(each.tempTop ?? null);
      midSpec.data.push(each.tempMid ?? null);
      botSpec.data.push(each.tempBot ?? null);
    }
    const datasetSpecs = displayMidDepth ? [topSpec, midSpec, botSpec] : [topSpec, botSpec];

    const firstTimestamp = moment(labels[0]);
    const lastTimestamp = moment(labels[labels.length - 1]);
    const timeUnit = lastTimestamp.diff(firstTimestamp, 'day') <= 3 ? 'hour' : 'day';

    const [nightTimeAnnotations] = calculateNightTimeAnnotations(
      moment(chartZoom.bounds?.[0] ?? firstTimestamp),
      moment(chartZoom.bounds?.[1] ?? lastTimestamp),
      coordinates
    );

    return {
      labels,
      chartZoom,
      datasetSpecs,
      rightAxisSpec: undefined,
      safeRangeOptions: undefined,
      timeUnit,
      annotations: nightTimeAnnotations,
      aggregates: [],
    };
  }, [chartZoom, data, displayMidDepth, nameMap.tempBot, nameMap.tempMid, nameMap.tempTop, intl, coordinates]);

  if (useSingleChart) {
    return <TimeSeriesChart {...timeSeriesSpec} yLabel={Y_LABEL} yMin={undefined} yMax={undefined} yUnit='°C' />;
  } else {
    return (
      <>
        {timeSeriesSpec.datasetSpecs.map((datasetSpec, index) => (
          <TimeSeriesChart
            {...timeSeriesSpec}
            key={index}
            datasetSpecs={[datasetSpec]}
            yLabel={Y_LABEL}
            yMin={undefined}
            yMax={undefined}
            yUnit='°C'
            small
          />
        ))}
      </>
    );
  }
}
