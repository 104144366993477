import type { History } from 'history';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, withRouter } from 'react-router';
import { SoilsenseBackgroundOverlay } from '../components/BackgroundOverlayContainer';
import ForgotPassword from '../components/ForgotPassword';
import { SeverityLevel, showSnackBar } from '../components/SnackBar';
import { useCurrentUser, useUserStore } from '../dataHandlers/RootStore';
import getErrorMessage from '../utils/getErrorMessage';

const ForgotPasswordPage = observer(({ history }: { history: History }) => {
  const [loading, setLoading] = useState(false);
  const userStore = useUserStore();
  const intl = useIntl();

  const handleForgotPassword = useCallback(
    async (event) => {
      event.preventDefault();
      const {
        email: { value: email },
      } = event.target.elements;
      if (typeof email != 'string') {
        return;
      }
      setLoading(true);
      try {
        await userStore.sendPasswordResetEmail(email.trim());
        showSnackBar(SeverityLevel.success, intl.formatMessage({ id: 'forgot_password_email_sent' }));
        history.push('/');
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        showSnackBar(
          SeverityLevel.error,
          intl.formatMessage(
            { id: 'forgot_password_error' },
            {
              error: getErrorMessage(error),
            }
          )
        );
      }
    },
    [history, userStore, intl]
  );

  const currentUser = useCurrentUser();
  if (currentUser) {
    return <Redirect to={{ pathname: '/', state: { from: 'forgotPassword' } }} />;
  }

  return (
    <SoilsenseBackgroundOverlay>
      <ForgotPassword onSubmit={handleForgotPassword} loading={loading} />
    </SoilsenseBackgroundOverlay>
  );
});

export default withRouter(ForgotPasswordPage);
