import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import type { FC } from 'react';
import React from 'react';

export interface ISwitchWithLabelProps {
  checked: boolean;
  onChange: () => void;
  label?: JSX.Element;
  disabled: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  className?: string;
}

const SwitchWithLabel: FC<ISwitchWithLabelProps> = ({
  checked,
  onChange,
  label,
  disabled,
  labelPlacement = 'end',
  className,
}) => {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} disabled={disabled} color='secondary' />}
      label={label}
      labelPlacement={labelPlacement}
      className={className}
    />
  );
};

export default SwitchWithLabel;
