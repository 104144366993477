import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    position: (props: IProps) => (props.position ? props.position : 'fixed'),
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
  },
  topBarLoadingRoot: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 9999,
  },
}));

interface IProps {
  showAsTopBar?: boolean;
  color?: 'primary' | 'secondary';
  position?: 'absolute' | 'fixed';
  style?: React.CSSProperties;
}

const Loading: FC<IProps> = (props) => {
  const { color, showAsTopBar = false, style } = props;
  const classes = useStyles(props);
  if (showAsTopBar) {
    return (
      <div className={classes.topBarLoadingRoot} style={style}>
        <LinearProgress color={color} style={{ margin: '0 auto' }} />
      </div>
    );
  }
  return (
    <div className={classes.root} style={style}>
      <CircularProgress color={color} style={{ margin: '0 auto' }} />
    </div>
  );
};

export default Loading;
