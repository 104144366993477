import type { SiteDetails } from '../dataHandlers/ObservationSiteStore';
import { DEFAULT_BOT_THRESHOLD, DEFAULT_TOP_THRESHOLD } from '../interfaces/ISensor';
import assertNever from './assertNever';

const IRRIGATION_STATUS_PRIORITY = {
  ok: 1,
  unknown: 2,
  overIrrigated: 3,
  dry: 4,
  wilting: 5,
} as const;

export type IrrigationStatus = keyof typeof IRRIGATION_STATUS_PRIORITY;

export const IRRIGATION_STATUS_COLOR = {
  ok: '#04724D',
  unknown: '#AAACB0',
  overIrrigated: '#3474BB',
  dry: '#FDE74C',
  wilting: '#DB2B39',
} as const;

export const DARK_NEUTRAL_COLOR = '#444';

export const NEUTRAL_COLOR = IRRIGATION_STATUS_COLOR.unknown;

export const BRIGHT_NEUTRAL_COLOR = 'white' as const;

export const INTERACTION_COLOR = IRRIGATION_STATUS_COLOR.dry;

export type MarkerColor = typeof IRRIGATION_STATUS_COLOR[IrrigationStatus];

export type FieldColor = MarkerColor | typeof BRIGHT_NEUTRAL_COLOR;

export function highestPriorityIrrigationStatus(...statuses: IrrigationStatus[]): IrrigationStatus {
  statuses.sort((first, second) => IRRIGATION_STATUS_PRIORITY[second] - IRRIGATION_STATUS_PRIORITY[first]);
  return statuses[0] ?? 'unknown';
}

export function getIrrigationStatusFromReadings(
  reading: { top?: number; mid?: number; bot?: number } | undefined,
  plantAvailableWaterSafeRange: readonly [number, number] | undefined
): IrrigationStatus {
  const lowerBound = plantAvailableWaterSafeRange?.[0] ?? DEFAULT_BOT_THRESHOLD;
  const upperBound = plantAvailableWaterSafeRange?.[1] ?? DEFAULT_TOP_THRESHOLD;

  if (reading == undefined) {
    return 'unknown';
  }
  const topUnderThreshold = reading.top != undefined && reading.top < lowerBound;
  const midUnderThreshold = reading.mid != undefined && reading.mid < lowerBound;
  const botUnderThreshold = reading.bot != undefined && reading?.bot < lowerBound;
  if (topUnderThreshold && botUnderThreshold) {
    return 'wilting';
  }
  if (topUnderThreshold || midUnderThreshold || botUnderThreshold) {
    return 'dry';
  }
  if (reading.bot != undefined && reading.bot > upperBound) {
    return 'overIrrigated';
  }
  if (reading.top == undefined || reading.bot == undefined) {
    return 'unknown';
  }
  return 'ok';
}

export function getMarkerColorForIrrigationStatus(status: IrrigationStatus): MarkerColor {
  return IRRIGATION_STATUS_COLOR[status];
}

export function getMarkerColorFromReadings(
  reading: { top?: number; mid?: number; bot?: number } | undefined,
  plantAvailableWaterSafeRange: readonly [number, number] | undefined
): MarkerColor {
  return getMarkerColorForIrrigationStatus(getIrrigationStatusFromReadings(reading, plantAvailableWaterSafeRange));
}

export function getIrrigationStatus({ transformed }: SiteDetails): IrrigationStatus {
  switch (transformed.status) {
    case 'pending':
    case 'error':
      return 'unknown';

    case 'ready': {
      const { statusBoxes } = transformed.value;
      const lastBox = statusBoxes[statusBoxes.length - 1];
      return lastBox?.status ?? 'unknown';
    }

    default:
      return assertNever(transformed);
  }
}

export default getMarkerColorFromReadings;
