import { Autocomplete, Box, Button, TextField } from '@mui/material';
import type { Dispatch } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import { cropTypeToDepths } from '../../consts/cropTypeToDepths';
import { cropTypeOptions } from '../../consts/cropTypeToPaw';
import type { MidDepthValue } from '../Settings/SensorDepths';
import { SensorDepths } from '../Settings/SensorDepths';

// The depth prefill based on crop type is a POC that
// is disabled for now because we decided it requires additional
// considerations in terms of the UX of it
// After setting CROP_DEPTHS_PREFILL_DISABLED to false the boolean
// should be removed altogether once everything works as expected.
const CROP_DEPTHS_PREFILL_DISABLED = true;

const getDepths = (cropType: string, midDepth: MidDepthValue) => {
  const depths = cropTypeToDepths.find((crop) => crop.label === cropType);
  if (depths == null) {
    return { top: undefined, bot: undefined, mid: undefined };
  }
  if (midDepth === 'disabled') {
    return { top: depths.midDepth, bot: depths.deeperDepth };
  } else {
    return {
      top: depths.shallowDepth,
      bot: depths.deeperDepth,
      mid: depths.midDepth,
    };
  }
};

export default function LocationConfigurationInput({
  name,
  setName,
  topDepth,
  setTopDepth,
  midDepth,
  setMidDepth,
  botDepth,
  setBotDepth,
  cropType,
  setCropType,
}: {
  name: string;
  setName: Dispatch<string>;
  topDepth: number | undefined;
  setTopDepth: Dispatch<number | undefined>;
  midDepth: MidDepthValue;
  setMidDepth: Dispatch<MidDepthValue>;
  botDepth: number | undefined;
  setBotDepth: Dispatch<number | undefined>;
  cropType: string;
  setCropType: Dispatch<string>;
}): JSX.Element {
  const [wePrefilledDepths, setWePrefilledDepths] = React.useState(false);
  const [depthEditDisabled, setDisabled] = React.useState(true);
  const intl = useIntl();
  const cropTypeOptionsSortedAndTranslated = React.useMemo(() => {
    return cropTypeOptions
      .map((option) => {
        return { ...option, label: intl.formatMessage({ id: option.label }) };
      })
      .sort((a, b) => {
        if (b.id.toLowerCase().includes('other')) {
          return 1;
        }
        return a.label.localeCompare(b.label);
      });
  }, [intl]);

  React.useEffect(() => {
    if (!CROP_DEPTHS_PREFILL_DISABLED && cropType) {
      const { top, bot, mid } = getDepths(cropType, midDepth);
      setTopDepth(top);
      setBotDepth(bot);
      if (mid != null) {
        setMidDepth(mid);
      }
      if (depthEditDisabled) {
        // if the depthEdit is active then the user is already editting the depths
        setWePrefilledDepths(true);
      }
    }
  }, [cropType, setTopDepth, setBotDepth, setMidDepth, depthEditDisabled, midDepth]);

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <TextField
        // label='Location name'
        label={intl.formatMessage({ id: 'location_name' })}
        variant='outlined'
        value={name}
        type='text'
        onChange={(e) => setName(e.target.value)}
        fullWidth
        required
      />
      <Autocomplete
        disablePortal
        id='crop-type'
        options={cropTypeOptionsSortedAndTranslated}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label={`${intl.formatMessage({ id: 'crop_type' })} *`} />}
        value={cropTypeOptions.find((option) => option.id === cropType)}
        onChange={(e, value) => (value != null ? setCropType(value.id) : setCropType(''))}
        // noOptionsText={'Crop type not found. Please select "Other" and contact us if you need help.'}
        noOptionsText={intl.formatMessage({
          id: 'crop_type_not_found',
          defaultMessage: 'Crop type not found. Please select "Other" and contact us if you need help.',
        })}
        aria-required
      />
      {wePrefilledDepths && (
        <div>
          We prefilled the depths with values that we recommend for you based on the selected crop type. <br />
          <Button
            variant='text'
            onClick={() => {
              setDisabled(!depthEditDisabled);
              setWePrefilledDepths(false);
            }}
          >
            Click here to edit
          </Button>
        </div>
      )}
      <SensorDepths
        topDepth={topDepth}
        setTopDepth={setTopDepth}
        midDepth={midDepth}
        setMidDepth={setMidDepth}
        botDepth={botDepth}
        setBotDepth={setBotDepth}
        fullWidth
        disabled={!CROP_DEPTHS_PREFILL_DISABLED && depthEditDisabled}
      />
    </Box>
  );
}
