export const SALINITY_TOP = 'salTop';
export const SALINITY_MID = 'salMid';
export const SALINITY_BOT = 'salBot';
export const TEMPERATURE_TOP = 'tempTop';
export const TEMPERATURE_MID = 'tempMid';
export const TEMPERATURE_BOT = 'tempBot';
export const BOX_TEMPERATURE = 'airTemp';
export const VOLUMETRIC_WATER_CONTENT_TOP = 'vwcTop';
export const VOLUMETRIC_WATER_CONTENT_MID = 'vwcMid';
export const VOLUMETRIC_WATER_CONTENT_BOT = 'vwcBot';
export const PLANT_AVAILABLE_WATER_TOP = 'pawTop';
export const PLANT_AVAILABLE_WATER_MID = 'pawMid';
export const PLANT_AVAILABLE_WATER_BOT = 'pawBot';
export const MOISTURE_SAFE_RANGE = 'Moisture safe range';
export const SALINITY_SAFE_RANGE = 'EC safe range';

export type VWC =
  | typeof VOLUMETRIC_WATER_CONTENT_BOT
  | typeof VOLUMETRIC_WATER_CONTENT_MID
  | typeof VOLUMETRIC_WATER_CONTENT_TOP;
