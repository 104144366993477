import type { IntlShape } from 'react-intl';
import { isCerror } from './errors';

const getErrorMessageIntl = (e: any, intl: IntlShape, fallbackString = 'Error occurred'): string => {
  let error: string | { status: number } | Error | undefined;
  let message: string = fallbackString;

  console.log('this error', { e });

  if (isCerror(e)) {
    console.log('CERROR', e, e.info?.code, e.info?.details, 'END CERROR');
    return intl.formatMessage(
      {
        id: e.info?.code,
      },
      {
        details: e.info?.details,
      }
    );
  }

  console.error(e);
  if (
    (typeof e.response?.data?.error === 'string' && e.response?.data?.error?.includes('token has expired')) ||
    (Object.prototype.hasOwnProperty.call(e, 'code') && e.code && e.code === 'auth/id-token-expired')
  ) {
    // return 'Your session has expired. Please refresh the page to keep using the application';
    return intl.formatMessage({ id: 'errors_your_session_expired' });
  }

  if (e.message && e.message === 'Network Error') {
    // return 'Failed reaching the data servers';
    return intl.formatMessage({ id: 'errors_failed_reaching_the_data_servers' });
  }

  if (e.response?.data?.error || e.response?.data?.detail || e.response?.data?.message) {
    error = e.response?.data?.message || e.response?.data?.error || e.response?.data?.detail;
    if ((error as { status: number })?.status) {
      message = `${fallbackString} (${(error as { status: number }).status})`;
    } else if (typeof error === 'string') {
      message = error;
    } else {
      message = fallbackString;
    }
  } else if (e.message) {
    message = e.message;
  } else {
    message = fallbackString;
  }

  if (Array.isArray(message) && message[0].msg === 'field required') {
    if (message[0].loc && message[0].loc.length && message[0].loc.length >= 3) {
      if (message[0].loc[2] === 'country') {
        return intl.formatMessage({
          id: 'errors_the_sensor_needs_a_timezone_set_in_order_to_perform_this_operation',
        });
      } else {
        message = `Missing field: ${message[0].loc[2]} in the request to AI Api`;
      }
    } else {
      message = message[0].msg;
    }
  }

  if (message === 'Request timeout') {
    if (e.response?.data?.object) {
      message = `Fetching the data took too long for ${e.response.data.object} -
      try selecting shorter time period or
      improving your network connection`;
    } else {
      return intl.formatMessage({
        id: 'errors_fetching_the_data_took_too_long_try_selecting_shorter_time_period_or_improving_your_network_connection',
      });
    }
  }

  try {
    return intl.formatMessage({ id: message });
  } catch (e) {
    console.log(e);
    return message;
  }
};

export default getErrorMessageIntl;
