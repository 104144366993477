import { JsonDecoder } from 'ts.data.json';
import {
  BOT_SENSOR_ATTRIBUTE,
  BOX_SENSOR_ATTRIBUTE,
  BOX_TEMPERATURE_ATTRIBUTE,
  MID_SENSOR_ATTRIBUTE,
  SALINITY_ATTRIBUTE,
  TEMPERATURE_ATTRIBUTE,
  TOP_SENSOR_ATTRIBUTE,
  WATER_VOLUME_ATTRIBUTE,
} from '../consts/backendAttributes';

export interface IDataElement {
  data: IDataPoint;
  timestamp: number;
  rssi?: number;
}

export interface IDataPoint {
  [TOP_SENSOR_ATTRIBUTE]: IDataPointPart;
  [MID_SENSOR_ATTRIBUTE]?: IMidDataPointPart;
  [BOT_SENSOR_ATTRIBUTE]: IDataPointPart;
  [BOX_SENSOR_ATTRIBUTE]: IBoxDataPoint;
}

export interface IBoxDataPoint {
  [BOX_TEMPERATURE_ATTRIBUTE]: number | undefined;
}

export interface IDataPointPart {
  [WATER_VOLUME_ATTRIBUTE]: number | undefined;
  [SALINITY_ATTRIBUTE]: number | undefined;
  [TEMPERATURE_ATTRIBUTE]: number | undefined;
}

export type IMidDataPointPart = Readonly<{
  [WATER_VOLUME_ATTRIBUTE]: number | undefined;
  [SALINITY_ATTRIBUTE]: number | undefined;
  [TEMPERATURE_ATTRIBUTE]: number | undefined;
}>;

function createDataElementDecoder(): JsonDecoder.Decoder<IDataElement> {
  const { object: obj, optional: opt, number: num } = JsonDecoder;

  const dataPointPartDecoder = obj<IDataPointPart>(
    {
      [WATER_VOLUME_ATTRIBUTE]: opt(num),
      [SALINITY_ATTRIBUTE]: opt(num),
      [TEMPERATURE_ATTRIBUTE]: opt(num),
    },
    'IDataPointPart'
  );

  const midDataPointPartDecoder = obj<IMidDataPointPart>(
    {
      [WATER_VOLUME_ATTRIBUTE]: opt(num),
      [SALINITY_ATTRIBUTE]: opt(num),
      [TEMPERATURE_ATTRIBUTE]: opt(num),
    },
    'IMidDataPointPart'
  );

  const boxDataPointDecoder = obj<IBoxDataPoint>(
    {
      [BOX_TEMPERATURE_ATTRIBUTE]: opt(num),
    },
    'IBoxDataPoint'
  );

  const dataPointDecoder = obj<IDataPoint>(
    {
      [TOP_SENSOR_ATTRIBUTE]: dataPointPartDecoder,
      [MID_SENSOR_ATTRIBUTE]: opt(midDataPointPartDecoder),
      [BOT_SENSOR_ATTRIBUTE]: dataPointPartDecoder,
      [BOX_SENSOR_ATTRIBUTE]: boxDataPointDecoder,
    },
    'IDataPoint'
  );

  return obj(
    {
      data: dataPointDecoder,
      timestamp: num,
      rssi: opt(num),
    },
    'IDataElement'
  );
}

export const DATA_ELEMENT_DECODER = createDataElementDecoder();

export const DATA_ELEMENTS_DECODER = JsonDecoder.array(DATA_ELEMENT_DECODER, 'IDataElements');
