import type { IntlShape } from 'react-intl';
import dataloggerResetImage from '../../assets/installation/DataloggerReset.png';
import nbLoggerResetImage from '../../assets/installation/NbLoggerReset.png';
import type { IStep } from './CustomStepper';

export function goToInstallationStep(intl: IntlShape): IStep {
  return {
    title: intl.formatMessage({ id: 'go_to_installation_location' }),
    content: intl.formatMessage({ id: 'go_to_installation_location_content' }),
    canProceed: true,
  };
}

export function resetDataLoggerStep(intl: IntlShape): IStep {
  return {
    title: intl.formatMessage({ id: 'reset_and_check_signal_strength' }),
    content: intl.formatMessage({ id: 'reset_and_check_signal_strength_content' }),
    canProceed: true,
    image: dataloggerResetImage,
  };
}

export function turnOnNbLoggerStep(intl: IntlShape): IStep {
  return {
    title: intl.formatMessage({ id: 'turn_on_the_device_title' }),
    content: intl.formatMessage({ id: 'turn_on_the_device_description' }),
    canProceed: true,
    image: nbLoggerResetImage,
  };
}
