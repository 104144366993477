import DownloadIcon from '@mui/icons-material/CloudDownload';
import { Button, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Controls from 'components/Board/Controls';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useFarmStore, useObservationSiteStore, useSensorDataLoading } from '../../dataHandlers/RootStore';
import getErrorMessageIntl from '../../utils/getErrorMessageIntl';
import type { IControls } from '../Board/config';
import { CONTROL_OPTIONS } from '../Board/config';
import RangeDatePicker from '../RangeDatePicker';
import { showErrorSnackBar } from '../SnackBar';

interface IProps {
  uiReactionLoading: boolean;
  controls: IControls;
  controlsOnChange: (key: CONTROL_OPTIONS) => void;
}

const ChartsControlPanel: React.FC<IProps> = observer(({ uiReactionLoading, controls, controlsOnChange }) => {
  const loading = useSensorDataLoading();
  const screenIsLarge = useMediaQuery(useTheme().breakpoints.up('lg'));
  const intl = useIntl();

  const farmStore = useFarmStore();
  const observationSiteStore = useObservationSiteStore();

  const downloadCsv = useCallback(() => {
    try {
      const farm = farmStore.selectedFarm;
      if (farm == undefined) {
        throw new Error(intl.formatMessage({ id: 'cannot_download_csv_because_no_farm' }));
      }

      const farmName = farm.name;
      const startDateString = farmStore.selectedDates.startDate.format('YYYY-MM-DD');
      const endDateString = farmStore.selectedDates.endDate.format('YYYY-MM-DD');
      const fileName = `${farmName}_${startDateString}_${endDateString}.csv`;
      const data = farmStore.currentFarmToCsv({
        includeSalinity: controls[CONTROL_OPTIONS.Salinity],
        includeSoilTemperature: controls[CONTROL_OPTIONS.Temperature],
        includeAirTemperature: controls[CONTROL_OPTIONS.BoxTemperature],
        includeArchivedSites: controls[CONTROL_OPTIONS.ArchivedLocations],
      });
      saveAs(new Blob([data], { type: 'text/csv;charset=utf-8' }), fileName);
    } catch (err) {
      showErrorSnackBar(getErrorMessageIntl(err, intl));
    }
  }, [controls, farmStore, intl]);

  return (
    <div style={{ paddingTop: 0, opacity: uiReactionLoading ? 0.5 : 1 }}>
      <div style={{ paddingTop: '2px', paddingBottom: '2px' }}>
        <RangeDatePicker
          size='small'
          isPending={uiReactionLoading || loading}
          format={screenIsLarge ? 'DD MMMM YYYY' : 'DD MMM YYYY'}
        />
      </div>

      <Divider
        style={{
          marginBottom: 7,
        }}
      />
      <Controls controls={controls} onChange={controlsOnChange} disabled={uiReactionLoading} />
      <ListItem key='download_csv'>
        <ListItemIcon style={{ minWidth: 40 }}>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'download_csv' })} />
        <Button
          disabled={farmStore.isLoading || observationSiteStore.isLoading}
          variant='contained'
          color='secondary'
          // startIcon={<DownloadIcon />}
          onClick={downloadCsv}
        >
          <DownloadIcon />
          {/* <FormattedMessage id='download_csv' /> */}
        </Button>
      </ListItem>
      <div
        style={{
          height: 10,
        }}
      ></div>

      {/* <Box display='flex' flexDirection='row' justifyContent='space-around' py='10px'>
        <Button
          disabled={farmStore.isLoading || observationSiteStore.isLoading}
          variant='outlined'
          color='primary'
          startIcon={<DownloadIcon />}
          onClick={downloadCsv}
        >
          <FormattedMessage id='download_csv' />
        </Button>
      </Box> */}
    </div>
  );
});

export default ChartsControlPanel;
