import MaterialTooltip from '@mui/material/Tooltip';
import React from 'react';
import type { AddGatewayCallback, AddSensorCallback } from './AddDeviceFlow';
import AddDeviceFlow from './AddDeviceFlow';

const AddDeviceAccordion: React.FC<{
  addNewSensor: AddSensorCallback;
  addNewGateway: AddGatewayCallback;
  disabled?: boolean;
  refetchAllStatuses: () => void;
}> = ({ addNewSensor, addNewGateway, disabled, refetchAllStatuses }) => {
  const [adding, setAdding] = React.useState(false);

  const deviceFlowElement = (
    <AddDeviceFlow
      addNewSensor={addNewSensor}
      addNewGateway={addNewGateway}
      disabled={disabled}
      adding={adding}
      setAdding={setAdding}
      refetchAllStatuses={refetchAllStatuses}
    />
  );

  if (disabled) {
    return (
      <MaterialTooltip
        title={
          disabled ? 'Adding a new sensor can only be done from a mobile device with a GPS capability' : false
        }
      >
        <span style={{ width: '100%' }}>{deviceFlowElement}</span>
      </MaterialTooltip>
    );
  }
  return deviceFlowElement;
};

export default AddDeviceAccordion;
