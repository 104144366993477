import { doc, getDoc } from 'firebase/firestore';
import { JsonDecoder } from 'ts.data.json';
import type FirebaseApi from './FirebaseApi';

type ExtractDecoderType<T> = T extends JsonDecoder.Decoder<infer U> ? U : never;

const DEVICE_NAME_DECODER = JsonDecoder.object(
  {
    deviceType: JsonDecoder.oneOf<'sensor' | 'nbsensor' | 'gateway'>(
      [JsonDecoder.isExactly('sensor'), JsonDecoder.isExactly('nbsensor'), JsonDecoder.isExactly('gateway')],
      'deviceType'
    ),
    id: JsonDecoder.string,
    deviceId: JsonDecoder.string,
  },
  'DeviceName'
);

export type DeviceName = ExtractDecoderType<typeof DEVICE_NAME_DECODER>;

function decodeDeviceName(value: unknown): Promise<DeviceName> {
  return DEVICE_NAME_DECODER.decodeToPromise(value);
}

class DeviceNames {
  constructor(private readonly api: FirebaseApi) {}

  public async getAssociatedDeviceNameObject(name: string): Promise<DeviceName | undefined> {
    const snapshot = await getDoc(doc(this.api.getCollections().deviceNames(), name));
    if (snapshot.exists()) {
      return await decodeDeviceName(snapshot.data());
    }
    return undefined;
  }
}

export default DeviceNames;
