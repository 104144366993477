import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCurrentUser, useObservationSiteStore } from '../../dataHandlers/RootStore';
import { toSnakeCase } from '../../utils/toSnakeCase';
import SwitchWithLabel from '../Switch';
import type { IControls } from './config';
import { CONTROLS_ORDER, CONTROL_ICONS, CONTROL_OPTIONS } from './config';

interface IProps {
  controls: IControls;
  onChange: (key: CONTROL_OPTIONS) => void;
  disabled: boolean;
}

const Controls: FC<IProps> = observer(({ controls, onChange, disabled }) => {
  const observationSiteStore = useObservationSiteStore();
  const currentUser = useCurrentUser();
  const intl = useIntl();

  const shouldRender = (key: string) => {
    switch (key) {
      case CONTROL_OPTIONS.Salinity:
        return observationSiteStore.atLeastOneSalinitySensor;
      case CONTROL_OPTIONS.BoxTemperature:
        return observationSiteStore.atLeastOneBoxTemperatureSensor && !currentUser?.disableAirTemperatureChart;
      case CONTROL_OPTIONS.ArchivedLocations:
        return observationSiteStore.archivedSiteDetails.length > 0;
      case CONTROL_OPTIONS.Precipitation:
        return observationSiteStore.hasPrecipitation;
      default:
        return true;
    }
  };

  const alwaysSetTo = (key: string) => {
    switch (key) {
      case CONTROL_OPTIONS.BoxTemperature:
        return currentUser?.disableAirTemperatureChart === true ? false : undefined;
      default:
        return undefined;
    }
  };

  return (
    <>
      {CONTROLS_ORDER.map((key) => {
        if (!shouldRender(key)) {
          return null;
        }
        const val = alwaysSetTo(key);
        return (
          <ListItem key={key}>
            <ListItemIcon style={{ minWidth: 40 }}>{CONTROL_ICONS[key as keyof IControls]}</ListItemIcon>
            <ListItemText primary={intl.formatMessage({ id: `controls_${toSnakeCase(key.toLowerCase())}` })} />
            <SwitchWithLabel
              checked={val ?? controls[key as keyof IControls]}
              disabled={disabled}
              onChange={() => onChange(key as keyof IControls)}
              labelPlacement='start'
            />
            <div style={{ paddingRight: 13 }}></div>
          </ListItem>
        );
      })}
    </>
  );
});

export default Controls;
