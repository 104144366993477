export let BASE_URL: string;

const hostname = window.location.hostname;

//deploy
if (process.env.REACT_APP_OVERWRITE_BASE_URL) {
  BASE_URL = `${process.env.REACT_APP_OVERWRITE_BASE_URL}firebase`;
} else if (hostname === 'localhost' || hostname === 'client') {
  BASE_URL = `http://${hostname === 'client' ? 'server' : hostname}:3001/firebase`;
} else {
  BASE_URL = `https://${window.location.hostname}/firebase`;
}
console.log(`--- Backend url set to ${BASE_URL} ---`);

export const DEFAULT_ZOOM = 17;

export function highlightColor(opacity: number): string {
  return `rgba(152, 222, 255, ${opacity})`;
}
