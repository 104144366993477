export enum LOCALES {
  ENGLISH = 'en',
  DANISH = 'da',
  GERMAN = 'de',
  SPANISH = 'es',
}

export const FLAGS = {
  [LOCALES.ENGLISH]: '🇬🇧',
  [LOCALES.DANISH]: '🇩🇰',
  [LOCALES.GERMAN]: '🇩🇪',
  [LOCALES.SPANISH]: '🇪🇸',
};

export const LANGUAGE_LABELS = {
  [LOCALES.ENGLISH]: 'English',
  [LOCALES.DANISH]: 'Dansk',
  [LOCALES.GERMAN]: 'Deutsch',
  [LOCALES.SPANISH]: 'Español',
};

export const ourLocaleToMomentLocale = (locale: LOCALES): string => {
  switch (locale) {
    case LOCALES.ENGLISH:
      return 'en-gb';
    case LOCALES.DANISH:
      return 'da';
    case LOCALES.SPANISH:
      return 'es';
    case LOCALES.GERMAN:
      return 'de';
  }
};
