export const stripUndefinedFromObj = <T extends { [key: string]: unknown }>(input: T): T => {
  Object.keys(input).forEach((key) => input[key] === undefined && delete input[key]);
  return input;
};

export const recursiveStripUndefinedFromObj = <T extends { [key: string]: unknown }>(input: T): T => {
  Object.keys(input).forEach((key) => {
    const value = input[key];
    if (value && typeof value === 'object') {
      recursiveStripUndefinedFromObj(value as { [key: string]: unknown });
    } else if (value === undefined) {
      delete input[key];
    }
  });
  return input;
};
