import type { UserUpdate } from '../dataHandlers/UserStore';
import DeviceNames from './DeviceNames';
import Farms from './Farms';
import FirebaseApi from './FirebaseApi';
import FirebaseMessaging from './FirebaseMessaging';
import Gateways from './Gateways';
import Sensors from './Sensors';
import Users from './Users';

export class Base {
  readonly api: FirebaseApi;
  readonly auth: FirebaseApi;
  readonly deviceNames: DeviceNames;
  readonly farms: Farms;
  readonly messaging: FirebaseMessaging;
  readonly sensors: Sensors;
  readonly users: Users;
  readonly gateways: Gateways;

  constructor(setUser: (user: UserUpdate | null) => void) {
    this.api = new FirebaseApi(setUser);
    this.auth = this.api;
    this.deviceNames = new DeviceNames(this.api);
    this.farms = new Farms(this.api);
    this.messaging = new FirebaseMessaging(this.api);
    this.sensors = new Sensors(this.api);
    this.users = new Users(this.api);
    this.gateways = new Gateways(this.api);
  }
}
