import theme from 'theme';

import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';

const Progress = withStyles({
  root: {
    color: theme.palette.secondary.main,
  },
})(CircularProgress);

export default Progress;
