import type { Location } from 'history';
import SystemStatusPage from 'pages/SystemStatus';
import React, { useEffect } from 'react';
import { Switch, useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import AdminPrivateRoute from './pages/AdminRoute';
import BoardPage from './pages/Board';
import ForgotPasswordPage from './pages/ForgotPassword';
import LoginPage from './pages/Login';
import { NotFound } from './pages/NotFound';
import PrivateRoute from './pages/PrivateRoute';
import SignUpPage from './pages/Signup';
import { Routes } from './Routes';
import googleAnalyticsInstance from './utils/googleAnalytics';

const Routing: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    let prevLocation: Location;
    const unlisten = history.listen((location) => {
      if (!prevLocation || prevLocation.pathname !== location.pathname) {
        googleAnalyticsInstance.pageView();
      }
      prevLocation = location;
    });
    return () => unlisten();
  }, [history]);

  return (
    <Switch>
      <Route exact path={Routes.LOGIN} component={LoginPage} />
      <Route exact path={Routes.SIGNUP} component={SignUpPage} />
      <Route exact path={Routes.RESET_PASSWORD} component={ForgotPasswordPage} />
      <PrivateRoute exact path={Routes.HOME} component={BoardPage} />
      <AdminPrivateRoute exact path={Routes.SYSTEM_STATUS} component={SystemStatusPage} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routing;
