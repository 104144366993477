import type { MessagePayload } from 'firebase/messaging';
import type FirebaseApi from './FirebaseApi';

class FirebaseMessaging {
  constructor(private readonly api: FirebaseApi) {}

  public async requestFirebaseNotificationToken(
    serviceWorkerRegistration: ServiceWorkerRegistration
  ): Promise<string | undefined> {
    return await this.api.getToken(serviceWorkerRegistration);
  }

  public onMessage(listener: (payload: MessagePayload) => void): () => void {
    return this.api.onMessage(listener);
  }
}

export default FirebaseMessaging;
