import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CircularProgress, Divider, IconButton, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import type { Dispatch, FC } from 'react';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFarmStore } from '../../dataHandlers/RootStore';
import { customerVisibleDeviceId } from '../../dataHandlers/utils/formatters';
import type { Area, AreaId } from '../../interfaces/Area';
import getErrorMessageIntl from '../../utils/getErrorMessageIntl';
import { showErrorSnackBar, showSuccessSnackBar } from '../SnackBar';
import { ColorBoxChart } from './Chart/ColorBoxChart';
import { WeightedAverageChart } from './Chart/WeightedAverageChart';
import { useStyles } from './SensorOverviewCard';

const useMoreStyles = makeStyles(() => ({
  fieldTitleBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fieldDeleteIconButton: {
    padding: 0,
  },
  fieldDeleteActionButton: {
    height: '32px',
    width: '150px',
  },
  mainDivider: {
    margin: '10px 0',
  },
  sectionDivider: {
    margin: '10px 20px',
  },
}));

type Props = Readonly<{
  area: Area;
  selectedArea: AreaId | undefined;
  setSelectedArea: Dispatch<AreaId>;
  forcedShowSelectionCounter: number;
}>;

export const AreaOverviewCard: FC<Props> = observer(
  ({ area, selectedArea, setSelectedArea, forcedShowSelectionCounter }) => {
    const classes = useStyles();
    const moreClasses = useMoreStyles();
    const farmStore = useFarmStore();
    const intl = useIntl();

    const wrapperRef = useRef<HTMLDivElement>(null);
    const selected = isSelected(area, selectedArea);

    useEffect(() => {
      if (selected) {
        wrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, [selected, forcedShowSelectionCounter]);

    const field = area.kind == 'field' ? area.fieldDetails : undefined;
    const siteDetails = area.kind == 'field' ? area.fieldDetails.siteDetails : [area.siteDetails];

    const [popoverOpen, setPopoverOpen] = useState(false);
    const deleteIconRef = React.useRef<HTMLButtonElement | null>(null);

    const [deletingField, setDeletingField] = useState(false);
    const onDeleteField = useCallback(() => {
      let active = true;
      if (field != undefined) {
        setDeletingField(true);
        (async () => {
          try {
            await farmStore.deleteField(field);
            if (active) {
              setPopoverOpen(false);
              showSuccessSnackBar(intl.formatMessage({ id: 'field_deleted' }));
            }
          } catch (err) {
            if (active) {
              const message = getErrorMessageIntl(err, intl);
              showErrorSnackBar(intl.formatMessage({ id: 'field_not_deleted' }, { message }));
            }
          } finally {
            if (active) {
              setDeletingField(false);
            }
          }
        })();
      }
      return () => (active = false);
    }, [farmStore, field, intl]);

    return (
      <div ref={wrapperRef} className={classes.cardWrapper} onClick={() => setSelectedArea(toAreaId(area))}>
        <Card
          className={clsx(classes.card, {
            [classes.clickableCard]: !selected,
            [classes.selectedCard]: selected,
          })}
        >
          {field != undefined && (
            <div className={clsx(classes.cardTitleBar, moreClasses.fieldTitleBar)}>
              <Typography color='textPrimary' variant='h5' noWrap display='block'>
                {field.name}
              </Typography>
              <IconButton
                ref={deleteIconRef}
                className={moreClasses.fieldDeleteIconButton}
                onClick={(event) => {
                  event.stopPropagation(); // don't trigger area selection
                  setPopoverOpen(true);
                }}
                size='large'
              >
                <DeleteIcon />
              </IconButton>
              {popoverOpen && (
                <Popover
                  open
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  anchorEl={deleteIconRef.current}
                  onClose={() => setPopoverOpen(false)}
                >
                  <Button
                    color='primary'
                    variant='contained'
                    className={moreClasses.fieldDeleteActionButton}
                    disabled={deletingField}
                    onClick={(event) => {
                      event.stopPropagation(); // don't trigger area selection
                      onDeleteField();
                    }}
                  >
                    {deletingField ? <CircularProgress size={18} /> : 'Delete field'}
                  </Button>
                </Popover>
              )}
            </div>
          )}
          {siteDetails.map((siteDetails, index) => {
            const isCalibrated =
              siteDetails.configuration.cableTop.calibration != undefined &&
              siteDetails.configuration.cableBottom.calibration != undefined;

            const siteHasName = siteDetails.site.name.trim() != '';
            const deviceIdString = `ID: ${customerVisibleDeviceId(siteDetails.deviceIds)}`;

            return (
              <Fragment key={siteDetails.site.id}>
                {field != undefined && (
                  <Divider className={index == 0 ? moreClasses.mainDivider : moreClasses.sectionDivider} />
                )}
                <div className={classes.cardTitleBar}>
                  <div className={classes.cardTitleWrapper}>
                    <Typography
                      color={field == undefined ? 'textPrimary' : 'textSecondary'}
                      variant='h6'
                      noWrap
                      display='block'
                    >
                      {siteHasName ? siteDetails.site.name : deviceIdString}
                    </Typography>
                    {siteHasName && (
                      <Typography
                        color={field == undefined ? 'textPrimary' : 'textSecondary'}
                        variant='caption'
                        noWrap
                        display='block'
                      >
                        {deviceIdString}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className={classes.cardMainArea}>
                  {isCalibrated ? (
                    <ColorBoxChart timeRange={farmStore.selectedDates} siteDetails={siteDetails} />
                  ) : (
                    <WeightedAverageChart timeRange={farmStore.selectedDates} siteDetails={siteDetails} />
                  )}
                </div>
              </Fragment>
            );
          })}
        </Card>
      </div>
    );
  }
);

function isSelected(area: Area, selectedArea: AreaId | undefined) {
  if (area.kind == 'field') {
    const { id } = area.fieldDetails;
    return selectedArea?.kind == 'field' && selectedArea.fieldId == id;
  } else {
    const { id } = area.siteDetails.site;
    return selectedArea?.kind == 'site' && selectedArea.siteId == id;
  }
}

function toAreaId(area: Area): AreaId {
  return area.kind == 'field'
    ? { kind: 'field', fieldId: area.fieldDetails.id }
    : { kind: 'site', siteId: area.siteDetails.site.id };
}
