import { Typography } from '@mui/material';
import type { ObservationSiteCalibrationSuggestion, SensorSlot } from '@soilsense/shared';
import moment from 'moment';
import React, { useMemo } from 'react';
import assertNever from '../../utils/assertNever';
import { TIME_FORMAT } from './HistoryItemSelector';

type Props = Readonly<{
  value: ObservationSiteCalibrationSuggestion;
}>;

export function CalibrationSuggestion({
  value: { sensorSlot, appliesFrom, fieldCapacity, dismissed },
}: Props): JSX.Element {
  const startTimeString = useMemo(() => moment(appliesFrom).format(TIME_FORMAT), [appliesFrom]);
  return (
    <Typography color={dismissed ? 'textSecondary' : 'textPrimary'}>
      {startTimeString}, {sensorSlotName(sensorSlot)}: {fieldCapacity}
    </Typography>
  );
}

function sensorSlotName(sensorSlot: SensorSlot): string {
  switch (sensorSlot) {
    case 'cableTop':
      return 'top sensor';
    case 'cableMiddle':
      return 'middle sensor';
    case 'cableBottom':
      return 'bottom sensor';
    default:
      return assertNever(sensorSlot);
  }
}
