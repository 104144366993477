import type { Action, Column } from '@material-table/core';
import MaterialTable from '@material-table/core';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import CachedIcon from '@mui/icons-material/Cached';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import HistoryIcon from '@mui/icons-material/History';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import type { PaperProps } from '@mui/material/Paper';
import Paper from '@mui/material/Paper';
import type { TransformedData } from 'dataHandlers/SensorTransformer';
import { observer } from 'mobx-react-lite';
import React, { forwardRef } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { JsonDecoder } from 'ts.data.json';
import type { VWC } from '../../dataHandlers/utils/constsAndTypes';
import { BASE_URL } from '../../utils/consts';
import getErrorMessageIntl from '../../utils/getErrorMessageIntl';
import { post } from '../../utils/post';
import useAuthToken from '../../utils/useAuthToken';
import useTraceUpdate from '../../utils/useTraceUpdate';
import { showErrorSnackBar, showSuccessSnackBar } from '../SnackBar';

function withRef<P>(Component: React.ComponentType<P>) {
  function forwarder(props: React.PropsWithChildren<P>, ref: React.ForwardedRef<SVGSVGElement>) {
    return <Component {...props} ref={ref} />;
  }
  forwarder.displayName = Component.displayName;
  return forwardRef<SVGSVGElement, P>(forwarder);
}

export const TABLE_ICONS = {
  Add: withRef(AddBox),
  Check: withRef(Check),
  Clear: withRef(Clear),
  Delete: withRef(DeleteOutline),
  DetailPanel: withRef(ChevronRight),
  Edit: withRef(Edit),
  Export: withRef(SaveAlt),
  Filter: withRef(FilterList),
  FirstPage: withRef(FirstPage),
  LastPage: withRef(LastPage),
  NextPage: withRef(ChevronRight),
  PreviousPage: withRef(ChevronLeft),
  ResetSearch: withRef(Clear),
  Search: withRef(Search),
  SortArrow: withRef(ArrowDownward),
  ThirdStateCheck: withRef(Remove),
  ViewColumn: withRef(ViewColumn),
};

export type Row = Readonly<{
  rowIndex: number;
  sensor: string;
  fc: number | undefined;
  pwp: number | undefined;
}>;

export type TableState = Readonly<{
  columns: readonly Column<Row>[];
  data: readonly Row[];
}>;

const BASE = `${BASE_URL}/ai`;

const CALIBRATION_RESPONSE_DECODER = JsonDecoder.object(
  {
    d: JsonDecoder.object(
      {
        fc: JsonDecoder.number,
        pwp: JsonDecoder.number,
      },
      'Calibration'
    ),
  },
  'CalibrationResponse'
);

async function autocalibrate(
  data: readonly TransformedData[],
  vwcEntryName: keyof TransformedData,
  country: string,
  token: string,
  intl: IntlShape
): Promise<{ fc: number; pwp: number } | undefined> {
  const postBody = {
    country,
    y: data.map((e) => e[vwcEntryName]),
    x: data.map((e) => e.timestamp / 1000),
  };
  try {
    const rawData = await post(`${BASE}/fc_and_pwp`, token, postBody);
    const data = await CALIBRATION_RESPONSE_DECODER.decodeToPromise(rawData);
    showSuccessSnackBar(intl.formatMessage({ id: 'successfully_calculated_new_field_capacity' }));
    return data.d;
  } catch (e) {
    const message = getErrorMessageIntl(e, intl);
    showErrorSnackBar(message);
    console.error(message);
    return undefined;
  }
}

const WILTING_POINT_RESPONSE_DECODER = JsonDecoder.object(
  {
    d: JsonDecoder.number,
  },
  'WiltingPointResponse'
);

export async function getPwp(fc: number, token: string, intl: IntlShape): Promise<number | undefined> {
  try {
    const rawData = await post(`${BASE}/pwp`, token, { fc });
    const data = await WILTING_POINT_RESPONSE_DECODER.decodeToPromise(rawData);
    showSuccessSnackBar(intl.formatMessage({ id: 'successfully_calculated_new_wilting_point' }));
    return data.d;
  } catch (e) {
    const message = getErrorMessageIntl(e, intl);
    showErrorSnackBar(message);
    console.error(message);
    return undefined;
  }
}

export type RowConfig = Readonly<{
  slot: 'cableTop' | 'cableMiddle' | 'cableBottom';
  var: VWC;
  label: string;
}>;

type IEditableTable = Readonly<{
  state: TableState;
  setState: React.Dispatch<React.SetStateAction<TableState>>;
  sensorData: readonly TransformedData[];
  sensorCountry: string;
  onRevert: (event: unknown, rowData: unknown) => Promise<void>;
  nothingChanged?: boolean;
  namesDefinitions: readonly RowConfig[];
}>;

const EditableTable: React.FC<IEditableTable> = observer((props) => {
  useTraceUpdate(props, 'EditableTable');
  const { state, setState, sensorData, sensorCountry, onRevert, nothingChanged, namesDefinitions } = props;
  const getIdToken = useAuthToken();
  const intl = useIntl();

  const actions: Action<Row>[] = [
    {
      icon: CachedIcon,
      tooltip: 'Auto-calibrate',
      onClick: async (event: unknown, rowData: Row | Row[]) => {
        if (Array.isArray(rowData)) {
          return;
        }

        const token = await getIdToken();
        const autoCalibrationResult = await autocalibrate(
          sensorData,
          namesDefinitions[rowData.rowIndex].var,
          sensorCountry,
          token,
          intl
        );
        if (!autoCalibrationResult) {
          return;
        }
        setState((prevState: TableState) => {
          const data = prevState.data.map((previousRowData, index) =>
            index === rowData.rowIndex
              ? {
                  ...previousRowData,
                  ...autoCalibrationResult,
                }
              : previousRowData
          );
          return { ...prevState, data };
        });
      },
    },
  ];

  if (!nothingChanged) {
    actions.push({
      icon: HistoryIcon,
      tooltip: 'Revert',
      onClick: onRevert,
    });
  }

  return (
    <MaterialTable
      components={{
        Container: ElevatedPaper,
      }}
      options={{
        search: false,
        sorting: false,
        draggable: false,
        paging: false,
      }}
      title={intl.formatMessage({ id: 'soil_parameters' })}
      columns={state.columns as Column<Row>[]}
      data={state.data as Row[]}
      icons={TABLE_ICONS}
      actions={actions}
      editable={{
        onRowUpdate: async (newData, oldData) => {
          if (!oldData) {
            return;
          }

          if (newData.fc === oldData?.fc && newData.pwp === oldData?.pwp) {
            return;
          }

          if (newData.fc != null && ((!oldData && !newData.pwp) || Object.is(newData.pwp, oldData?.pwp))) {
            const token = await getIdToken();
            const newPwp = await getPwp(newData.fc, token, intl);
            if (newPwp) {
              newData = { ...newData, pwp: newPwp };
            }
          }

          setState((prevState: TableState) => {
            const data = prevState.data.map((rowData, index) => (index === oldData.rowIndex ? newData : rowData));
            return { ...prevState, data };
          });
        },
      }}
    />
  );
});

function ElevatedPaper(p: PaperProps) {
  return (
    <Paper
      {...p}
      elevation={3}
      style={{
        width: '600px',
      }}
    />
  );
}

export default EditableTable;
