import { Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';

export const getLastUpdatedColor = (lastUpdated?: Moment): string => {
  const minutes = moment().diff(lastUpdated, 'minutes');
  switch (true) {
    case lastUpdated == null:
      return 'grey';
    case minutes > 6 * 60:
      return 'red';
    case minutes > 1.5 * 60:
      return 'orange';
    default:
      return 'green';
  }
};

const useBadgeStyles = makeStyles((theme) => ({
  badgeDot: ({ c }: { c: string }) => ({
    backgroundColor: c,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: '12px',
    height: '12px',
    borderRadius: '50px',
  }),
}));

export const StatusCircle = ({ color, children }: { color: string; children: React.ReactNode }): JSX.Element => {
  const badgeClasses = useBadgeStyles({ c: color });
  return (
    <Badge
      color='secondary'
      variant='dot'
      overlap='circular'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        dot: badgeClasses.badgeDot,
      }}
    >
      {children}
    </Badge>
  );
};

export const SecondarySummaryColored = ({
  lastUpdated,
  lastUpdatedError,
  loading,
}: {
  lastUpdated?: Moment;
  lastUpdatedError?: string;
  loading?: boolean;
}): JSX.Element => {
  const intl = useIntl();
  const c = getLastUpdatedColor(lastUpdated);

  let secondarySummaryString: string;
  if (lastUpdatedError) {
    secondarySummaryString = lastUpdatedError;
  } else if (!loading) {
    if (lastUpdated) {
      secondarySummaryString = intl.formatMessage({ id: 'last_online' }, { lastOnline: lastUpdated?.fromNow() });
    } else {
      secondarySummaryString = intl.formatMessage({ id: 'no_status_available' });
    }
  } else {
    secondarySummaryString = `${intl.formatMessage({ id: 'fetching' })}...`;
  }

  return (
    <span
      style={{
        color: lastUpdatedError
          ? 'grey'
          : `rgba(${c === 'red' || c === 'orange' ? 100 : 0}, ${
              c === 'green' || c === 'orange' ? 100 : 0
            }, 50, 0.7)`,
      }}
    >
      {secondarySummaryString}
    </span>
  );
};
