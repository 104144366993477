// import EventNoteIcon from '@mui/icons-material/EventNote';
// import TimelineIcon from '@mui/icons-material/Timeline';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import React from 'react';
import enumToArray from '../../utils/enumToArray';

export enum TabLabels {
  Overview,
  Charts,
}
export const tabLabels = enumToArray(TabLabels) as string[];
export const tabIcons = [<MapOutlinedIcon key={0} />, <DataThresholdingOutlinedIcon key={1} />];
