import React from 'react';

export type DeviceIdentifiers = Readonly<{
  id: string;
  deviceName?: string;
  deviceType?: 'gateway' | 'sensor' | 'nbsensor';
  nblogger?: boolean;
}>;

export function customerVisibleDeviceId(device: DeviceIdentifiers): string {
  return device.deviceName ?? device.id;
}

export function longDeviceDisplayName(
  device: DeviceIdentifiers &
    Readonly<{
      name?: string;
    }>
): JSX.Element {
  const idString = `ID: ${customerVisibleDeviceId(device)}`;
  if (device.name) {
    return (
      <span>
        {device.name} <span style={{ fontSize: '60%' }}>({idString})</span>
      </span>
    );
  }
  return <span>{idString}</span>;
}

export function veryLongDeviceDisplayName(
  device: DeviceIdentifiers &
    Readonly<{
      name?: string;
    }>
): JSX.Element {
  const idString = `ID: ${longCustomerVisibleDeviceId(device)}`;
  if (device.name) {
    return (
      <span>
        {device.name} <span style={{ fontSize: '60%' }}>({idString})</span>
      </span>
    );
  }
  return <span>{idString}</span>;
}

function longCustomerVisibleDeviceId(device: DeviceIdentifiers): string {
  return device.deviceName == undefined ? device.id : `${device.deviceName}, ${device.id}`;
}
