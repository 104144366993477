import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, CircularProgress } from '@mui/material';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { showErrorSnackBar } from 'components/SnackBar';
import type { ImgHTMLAttributes } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CancelConfirmButton from '../CancelConfirmButton';
import DialogBehindIcon from '../DialogBehindIcon';
import { NEXT_BUTTON_ENABLE_DELAY } from './config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    [theme.breakpoints.down('md')]: {
      padding: 4,
    },
  },
  stepLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

type NextButtonProps = Readonly<{
  lastStep?: boolean;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}>;

export const NextButton: React.FC<NextButtonProps> = ({ lastStep, onClick, disabled, loading }) => {
  const classes = useStyles();
  const [initiallyDisabled, setInitiallyDisabled] = React.useState(disabled ?? true);

  React.useEffect(() => {
    setTimeout(() => {
      setInitiallyDisabled(false);
    }, NEXT_BUTTON_ENABLE_DELAY);
  }, []);

  return (
    <Button
      disabled={disabled ?? initiallyDisabled}
      variant='contained'
      color='primary'
      onClick={onClick}
      className={classes.button}
    >
      {lastStep ? (
        <FormattedMessage id='finish' defaultMessage='Finish' />
      ) : (
        <FormattedMessage id='next' defaultMessage='Next' />
      )}
      &nbsp;
      {loading && <CircularProgress size={20} />}
    </Button>
  );
};

const LoadingImageElement = ({ src, alt }: ImgHTMLAttributes<HTMLImageElement>) => {
  const [loading, setLoading] = React.useState(true);
  return (
    <>
      {loading && <CircularProgress />}
      <img
        style={{ maxHeight: '70vh', maxWidth: '100%' }}
        src={src}
        alt={alt}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </>
  );
};

export type IStep = Readonly<{
  title: string;
  content: string | JSX.Element;
  canProceed: boolean;
  onNext?: () => Promise<void>;
  reset?: () => void;
  image?: string;
}>;

type CustomStepperProps = Readonly<{
  activeStep: number;
  steps: readonly IStep[];
  incrementStepper: () => void;
  onCancel: () => void;
  unknownNumberOfSteps: boolean;
}>;

export default function CustomStepper({
  activeStep,
  steps,
  incrementStepper,
  onCancel,
  unknownNumberOfSteps,
}: CustomStepperProps): JSX.Element {
  const classes = useStyles();
  const lastStep = !unknownNumberOfSteps && activeStep === steps.length - 1;
  const [loading, setLoading] = React.useState<{ [key: number]: boolean | undefined }>({});

  const setActiveStepLoading = React.useCallback(
    (value: boolean) => setLoading((previous) => ({ ...previous, activeStep: value })),
    [setLoading]
  );

  const onNext = React.useCallback(async () => {
    try {
      setActiveStepLoading(true);
      await steps[activeStep]?.onNext?.();
      setActiveStepLoading(false);
      incrementStepper();
    } catch (error) {
      setActiveStepLoading(false);
      showErrorSnackBar(
        (typeof error == 'object' ? (error as { message?: string } | null)?.message : undefined) ??
          'An unknown error occurred'
      );
    }
  }, [activeStep, incrementStepper, setActiveStepLoading, steps]);

  const cancelAll = React.useCallback(() => {
    for (const step of steps) {
      step.reset?.();
    }
    onCancel();
  }, [onCancel, steps]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation='vertical' className={classes.stepper}>
        {steps.map((step, index) => (
          <Step key={step.title}>
            <StepLabel classes={{ label: classes.stepLabel }}>{step.title}</StepLabel>
            <StepContent>
              {typeof step.content === 'string' ? <Typography>{step.content}</Typography> : step.content}
              <div className={classes.actionsContainer}>
                <div>
                  {!lastStep && (
                    <CancelConfirmButton
                      disabled={activeStep === 0}
                      onClick={cancelAll}
                      classNameStyle={classes.button}
                    />
                  )}
                  <NextButton
                    onClick={
                      lastStep
                        ? async () => {
                            await onNext();
                            cancelAll();
                          }
                        : onNext
                    }
                    lastStep={lastStep}
                    disabled={loading[index] === true || steps[activeStep]?.canProceed === false}
                    loading={loading[index] === true}
                  />
                </div>
                <div>
                  {step.image && (
                    <DialogBehindIcon
                      imageElement={<LoadingImageElement src={step.image} alt={step.title} />}
                      title={step.title}
                      startOpen={true}
                    />
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
        {unknownNumberOfSteps && (
          <Step>
            <StepLabel StepIconComponent={() => <MoreVertIcon />} color={'secondary'} />
          </Step>
        )}
      </Stepper>
    </div>
  );
}
