import { Box, Card, Divider, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { Fragment } from 'react';
import type { IMinMax } from '../../dataHandlers/SensorTransformer';
import type { Area } from '../../interfaces/Area';
import theme from '../../theme';
import { ZoomableChartGroup } from './Chart/ZoomableChartGroup';
import MapIcon from './MapIcon';

const useStyles = makeStyles({
  mainDivider: {
    margin: '10px 0',
  },
  sectionDivider: {
    margin: '10px 15px',
  },
});

type Props = Readonly<{
  area: Area;
  scaleLoading: boolean;
  scale: IMinMax | undefined;
  displaySalinity: boolean;
  displayTemperature: boolean;
  displayAirTemperature: boolean;
  displayPrecipitation: boolean;
  openDialog?: (sensorId: string) => void;
}>;

export const AreaDataCard: FC<Props> = observer(
  ({
    area,
    scaleLoading,
    scale,
    displaySalinity,
    displayTemperature,
    displayAirTemperature,
    displayPrecipitation,
    openDialog,
  }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // if (area.kind == 'site') {
    //   if (area.siteDetails.deviceIds.id != '9999') {
    //     return null;
    //   }

    //   console.log(area.siteDetails.deviceIds.id, area.siteDetails.site.id);
    // }

    return (
      <Card
        style={
          isMobile
            ? {
                borderRadius: 0,
              }
            : {}
        }
      >
        {area.kind == 'site' ? (
          <ZoomableChartGroup
            siteDetails={area.siteDetails}
            titleFontColor='textPrimary'
            scaleLoading={scaleLoading}
            scale={scale}
            onSettingsClick={openDialog}
            displaySalinity={displaySalinity}
            displayTemperature={displayTemperature}
            displayAirTemperature={displayAirTemperature}
            displayPrecipitation={displayPrecipitation}
          >
            <MapIcon siteDetails={area.siteDetails} />
          </ZoomableChartGroup>
        ) : (
          <>
            <Box px='15px' pt='10px'>
              <Typography color='textPrimary' variant='h5' noWrap display='block'>
                {area.fieldDetails.name}
              </Typography>
            </Box>
            {area.fieldDetails.siteDetails.map((siteDetails, index) => (
              <Fragment key={siteDetails.site.id}>
                <Divider className={index == 0 ? classes.mainDivider : classes.sectionDivider} />
                <ZoomableChartGroup
                  siteDetails={siteDetails}
                  titleFontColor='textSecondary'
                  scaleLoading={scaleLoading}
                  scale={scale}
                  onSettingsClick={openDialog}
                  displaySalinity={displaySalinity}
                  displayTemperature={displayTemperature}
                  displayAirTemperature={displayAirTemperature}
                  displayPrecipitation={displayPrecipitation}
                >
                  <MapIcon siteDetails={siteDetails} />
                </ZoomableChartGroup>
              </Fragment>
            ))}
          </>
        )}
      </Card>
    );
  }
);
